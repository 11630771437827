import { Avatar, Flex, Input, useBreakpointValue } from "@chakra-ui/react";
import { MentorForFilter } from ".";
import { useContext } from "react";
import { MentorFilterContext } from "../../providers/mentorFilterProvider";

const FilterItem = ({
  mentor,
  closeFilterModal,
  isPreselected,
  onClick,
}: {
  mentor: MentorForFilter;
  closeFilterModal: () => void;
  isPreselected: boolean;
  onClick?: () => void;
}) => {
  const isMobile = useBreakpointValue({ base: true, lg: false });
  const mentorFilterContext = useContext(MentorFilterContext);

  if (!mentorFilterContext) return null;

  const { setSelectedMentor } = mentorFilterContext;

  const handleClick = () => {
    setSelectedMentor(mentor);

    if (isMobile) {
      closeFilterModal();
    }
  };

  return (
    <Flex
      w={"100%"}
      zIndex={10}
      cursor={"pointer"}
      key={mentor.id}
      align={"center"}
      flexDir={"row"}
      gap={"12px"}
      pr={"12px"}
      py={"14px"}
      px={"12px"}
      onClick={onClick || handleClick}
      userSelect={"none"}
      onSelect={() => {}}
      bg={isPreselected ? "#F7F8F9" : "white"}
      _focusWithin={{
        borderColor: "primary.200",
        bg: "#F7F8F9",
      }}
      _hover={{
        borderColor: "primary.200",
        bg: isMobile ? "#E9F1FF" : "#F7F8F9",
        color: "primary.200",
      }}
    >
      <Avatar src={mentor.avatarUrl ?? ""} w={"32px"} h={"32px"} />
      <Input
        w={"100%"}
        px={0}
        isReadOnly
        onSelect={() => {}}
        border={"none"}
        maxH={"min-content"}
        value={`${mentor.name} ${mentor.lastName}`}
        onChange={() => {}}
        outline={"none"}
        bg="transparent !important"
        cursor={"pointer"}
        userSelect={"none"}
        color={isPreselected ? "primary.200" : "black"}
        _selection={{
          bg: "transparent",
        }}
        _active={{
          border: "none",
          outline: "none",
          boxShadow: "none",
          bg: "transparent",
        }}
        _focus={{
          border: "none",
          outline: "none",
          boxShadow: "none",
          bg: "red",
        }}
        _pressed={{
          border: "none",
          outline: "none",
          boxShadow: "none",
          bg: "transparent",
        }}
        _selected={{
          border: "none",
          outline: "none",
          boxShadow: "none",
          bg: "transparent",
        }}
        _focusWithin={{
          border: "none",
          outline: "none",
          boxShadow: "none",
          bg: "transparent",
        }}
        _hover={{
          border: "none",
          outline: "none",
          boxShadow: "none",
          bg: "transparent",
          color: "primary.200",
        }}
      />
    </Flex>
  );
};

export default FilterItem;
