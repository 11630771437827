export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: Date;
  JSON: any;
};

export enum ActivityType {
  Lab = 'LAB',
  PracticalLearning = 'PRACTICAL_LEARNING',
  Product = 'PRODUCT',
  Review = 'REVIEW'
}

export type AddUserBadgeInput = {
  badgeId: Scalars['ID'];
  idBadges?: InputMaybe<Scalars['String']>;
  userId: Scalars['ID'];
};

export type AffiliateLinks = {
  __typename?: 'AffiliateLinks';
  link: Scalars['String'];
  name: Scalars['String'];
};

export type AffiliateLinksInput = {
  link: Scalars['String'];
  name: Scalars['String'];
};

export type AgoraToken = {
  __typename?: 'AgoraToken';
  msg: Scalars['String'];
  rtcToken: Scalars['String'];
  rtmToken: Scalars['String'];
  uid: Scalars['String'];
  userId: Scalars['ID'];
};

export type AnswerCount = {
  __typename?: 'AnswerCount';
  answerId: Scalars['ID'];
  count: Scalars['Int'];
};

export type AnswerOption = {
  __typename?: 'AnswerOption';
  description: Array<TranslatedField>;
  id: Scalars['ID'];
  isCorrectAnswer: Scalars['Boolean'];
};

export type AnswerOptionInput = {
  description: Array<TranslatedFieldInput>;
  isCorrectAnswer?: InputMaybe<Scalars['Boolean']>;
};

export type AnswersProfiling = {
  __typename?: 'AnswersProfiling';
  multipleAnswers?: Maybe<Array<Maybe<Scalars['String']>>>;
  question: Scalars['String'];
  singleAnswer?: Maybe<Scalars['String']>;
};

export type AnswersProfilingInput = {
  multipleAnswers?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  question: Scalars['String'];
  singleAnswer?: InputMaybe<Scalars['String']>;
};

export type AssignDeliverablesInput = {
  assignToUserId: Scalars['ID'];
  cohortId: Scalars['ID'];
  interactiveContentId: Scalars['ID'];
  userIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type Attendance = {
  __typename?: 'Attendance';
  attendanceCheck: AttendanceCheck;
  calendarEventId: Scalars['ID'];
  id: Scalars['ID'];
  userId: Scalars['ID'];
};

export type AttendanceCheck = {
  __typename?: 'AttendanceCheck';
  firstCheck?: Maybe<Scalars['DateTime']>;
  secondCheck?: Maybe<Scalars['DateTime']>;
};

export type AttendanceResponse = {
  __typename?: 'AttendanceResponse';
  createdAttendance: Attendance;
  liveSessionLink: Scalars['String'];
};

export type Author = {
  __typename?: 'Author';
  id: Scalars['String'];
  name: Scalars['String'];
  role?: Maybe<Scalars['String']>;
};

export type AuthorInput = {
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<Scalars['String']>;
};

export type AwardCoinsInput = {
  cohortId: Scalars['ID'];
  comments?: InputMaybe<Scalars['String']>;
  userCoins: Array<AwardCoinsToUser>;
};

export type AwardCoinsToUser = {
  amount: Scalars['Int'];
  userId: Scalars['ID'];
};

export type Badge = {
  __typename?: 'Badge';
  badgeColor: Scalars['String'];
  badgeType: Scalars['String'];
  description: Array<TranslatedField>;
  id: Scalars['ID'];
  imageUrl: Scalars['String'];
  name: Array<TranslatedField>;
};

export type Badge2 = {
  __typename?: 'Badge2';
  color: Scalars['String'];
  id: Scalars['ID'];
  imageUrl: Badge2ImageUrl;
  name: Scalars['String'];
  trigger: Badge2Trigger;
};

export type Badge2ImageUrl = {
  __typename?: 'Badge2ImageUrl';
  complete: Scalars['String'];
  mini: Scalars['String'];
};

export type Badge2ImageUrlInput = {
  complete: Scalars['String'];
  mini: Scalars['String'];
};

export type Badge2Trigger = {
  __typename?: 'Badge2Trigger';
  id?: Maybe<Scalars['String']>;
  type: Scalars['String'];
};

export type Badge2TriggerInput = {
  id?: InputMaybe<Scalars['String']>;
  type: Scalars['String'];
};

export type BadgeNotification = {
  __typename?: 'BadgeNotification';
  badgeId?: Maybe<Scalars['String']>;
  badgeName?: Maybe<Scalars['String']>;
  badgeUrl?: Maybe<Scalars['String']>;
};

export type BannerContinueProgress = {
  __typename?: 'BannerContinueProgress';
  course?: Maybe<CourseBannerProgress>;
  interactiveContent?: Maybe<InteractiveContentBannerContinueProgress>;
  program?: Maybe<ProgramBannerProgress>;
  progress?: Maybe<Scalars['Int']>;
  showBanner: Scalars['Boolean'];
  userId: Scalars['ID'];
};

export type BaseSchool = {
  id: Scalars['ID'];
  linkedInId?: Maybe<Scalars['String']>;
  logoUrl?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  theme?: Maybe<Theme>;
};

export type Bootcamp = {
  __typename?: 'Bootcamp';
  bootcampType?: Maybe<Scalars['String']>;
  brand?: Maybe<Array<Maybe<Category>>>;
  brandId?: Maybe<Array<Maybe<Scalars['ID']>>>;
  description?: Maybe<Scalars['String']>;
  faqs?: Maybe<Array<Faq>>;
  id?: Maybe<Scalars['ID']>;
  level?: Maybe<Array<Maybe<Category>>>;
  levelId?: Maybe<Array<Maybe<Scalars['ID']>>>;
  prerequisites?: Maybe<Array<Scalars['ID']>>;
  status?: Maybe<Scalars['String']>;
  subCategory?: Maybe<Array<Maybe<Category>>>;
  subCategoryId?: Maybe<Array<Maybe<Scalars['ID']>>>;
  tags?: Maybe<Array<Scalars['String']>>;
  title?: Maybe<Scalars['String']>;
};

export type BootcampBannerEntry = {
  __typename?: 'BootcampBannerEntry';
  cohort?: Maybe<CohortForBootcamp>;
  parent?: Maybe<Bootcamp>;
  release?: Maybe<BootcampRelease>;
  releaseBlock?: Maybe<Scalars['Boolean']>;
  totalAttendees?: Maybe<Scalars['Int']>;
};

export type BootcampClass = {
  __typename?: 'BootcampClass';
  dateTime?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  link?: Maybe<Scalars['String']>;
  mentors?: Maybe<Array<Maybe<Scalars['String']>>>;
  order?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<EntityName>;
};

export type BootcampClassInput = {
  dateTime?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  link?: InputMaybe<Scalars['String']>;
  mentors?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  order?: InputMaybe<Scalars['Int']>;
  title?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<EntityName>;
};

export type BootcampMentor = {
  __typename?: 'BootcampMentor';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  jobtitle?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  profilePicture?: Maybe<Scalars['String']>;
};

export type BootcampMentorInput = {
  description?: InputMaybe<Scalars['String']>;
  jobtitle?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  profilePicture?: InputMaybe<Scalars['String']>;
};

export type BootcampPlaylistObject = {
  __typename?: 'BootcampPlaylistObject';
  mentorsPlaylists?: Maybe<Array<Maybe<MentorPlaylist>>>;
  prevSessionsPlaylist?: Maybe<Array<Maybe<Scalars['String']>>>;
  suggestedTutorialsPlaylist?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type BootcampRelease = {
  __typename?: 'BootcampRelease';
  bootcampType?: Maybe<Scalars['String']>;
  classes?: Maybe<Array<BootcampClass>>;
  description?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['DateTime']>;
  faqs?: Maybe<Array<Faq>>;
  id?: Maybe<Scalars['ID']>;
  linkForResources?: Maybe<Scalars['String']>;
  mentors?: Maybe<Array<BootcampMentor>>;
  mentorsIds?: Maybe<Array<Scalars['ID']>>;
  new?: Maybe<Scalars['Boolean']>;
  parentId?: Maybe<Scalars['ID']>;
  playlists?: Maybe<BootcampPlaylistObject>;
  prerequisites?: Maybe<Array<Scalars['ID']>>;
  signUpLink?: Maybe<Scalars['String']>;
  slots?: Maybe<Scalars['Int']>;
  speakers?: Maybe<Array<BootcampSpeaker>>;
  startDate?: Maybe<Scalars['DateTime']>;
  targetAudience?: Maybe<Array<Scalars['String']>>;
  title?: Maybe<Scalars['String']>;
  whatYouWillLearn?: Maybe<Array<Scalars['String']>>;
};

export type BootcampSpeaker = {
  __typename?: 'BootcampSpeaker';
  jobtitle?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  profilePicture?: Maybe<Scalars['String']>;
};

export type BootcampSpeakerInput = {
  id?: InputMaybe<Scalars['ID']>;
  jobtitle?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  profilePicture?: InputMaybe<Scalars['String']>;
};

export type BootcampsBannerSummary = {
  __typename?: 'BootcampsBannerSummary';
  completedBanner?: Maybe<Array<BootcampBannerEntry>>;
  ctaBanner?: Maybe<Array<BootcampBannerEntry>>;
  myBootcampsBanner?: Maybe<Array<BootcampBannerEntry>>;
};

export enum CacheControlScope {
  Private = 'PRIVATE',
  Public = 'PUBLIC'
}

export type CalendarEvent = {
  __typename?: 'CalendarEvent';
  allDay: Scalars['Boolean'];
  cohort?: Maybe<Cohort>;
  course: Course;
  courseId: Scalars['ID'];
  duration: Scalars['Float'];
  end: Scalars['DateTime'];
  hasCalendarEventStarted?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  isCalendarEventInThePast?: Maybe<Scalars['Boolean']>;
  liveSessionLink: Scalars['String'];
  presentationLink?: Maybe<Scalars['String']>;
  recordingLink?: Maybe<Scalars['String']>;
  responsibles: Array<Responsible>;
  responsiblesCount: Scalars['Int'];
  session?: Maybe<Session>;
  sessionId?: Maybe<Scalars['ID']>;
  start: Scalars['DateTime'];
};

export type Category = {
  __typename?: 'Category';
  color?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  pluralName?: Maybe<Scalars['String']>;
  type: Scalars['String'];
};

export type CategoryInput = {
  color?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  name: Scalars['String'];
  pluralName?: InputMaybe<Scalars['String']>;
  type: Scalars['String'];
};

export type Certificate = {
  __typename?: 'Certificate';
  badge: Badge2;
  createdAt: Scalars['DateTime'];
  eventId: Scalars['ID'];
  eventName: Scalars['String'];
  eventType: EntityName;
  id: Scalars['ID'];
  permalink: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  userId: Scalars['ID'];
  userName: Scalars['String'];
};

export type CertificateNotification = {
  __typename?: 'CertificateNotification';
  certificateId?: Maybe<Scalars['String']>;
  certificateUrl?: Maybe<Scalars['String']>;
  certificatename?: Maybe<Scalars['String']>;
};

export type ChangeHistory = {
  __typename?: 'ChangeHistory';
  createdAt: Scalars['DateTime'];
  entity: EntityName;
  entityId: Scalars['ID'];
  id: Scalars['ID'];
  typeOfChange: ChangeType;
  user: User;
};

export enum ChangeType {
  Created = 'CREATED',
  Deleted = 'DELETED',
  Modified = 'MODIFIED'
}

export type ChannelConfig = DashboardNotificationConfig | DiscordNotificationConfig | EmailNotificationConfig | SlackNotificationConfig;

export type ChinaAgentForm = {
  __typename?: 'ChinaAgentForm';
  product: ChinaAgentFormProduct;
  tcAccepted: Scalars['Boolean'];
  user: ChinaAgentFormUser;
};

export type ChinaAgentFormProduct = {
  __typename?: 'ChinaAgentFormProduct';
  budget: Scalars['String'];
  details?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isQuoted: Scalars['Boolean'];
  isSelected: Scalars['Boolean'];
  months: Scalars['String'];
  platforms: Array<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type ChinaAgentFormProductInput = {
  budget: Scalars['String'];
  details?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  isQuoted: Scalars['Boolean'];
  isSelected: Scalars['Boolean'];
  months: Scalars['String'];
  platforms: Array<Scalars['String']>;
  url?: InputMaybe<Scalars['String']>;
};

export type ChinaAgentFormUser = {
  __typename?: 'ChinaAgentFormUser';
  id: Scalars['String'];
  isAlreadySelling: Scalars['Boolean'];
};

export type ChinaAgentFormUserInput = {
  id: Scalars['String'];
  isAlreadySelling: Scalars['Boolean'];
};

export type City = {
  __typename?: 'City';
  countryId: Scalars['ID'];
  id: Scalars['ID'];
  name: Scalars['String'];
  regionId: Scalars['ID'];
};

export type Cohort = {
  __typename?: 'Cohort';
  bootcampReleaseId?: Maybe<Scalars['ID']>;
  calendarEvents: Array<CalendarEvent>;
  calendarEventsDuration: Scalars['Float'];
  code: Scalars['String'];
  cohortType?: Maybe<EntityName>;
  courseId: Array<Scalars['ID']>;
  courses: Array<Course>;
  coursesWithModules: CoursesWithModules;
  defaultLiveSessionLink?: Maybe<Scalars['String']>;
  deliverables: Array<Deliverable>;
  discordChannel?: Maybe<Scalars['String']>;
  end?: Maybe<Scalars['DateTime']>;
  highTicketMentorshipId?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  id_mysql?: Maybe<Scalars['String']>;
  invites: Array<Invite>;
  isPublic: Scalars['Boolean'];
  prices?: Maybe<Array<Price>>;
  program?: Maybe<Program>;
  schoolId?: Maybe<Scalars['ID']>;
  sessionDefaultDuration: Scalars['Int'];
  slackChannel?: Maybe<Scalars['String']>;
  sponsors?: Maybe<Array<PublicOrganization>>;
  start: Scalars['DateTime'];
  teams: Array<Team>;
  timeZone: Scalars['String'];
  userCurrentModule?: Maybe<Module>;
  userGrade?: Maybe<Grade>;
  userProgress?: Maybe<Progress>;
  users: Array<User>;
  weekdays: Array<Scalars['Int']>;
};


export type CohortCalendarEventsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type CohortCoursesWithModulesArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  startModuleId?: InputMaybe<Scalars['ID']>;
};


export type CohortDeliverablesArgs = {
  interactiveContentId?: InputMaybe<Scalars['ID']>;
  userId?: InputMaybe<Scalars['ID']>;
};


export type CohortUsersArgs = {
  userSearchInput?: InputMaybe<EntitySearchInput>;
};

export type CohortForBootcamp = {
  __typename?: 'CohortForBootcamp';
  bootcampReleaseId?: Maybe<Scalars['ID']>;
  code?: Maybe<Scalars['String']>;
  cohortType?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  schoolId?: Maybe<Scalars['ID']>;
  start?: Maybe<Scalars['DateTime']>;
};

export type CohortWithRole = {
  __typename?: 'CohortWithRole';
  cohort: Cohort;
  isBlocked: Scalars['Boolean'];
  roles: Array<UserRole>;
};

export type Coins = {
  __typename?: 'Coins';
  amount: Scalars['Int'];
  id: Scalars['ID'];
};

export type Comment = {
  __typename?: 'Comment';
  _id?: Maybe<Scalars['String']>;
  author: Author;
  comment: Scalars['String'];
  commentId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  postId: Scalars['String'];
};

export type CommentInput = {
  _id?: InputMaybe<Scalars['String']>;
  author: AuthorInput;
  comment: Scalars['String'];
  commentId?: InputMaybe<Scalars['String']>;
  postId: Scalars['String'];
};

export type CommunityEvent = {
  __typename?: 'CommunityEvent';
  advisor: Scalars['String'];
  category: Category;
  city: Category;
  date: Scalars['DateTime'];
  eventType: Category;
  format: Category;
  id: Scalars['ID'];
  imgUrl?: Maybe<Scalars['String']>;
  isVisible: Scalars['Boolean'];
  title: Scalars['String'];
  urlRegister?: Maybe<Scalars['String']>;
};

export type CommunityEventInput = {
  advisor: Scalars['String'];
  categoryId: Scalars['ID'];
  cityId: Scalars['ID'];
  date: Scalars['DateTime'];
  eventTypeId: Scalars['ID'];
  formatId: Scalars['ID'];
  imgUrl: Scalars['String'];
  isVisible: Scalars['Boolean'];
  title: Scalars['String'];
  urlRegister: Scalars['String'];
};

export type Consultant = {
  __typename?: 'Consultant';
  bio: Scalars['String'];
  buttonUrl: Scalars['String'];
  buttonUrl30Min: Scalars['String'];
  description: Scalars['String'];
  id: Scalars['ID'];
  isVisible?: Maybe<Scalars['Boolean']>;
  job: Scalars['String'];
  name: Scalars['String'];
  photo: Scalars['String'];
  popularity: Scalars['String'];
  previousExperience: Array<Maybe<Scalars['String']>>;
  sections: Array<Maybe<Scalars['String']>>;
  shortSpecialty: Array<Maybe<Scalars['String']>>;
  skills: Array<Maybe<Scalars['String']>>;
  socialMedia?: Maybe<ConsultantSocialMedia>;
  value: Scalars['String'];
  value30Min: Scalars['String'];
};

export type ConsultantSocialMedia = {
  __typename?: 'ConsultantSocialMedia';
  facebook?: Maybe<Scalars['String']>;
  instagram?: Maybe<Scalars['String']>;
  linkedIn?: Maybe<Scalars['String']>;
  twitter?: Maybe<Scalars['String']>;
  youTube?: Maybe<Scalars['String']>;
};

export type ConsultantSocialMediaInput = {
  facebook?: InputMaybe<Scalars['String']>;
  instagram?: InputMaybe<Scalars['String']>;
  linkedIn?: InputMaybe<Scalars['String']>;
  twitter?: InputMaybe<Scalars['String']>;
  youTube?: InputMaybe<Scalars['String']>;
};

export type Country = {
  __typename?: 'Country';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type Course = {
  __typename?: 'Course';
  badges?: Maybe<Array<Badge>>;
  brand?: Maybe<Array<Maybe<Category>>>;
  code: Scalars['String'];
  cohorts: Array<Cohort>;
  coins?: Maybe<Scalars['String']>;
  courseType?: Maybe<EntityName>;
  description?: Maybe<Array<TranslatedField>>;
  details?: Maybe<Array<TranslatedField>>;
  holidays?: Maybe<Array<Holiday>>;
  id: Scalars['ID'];
  id_mysql?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  interactiveContents: Array<InteractiveContent>;
  level?: Maybe<Array<Maybe<Category>>>;
  mentors: Array<User>;
  mentorsIds?: Maybe<Array<Scalars['ID']>>;
  moduleCount: Scalars['Int'];
  modules: Array<Module>;
  /** @deprecated Use 'translatedName' instead */
  name: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
  organizations: Array<PublicOrganizationWithRole>;
  school: School;
  sessions: Array<Session>;
  sessionsDuration: Scalars['Float'];
  sprints: Array<Sprint>;
  status: CourseStatus;
  students: Array<User>;
  subCategory?: Maybe<Array<Maybe<Category>>>;
  tags?: Maybe<Array<Scalars['String']>>;
  translatedName?: Maybe<Array<TranslatedField>>;
  userBadges?: Maybe<Array<Badge>>;
  userCurrentModule?: Maybe<Module>;
  userGrade?: Maybe<Grade>;
  userProgress?: Maybe<Progress>;
  users: Array<User>;
  visibility: CourseVisibility;
};


export type CourseCohortsArgs = {
  isPublic?: InputMaybe<Scalars['Boolean']>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type CourseInteractiveContentsArgs = {
  interactiveContentTypes: Array<InteractiveContentType>;
};


export type CourseModulesArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type CourseOrganizationsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type CourseUserCurrentModuleArgs = {
  cohortId: Scalars['ID'];
};


export type CourseUserGradeArgs = {
  cohortId?: InputMaybe<Scalars['ID']>;
};


export type CourseUserProgressArgs = {
  cohortId?: InputMaybe<Scalars['ID']>;
};

export type CourseBannerProgress = {
  __typename?: 'CourseBannerProgress';
  cohortsId: Scalars['ID'];
  courseType?: Maybe<EntityName>;
  id: Scalars['ID'];
  imageUrl?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  translatedName?: Maybe<Array<TranslatedField>>;
};

export type CourseCohortPrice = {
  __typename?: 'CourseCohortPrice';
  courses: Array<CourseDetails>;
  invitationError?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  prices?: Maybe<Array<Price>>;
  program?: Maybe<ProgramDetails>;
  start?: Maybe<Scalars['DateTime']>;
};

export type CourseDetails = {
  __typename?: 'CourseDetails';
  courseId: Scalars['ID'];
  description?: Maybe<Array<TranslatedField>>;
  details?: Maybe<Array<TranslatedField>>;
  imageUrl?: Maybe<Scalars['String']>;
  /** @deprecated use 'translatedName' instead */
  name?: Maybe<Scalars['String']>;
  translatedName: Array<TranslatedField>;
};

export enum CourseStatus {
  Draft = 'DRAFT',
  Old = 'OLD',
  Published = 'PUBLISHED'
}

export enum CourseVisibility {
  Private = 'PRIVATE',
  Public = 'PUBLIC'
}

export type CoursesWithModules = {
  __typename?: 'CoursesWithModules';
  courses: Array<Course>;
  modules: Array<Module>;
  nextModule?: Maybe<Module>;
  prevModule?: Maybe<Module>;
};

export type CreateBadge2Input = {
  color: Scalars['String'];
  imageUrl: Badge2ImageUrlInput;
  name: Scalars['String'];
  trigger: Badge2TriggerInput;
};

export type CreateBadgeInput = {
  badgeColor?: InputMaybe<Scalars['String']>;
  badgeType?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Array<TranslatedFieldInput>>;
  imageUrl?: InputMaybe<Scalars['String']>;
  name: Array<TranslatedFieldInput>;
};

export type CreateBootcampInput = {
  bootcampType?: InputMaybe<Scalars['String']>;
  brandId: Array<Scalars['ID']>;
  description?: InputMaybe<Scalars['String']>;
  faqs?: InputMaybe<Array<FaqInput>>;
  levelId: Array<Scalars['ID']>;
  prerequisites?: InputMaybe<Array<Scalars['ID']>>;
  status?: InputMaybe<Scalars['String']>;
  subCategoryId: Array<Scalars['ID']>;
  tags?: InputMaybe<Array<Scalars['String']>>;
  title?: InputMaybe<Scalars['String']>;
};

export type CreateBootcampReleaseInput = {
  bootcampType?: InputMaybe<Scalars['String']>;
  classes?: InputMaybe<Array<InputMaybe<BootcampClassInput>>>;
  description?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  linkForResources?: InputMaybe<Scalars['String']>;
  mentors?: InputMaybe<Array<BootcampMentorInput>>;
  new?: InputMaybe<Scalars['Boolean']>;
  parentId?: InputMaybe<Scalars['ID']>;
  playlists?: InputMaybe<PlaylistObject>;
  signUpLink?: InputMaybe<Scalars['String']>;
  speakers?: InputMaybe<Array<InputMaybe<BootcampSpeakerInput>>>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  tags?: InputMaybe<Array<Scalars['String']>>;
  targetAudience?: InputMaybe<Array<Scalars['String']>>;
  title?: InputMaybe<Scalars['String']>;
  whatYouWillLearn?: InputMaybe<Array<Scalars['String']>>;
};

export type CreateCalendarEventInput = {
  allDay?: InputMaybe<Scalars['Boolean']>;
  cohortId: Scalars['ID'];
  duration: Scalars['Float'];
  liveSessionLink: Scalars['String'];
  presentationLink?: InputMaybe<Scalars['String']>;
  recordingLink?: InputMaybe<Scalars['String']>;
  responsibles?: InputMaybe<Array<ResponsibleInput>>;
  sessionId: Scalars['ID'];
  start: Scalars['DateTime'];
};

export type CreateCertificateInput = {
  eventId: Scalars['ID'];
  eventType: Scalars['String'];
  userId: Scalars['ID'];
};

export type CreateChangeHistoryInput = {
  entity: EntityName;
  entityId: Scalars['ID'];
  newState?: InputMaybe<Scalars['String']>;
  previousState?: InputMaybe<Scalars['String']>;
  typeOfChange: ChangeType;
};

export type CreateChinaAgentFormInput = {
  product: ChinaAgentFormProductInput;
  tcAccepted: Scalars['Boolean'];
  user: ChinaAgentFormUserInput;
};

export type CreateCohortInput = {
  bootcampReleaseId?: InputMaybe<Scalars['ID']>;
  code: Scalars['String'];
  cohortType?: InputMaybe<EntityName>;
  courseId?: InputMaybe<Array<Scalars['ID']>>;
  defaultLiveSessionLink?: InputMaybe<Scalars['String']>;
  discordChannel?: InputMaybe<Scalars['String']>;
  isPublic: Scalars['Boolean'];
  prices?: InputMaybe<Array<PriceInput>>;
  programId?: InputMaybe<Scalars['ID']>;
  sessionDefaultDuration?: InputMaybe<Scalars['Int']>;
  slackChannel?: InputMaybe<Scalars['String']>;
  sponsorIds?: InputMaybe<Array<Scalars['ID']>>;
  start: Scalars['DateTime'];
  timeZone: Scalars['String'];
  weekdays?: InputMaybe<Array<Scalars['Int']>>;
};

export type CreateConsultantInput = {
  bio: Scalars['String'];
  buttonUrl: Scalars['String'];
  buttonUrl30Min: Scalars['String'];
  description: Scalars['String'];
  isVisible?: InputMaybe<Scalars['Boolean']>;
  job: Scalars['String'];
  name: Scalars['String'];
  photo: Scalars['String'];
  popularity: Scalars['String'];
  previousExperience: Array<InputMaybe<Scalars['String']>>;
  sections: Array<InputMaybe<Scalars['String']>>;
  shortSpecialty: Array<InputMaybe<Scalars['String']>>;
  skills: Array<InputMaybe<Scalars['String']>>;
  socialMedia?: InputMaybe<ConsultantSocialMediaInput>;
  value: Scalars['String'];
  value30Min: Scalars['String'];
};

export type CreateCourseInput = {
  badgesIds?: InputMaybe<Array<Scalars['ID']>>;
  brandId: Array<Scalars['ID']>;
  code: Scalars['String'];
  coins?: InputMaybe<Scalars['String']>;
  courseType?: InputMaybe<EntityName>;
  description?: InputMaybe<Array<TranslatedFieldInput>>;
  details?: InputMaybe<Array<TranslatedFieldInput>>;
  imageUrl?: InputMaybe<Scalars['String']>;
  levelId: Array<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['Int']>;
  organizations?: InputMaybe<Array<PublicOrganizationWithRoleInput>>;
  status?: InputMaybe<CourseStatus>;
  subCategoryId: Array<Scalars['ID']>;
  tags?: InputMaybe<Array<Scalars['String']>>;
  translatedName: Array<TranslatedFieldInput>;
  visibility?: InputMaybe<CourseVisibility>;
};

export type CreateDeliverableInput = {
  cohortId: Scalars['ID'];
  deliverableUrl: Scalars['String'];
  interactiveContentId: Scalars['ID'];
};

export type CreateDiscordChannelInput = {
  cohortId?: InputMaybe<Scalars['ID']>;
};

export type CreateFormInput = {
  allowResponseUpdate: Scalars['Boolean'];
  courseId: Scalars['ID'];
  isGradeable: Scalars['Boolean'];
  name?: InputMaybe<Array<TranslatedFieldInput>>;
  questions: Array<QuestionInput>;
};

export type CreateFormInstanceInput = {
  cohortId?: InputMaybe<Scalars['ID']>;
  end?: InputMaybe<Scalars['DateTime']>;
  entity: EntityName;
  entityId: Scalars['ID'];
  formId: Scalars['ID'];
  start?: InputMaybe<Scalars['DateTime']>;
};

export type CreateFormResponseInput = {
  cohortId?: InputMaybe<Scalars['ID']>;
  entity?: InputMaybe<EntityName>;
  entityId?: InputMaybe<Scalars['ID']>;
  formId?: InputMaybe<Scalars['ID']>;
  formInstanceId?: InputMaybe<Scalars['ID']>;
  responses: Array<QuestionResponseInput>;
};

export type CreateGradeInput = {
  cohortId: Scalars['ID'];
  comments?: InputMaybe<Scalars['String']>;
  entity: EntityName;
  entityId: Scalars['ID'];
  grade: Scalars['Float'];
  userId: Scalars['ID'];
};

export type CreateHotProductInput = {
  competitors: Array<HotProductCompetitorInput>;
  description: Scalars['String'];
  imagesURLs: Array<Scalars['String']>;
  isVisible?: InputMaybe<Scalars['Boolean']>;
  minQuantity: Scalars['Int'];
  month?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  price: Array<HotProductPriceInput>;
  recommendations: Array<Scalars['String']>;
  releaseDate?: InputMaybe<Scalars['DateTime']>;
  reportsAvailable: Array<Scalars['String']>;
  reportsDownloadLink: Scalars['String'];
  score: Scalars['String'];
};

export type CreateInteractiveContentInput = {
  contentType: InteractiveContentType;
  courseId: Scalars['ID'];
  description?: InputMaybe<Array<TranslatedFieldInput>>;
  formId?: InputMaybe<Scalars['ID']>;
  rank?: InputMaybe<Scalars['String']>;
  sessionId: Scalars['ID'];
  subTitle?: InputMaybe<Array<TranslatedFieldInput>>;
  title: Array<TranslatedFieldInput>;
  video?: InputMaybe<Array<TranslatedFieldInput>>;
};

export type CreateLiveSessionRecordingsInput = {
  channelName: Scalars['String'];
  isRecordingSent: Scalars['Boolean'];
  link?: InputMaybe<Scalars['String']>;
  notifiedAt: Scalars['DateTime'];
  recordedAt: Scalars['DateTime'];
};

export type CreateLiveSessionsInput = {
  brandId: Array<Scalars['ID']>;
  endDate: Scalars['DateTime'];
  imageUrlMentor: Scalars['String'];
  levelId: Array<Scalars['ID']>;
  liveSessionType?: InputMaybe<LiveSessionType>;
  mentorId: Scalars['ID'];
  mentorName: Scalars['String'];
  moduleId: Scalars['ID'];
  name: Scalars['String'];
  programId: Scalars['ID'];
  recurrence: LiveSessionRecurrence;
  startDate: Scalars['DateTime'];
  subCategoryId: Array<Scalars['ID']>;
  userId: Scalars['ID'];
};

export type CreateLiveSessionsSchedulingInput = {
  liveSessionsId: Scalars['ID'];
  timezone: Scalars['String'];
};

export type CreateMarketplaceServiceInput = {
  agency?: InputMaybe<Scalars['String']>;
  agencyImage?: InputMaybe<Scalars['String']>;
  categoryId: Scalars['ID'];
  contactLink?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  draft?: InputMaybe<Scalars['Boolean']>;
  howItWorks?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  imageGallery?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  published?: InputMaybe<Scalars['Boolean']>;
  recipientsEmails?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  responseTime?: InputMaybe<Scalars['String']>;
  services?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  subcategoryId?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  subtitle?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
  whyUs?: InputMaybe<Scalars['String']>;
};

export type CreateMasterClassInput = {
  brandId: Array<Scalars['ID']>;
  description: Scalars['String'];
  endDate: Scalars['DateTime'];
  imageUrlMentor: Scalars['String'];
  isWorkshop?: InputMaybe<Scalars['Boolean']>;
  levelId: Array<Scalars['ID']>;
  liveSessionType?: InputMaybe<LiveSessionType>;
  mentorId: Scalars['ID'];
  mentorName: Scalars['String'];
  name: Scalars['String'];
  startDate: Scalars['DateTime'];
  subCategoryId: Array<Scalars['ID']>;
  userId: Scalars['ID'];
};

export type CreateModuleInput = {
  badgesIds?: InputMaybe<Array<Scalars['ID']>>;
  courseId: Scalars['ID'];
  description?: InputMaybe<Array<TranslatedFieldInput>>;
  name: Array<TranslatedFieldInput>;
};

export type CreateNewNotificationInput = {
  notificationRecords?: InputMaybe<NotificationDataInput>;
  userId?: InputMaybe<Scalars['String']>;
};

export type CreateNotificationInput = {
  channel: NotificationChannel;
  cohortId?: InputMaybe<Scalars['ID']>;
  discordChannel?: InputMaybe<Scalars['String']>;
  message: Array<TranslatedFieldInput>;
  notificationTime: Scalars['DateTime'];
  recipientId?: InputMaybe<Scalars['ID']>;
  recipientType: Scalars['String'];
  schoolId?: InputMaybe<Scalars['ID']>;
  slackChannel?: InputMaybe<Scalars['String']>;
  subject?: InputMaybe<Array<TranslatedFieldInput>>;
  text?: InputMaybe<Array<TranslatedFieldInput>>;
  userEmail?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
};

export type CreateOrUpdateGamificationInput = {
  eventRecords?: InputMaybe<EventDataGamificationInput>;
  range?: InputMaybe<Scalars['String']>;
  stars?: InputMaybe<Scalars['Int']>;
  userId?: InputMaybe<Scalars['ID']>;
};

export type CreateOrUpdatePostInput = {
  _id?: InputMaybe<Scalars['String']>;
  active?: InputMaybe<Scalars['Boolean']>;
  author: AuthorInput;
  category?: InputMaybe<Scalars['String']>;
  content: Scalars['String'];
  tags: Array<InputMaybe<Scalars['String']>>;
  title?: InputMaybe<Scalars['String']>;
};

export type CreateOrderInput = {
  currency: Scalars['String'];
  items: Array<OrderItemInput>;
  paymentMethodType: PaymentMethod;
  referrerCode?: InputMaybe<Scalars['String']>;
  token?: InputMaybe<Scalars['String']>;
};

export type CreateOrganizationInput = {
  imageUrl?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
};

export type CreateProfilingUserInput = {
  answers?: InputMaybe<Array<AnswersProfilingInput>>;
  userId: Scalars['ID'];
};

export type CreateProgramInput = {
  brandId: Array<Scalars['ID']>;
  code: Scalars['String'];
  description?: InputMaybe<Array<TranslatedFieldInput>>;
  imageUrl?: InputMaybe<Scalars['String']>;
  items: Array<ProgramItemInput>;
  levelId: Array<Scalars['ID']>;
  name: Scalars['String'];
  order?: InputMaybe<Scalars['Int']>;
  programType?: InputMaybe<EntityName>;
  status: CourseStatus;
  subCategoryId: Array<Scalars['ID']>;
  tags?: InputMaybe<Array<Scalars['String']>>;
  thumbnailUrl?: InputMaybe<Scalars['String']>;
};

export type CreateProgressInput = {
  cohortId: Scalars['ID'];
  interactiveContentId: Scalars['ID'];
  progress: Scalars['Int'];
  userId?: InputMaybe<Scalars['ID']>;
};

export type CreateSecondCheckNotificationInput = {
  baseURL: Scalars['String'];
  calendarEventId: Scalars['ID'];
};

export type CreateSessionInput = {
  activityType: ActivityType;
  affiliateLinks?: InputMaybe<Array<InputMaybe<AffiliateLinksInput>>>;
  courseId: Scalars['ID'];
  downloableContent?: InputMaybe<Array<InputMaybe<DownloableContentInput>>>;
  duration?: InputMaybe<Scalars['Float']>;
  goals?: InputMaybe<Array<LearningGoalInput>>;
  moduleId: Scalars['ID'];
  name: Array<TranslatedFieldInput>;
  prerecordingLink?: InputMaybe<Scalars['String']>;
  presentationLink?: InputMaybe<Scalars['String']>;
  rank?: InputMaybe<Scalars['String']>;
};

export type CreateSolutionInput = {
  benefits?: InputMaybe<Array<Scalars['String']>>;
  categoryId: Scalars['ID'];
  description: Scalars['String'];
  details: Scalars['String'];
  features: Array<SolutionFeatureInput>;
  imgUrl: Scalars['String'];
  isVisible: Scalars['Boolean'];
  links: Array<SolutionLinkInput>;
  name: Scalars['String'];
  popularity: Scalars['String'];
  price?: InputMaybe<SolutionPriceInput>;
};

export type CreateTeamInput = {
  cohortId: Scalars['ID'];
  memberIds: Array<Scalars['ID']>;
  name: Scalars['String'];
};

export type CreateTokenAgoraInput = {
  channelName: Scalars['String'];
  uid: Scalars['String'];
};

export type CreateUserBadge2Input = {
  badgeId: Scalars['ID'];
  userId: Scalars['ID'];
};

export type CreateUserBadge2WithTriggerInput = {
  trigger: Badge2TriggerInput;
  userId: Scalars['ID'];
};

export type CreateUserInput = {
  avatarUrl?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  firebaseUid?: InputMaybe<Scalars['String']>;
  gitHubUsername?: InputMaybe<Scalars['String']>;
  invitationToken?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  password?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  roles?: InputMaybe<Array<UserRole>>;
  salesforceID?: InputMaybe<Scalars['String']>;
};

export type CreateWorkReportInput = {
  category: Scalars['String'];
  comments: Scalars['String'];
  totalHours: Scalars['Float'];
  userId?: InputMaybe<Scalars['ID']>;
};

export type DashboardNotificationConfig = {
  __typename?: 'DashboardNotificationConfig';
  userId: Scalars['ID'];
};

export type DataPortal1Input = {
  userId: Scalars['String'];
};

export type DeleteUserBadge2Input = {
  badgeId: Scalars['ID'];
  userId: Scalars['ID'];
};

export type Deliverable = {
  __typename?: 'Deliverable';
  assignedTo?: Maybe<User>;
  assignedToUserId?: Maybe<Scalars['ID']>;
  cohortId: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  deliverableUrl: Scalars['String'];
  grade?: Maybe<Grade>;
  id: Scalars['ID'];
  interactiveContent?: Maybe<InteractiveContent>;
  interactiveContentId: Scalars['ID'];
  isBlocked: Scalars['Boolean'];
  user?: Maybe<User>;
  userId: Scalars['ID'];
};

export type Description = {
  __typename?: 'Description';
  descriptionEvent?: Maybe<Scalars['String']>;
  eventId?: Maybe<Scalars['String']>;
  eventName?: Maybe<Scalars['String']>;
  eventType?: Maybe<Scalars['String']>;
  userEventId?: Maybe<Scalars['String']>;
  userName?: Maybe<Scalars['String']>;
};

export type DescriptionInput = {
  descriptionEvent?: InputMaybe<Scalars['String']>;
  eventId?: InputMaybe<Scalars['String']>;
  eventName?: InputMaybe<Scalars['String']>;
  eventType?: InputMaybe<Scalars['String']>;
  userEventId?: InputMaybe<Scalars['String']>;
  userName?: InputMaybe<Scalars['String']>;
};

export type DiscordConfig = {
  __typename?: 'DiscordConfig';
  botToken: Scalars['String'];
  cohortsChannelId: Scalars['String'];
  feedbackChannelId: Scalars['String'];
  serverId: Scalars['String'];
};

export type DiscordConfigInput = {
  botToken: Scalars['String'];
  cohortsChannelId: Scalars['String'];
  feedbackChannelId: Scalars['String'];
  serverId: Scalars['String'];
};

export type DiscordNotificationConfig = {
  __typename?: 'DiscordNotificationConfig';
  discordChannel: Scalars['String'];
};

export type DisenrollUserInput = {
  cohortId: Scalars['ID'];
  roleInCohort?: InputMaybe<UserRole>;
  salesforceID?: InputMaybe<Scalars['String']>;
  shouldNotifySalesforce?: InputMaybe<Scalars['Boolean']>;
  userId: Scalars['ID'];
};

export type DownloableContent = {
  __typename?: 'DownloableContent';
  link: Scalars['String'];
  name: Scalars['String'];
};

export type DownloableContentInput = {
  link: Scalars['String'];
  name: Scalars['String'];
};

export type EmailNotificationConfig = {
  __typename?: 'EmailNotificationConfig';
  emails: Array<Scalars['String']>;
  subject?: Maybe<Array<TranslatedField>>;
  text?: Maybe<Array<TranslatedField>>;
};

export type EnrollUserInput = {
  cohortId: Scalars['ID'];
  referrerCode?: InputMaybe<Scalars['String']>;
  roleInCohort: UserRole;
  salesforceID?: InputMaybe<Scalars['String']>;
  userId: Scalars['ID'];
};

export type EnrollUserMultipleInput = {
  bootcampReleaseId?: InputMaybe<Scalars['String']>;
  cohortIds: Array<Scalars['ID']>;
  contractId?: InputMaybe<Scalars['String']>;
  contractPositionId?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['Int']>;
  invoiceId?: InputMaybe<Scalars['String']>;
  orderDescription?: InputMaybe<Scalars['String']>;
  referrerCode?: InputMaybe<Scalars['String']>;
  roleInCohort: UserRole;
  salesforceUserId?: InputMaybe<Scalars['String']>;
  shouldNotifySalesforce?: InputMaybe<Scalars['Boolean']>;
  startDate?: InputMaybe<Scalars['Int']>;
  totalAttendees?: InputMaybe<Scalars['Int']>;
  userId: Scalars['ID'];
};

export type Enrollment = {
  __typename?: 'Enrollment';
  endDate?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  id_mysql?: Maybe<Scalars['String']>;
  referrerCode?: Maybe<Scalars['String']>;
  roles: Array<UserRole>;
  salesforceID?: Maybe<Scalars['String']>;
};

export enum EnrollmentStatus {
  Attempted = 'ATTEMPTED',
  Failed = 'FAILED',
  Successful = 'SUCCESSFUL'
}

export enum EntityName {
  AdvancedBootcamp = 'ADVANCED_BOOTCAMP',
  AdvancedCourse = 'ADVANCED_COURSE',
  Badge = 'BADGE',
  BasicBootcamp = 'BASIC_BOOTCAMP',
  Bootcamp = 'BOOTCAMP',
  CalendarEvent = 'CALENDAR_EVENT',
  Cohort = 'COHORT',
  Coins = 'COINS',
  CommunityEvent = 'COMMUNITY_EVENT',
  ComplementaryCourse = 'COMPLEMENTARY_COURSE',
  Course = 'COURSE',
  Deliverable = 'DELIVERABLE',
  Diplomat = 'DIPLOMAT',
  EliteMentorship = 'ELITE_MENTORSHIP',
  Feedback = 'FEEDBACK',
  Form = 'FORM',
  FormResponse = 'FORM_RESPONSE',
  Gamification = 'GAMIFICATION',
  HighTicketMentorship = 'HIGH_TICKET_MENTORSHIP',
  InteractiveContent = 'INTERACTIVE_CONTENT',
  LiveSession = 'LIVE_SESSION',
  LiveSessionAttendance = 'LIVE_SESSION_ATTENDANCE',
  Login = 'LOGIN',
  Marketplace = 'MARKETPLACE',
  MastermindBootcamp = 'MASTERMIND_BOOTCAMP',
  Module = 'MODULE',
  Program = 'PROGRAM',
  RegularClass = 'REGULAR_CLASS',
  RegularCourse = 'REGULAR_COURSE',
  Roundtable = 'ROUNDTABLE',
  School = 'SCHOOL',
  Session = 'SESSION',
  Solution = 'SOLUTION',
  Specialization = 'SPECIALIZATION',
  User = 'USER',
  VipMentorship = 'VIP_MENTORSHIP'
}

export type EntitySearchInput = {
  cohortId?: InputMaybe<Scalars['ID']>;
  courseId?: InputMaybe<Scalars['ID']>;
  entityName?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  notifyInBanner?: InputMaybe<Scalars['Boolean']>;
  offset?: InputMaybe<Scalars['Int']>;
  organizationId?: InputMaybe<Scalars['ID']>;
  programId?: InputMaybe<Scalars['ID']>;
  query?: InputMaybe<Scalars['String']>;
  roles?: InputMaybe<Array<UserRole>>;
  sort?: InputMaybe<Array<Scalars['String']>>;
};

export type EventDataGamification = {
  __typename?: 'EventDataGamification';
  awardedStars?: Maybe<Scalars['Int']>;
  eventType?: Maybe<Scalars['String']>;
  idEvent?: Maybe<Scalars['String']>;
  nameEvent?: Maybe<Scalars['String']>;
  previousRange?: Maybe<Scalars['String']>;
  previousStars?: Maybe<Scalars['Int']>;
  registerDate?: Maybe<Scalars['DateTime']>;
  rule?: Maybe<Scalars['String']>;
};

export type EventDataGamificationInput = {
  awardedStars?: InputMaybe<Scalars['Int']>;
  eventType?: InputMaybe<Scalars['String']>;
  idEvent?: InputMaybe<Scalars['String']>;
  nameEvent?: InputMaybe<Scalars['String']>;
  previousRange?: InputMaybe<Scalars['String']>;
  previousStars?: InputMaybe<Scalars['Int']>;
  registerDate?: InputMaybe<Scalars['DateTime']>;
  rule?: InputMaybe<Scalars['String']>;
};

export type Experience = {
  __typename?: 'Experience';
  experience: Scalars['Int'];
  id: Scalars['ID'];
  level: Scalars['Int'];
  nextLevelExperience: Scalars['Int'];
  prevLevelExperience: Scalars['Int'];
};

export type Faq = {
  __typename?: 'Faq';
  answer?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  question?: Maybe<Scalars['String']>;
};

export type FaqInput = {
  answer?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  order?: InputMaybe<Scalars['Int']>;
  question?: InputMaybe<Scalars['String']>;
};

export type Feedback = {
  __typename?: 'Feedback';
  cohortId: Scalars['ID'];
  courseId: Scalars['ID'];
  createdAt?: Maybe<Scalars['DateTime']>;
  entity: EntityName;
  entityId: Scalars['ID'];
  feedback?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  pageUrl?: Maybe<Scalars['String']>;
  ratings: Array<FeedbackRating>;
  user?: Maybe<User>;
};

export type FeedbackInput = {
  cohortId: Scalars['ID'];
  courseId: Scalars['ID'];
  entity: EntityName;
  entityId: Scalars['ID'];
  feedback?: InputMaybe<Scalars['String']>;
  pageUrl?: InputMaybe<Scalars['String']>;
  ratings: Array<FeedbackRatingInput>;
};

export type FeedbackRating = {
  __typename?: 'FeedbackRating';
  code: Scalars['String'];
  rating: Scalars['Int'];
};

export type FeedbackRatingInput = {
  code: Scalars['String'];
  rating: Scalars['Int'];
};

export enum FilterByLiveSession {
  Modules = 'MODULES',
  Programs = 'PROGRAMS'
}

export type Form = {
  __typename?: 'Form';
  allowResponseUpdate: Scalars['Boolean'];
  course?: Maybe<Course>;
  courseId: Scalars['ID'];
  id: Scalars['ID'];
  isGradeable: Scalars['Boolean'];
  name?: Maybe<Array<TranslatedField>>;
  questions: Array<Question>;
};

export type FormInstance = {
  __typename?: 'FormInstance';
  cohort: Cohort;
  end?: Maybe<Scalars['DateTime']>;
  entity: EntityName;
  entityId: Scalars['ID'];
  form: Form;
  id: Scalars['ID'];
  responseCount: Array<AnswerCount>;
  start?: Maybe<Scalars['DateTime']>;
  totalResponsesCount: Scalars['Int'];
  userResponse?: Maybe<FormResponse>;
};

export type FormResponse = {
  __typename?: 'FormResponse';
  formInstanceId: Scalars['ID'];
  id: Scalars['ID'];
  responses: Array<QuestionResponse>;
  userId: Scalars['ID'];
};

export type FormResultsDisplayData = {
  __typename?: 'FormResultsDisplayData';
  checkedAnswers: Array<Scalars['ID']>;
  questionsResultsDisplayData: QuestionsResultsDisplayData;
  responseGamification?: Maybe<Gamification>;
};

export type Gamification = {
  __typename?: 'Gamification';
  eventRecords?: Maybe<Array<Maybe<EventDataGamification>>>;
  range?: Maybe<Scalars['String']>;
  stars?: Maybe<Scalars['Int']>;
  userId?: Maybe<Scalars['ID']>;
};

export type GamificationNotification = {
  __typename?: 'GamificationNotification';
  notifyLevel?: Maybe<Scalars['String']>;
  notifyStars?: Maybe<Scalars['Int']>;
  rule?: Maybe<Scalars['String']>;
};

export type GamificationResponse = {
  __typename?: 'GamificationResponse';
  msg?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

export type GatewayResponse = PayUResponse | StripeResponse;

export type GenerateCalendarEventsHolidays = {
  end?: InputMaybe<Scalars['String']>;
  start: Scalars['String'];
};

export type GenerateCalendarEventsInput = {
  cohortId: Scalars['ID'];
  courseId: Scalars['ID'];
  duration: Scalars['Int'];
  holidays?: InputMaybe<Array<GenerateCalendarEventsHolidays>>;
  sessionIds: Array<Scalars['ID']>;
  startDay: Scalars['Int'];
  startHour: Scalars['Int'];
  startMinute: Scalars['Int'];
  startMonth: Scalars['Int'];
  startYear: Scalars['Int'];
  timeZone: Scalars['String'];
  weekdays: Array<Scalars['Int']>;
};

export type GetCitiesByCountryAndRegionIdInput = {
  countryId: Scalars['ID'];
  regionId: Scalars['ID'];
};

export type GetHotProductsInput = {
  endDate?: InputMaybe<Scalars['String']>;
  startDate: Scalars['String'];
};

export type GetProductRatingByUserAndProduct = {
  productId: Scalars['ID'];
  productType: Scalars['String'];
  userId: Scalars['ID'];
};

export type GetRegionsByCountryIdInput = {
  countryId: Scalars['ID'];
};

export type GoogleAnalyticsConfig = {
  __typename?: 'GoogleAnalyticsConfig';
  tagManagerId: Scalars['String'];
};

export type GoogleAnalyticsConfigInput = {
  tagManagerId: Scalars['String'];
};

export type Grade = {
  __typename?: 'Grade';
  cohortId?: Maybe<Scalars['ID']>;
  comments?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<User>;
  entity: EntityName;
  entityId: Scalars['ID'];
  grade: Scalars['Float'];
  gradesCount?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  userId: Scalars['ID'];
};

export type GroupSession = {
  __typename?: 'GroupSession';
  avatar?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['DateTime']>;
  isLinkEnabled?: Maybe<Scalars['Boolean']>;
  link?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  participants: Array<Maybe<Scalars['String']>>;
  title?: Maybe<Scalars['String']>;
  topics?: Maybe<Array<Maybe<Scalars['String']>>>;
  userId?: Maybe<Scalars['String']>;
};

export type GroupSessionInput = {
  date?: InputMaybe<Scalars['DateTime']>;
  link?: InputMaybe<Scalars['String']>;
  participants: Array<InputMaybe<Scalars['String']>>;
  title?: InputMaybe<Scalars['String']>;
  topics?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  userId?: InputMaybe<Scalars['String']>;
};

export type HighTicketMentorship = {
  __typename?: 'HighTicketMentorship';
  avatar?: Maybe<Scalars['String']>;
  brand: Array<Maybe<Category>>;
  brandIds: Array<Maybe<Scalars['ID']>>;
  category: Category;
  categoryId: Scalars['ID'];
  description: Scalars['String'];
  duration: Scalars['String'];
  endDate?: Maybe<Scalars['DateTime']>;
  extraBenefits?: Maybe<ExtraBenefitsHighTicketMentorship>;
  faq?: Maybe<Array<Maybe<Faq>>>;
  groupSessions: Array<Maybe<GroupSession>>;
  id: Scalars['ID'];
  isPublished: Scalars['Boolean'];
  landingUrl: Scalars['String'];
  level: Array<Maybe<Category>>;
  levelIds: Array<Maybe<Scalars['ID']>>;
  logo?: Maybe<Scalars['String']>;
  mentor?: Maybe<MentorHighTicketMentorship>;
  mentorsIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  oneOnOneBenefits: Array<Maybe<Scalars['String']>>;
  oneToOneSessions: Array<Maybe<OneToOneSession>>;
  order: Scalars['Int'];
  parentId?: Maybe<Scalars['ID']>;
  qrCodeLink: Scalars['String'];
  remainingDays?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  subCategory: Array<Maybe<Category>>;
  subCategoryIds: Array<Maybe<Scalars['ID']>>;
  thumbnail: Scalars['String'];
  title: Scalars['String'];
  type: Scalars['String'];
  whatsAppLink: Scalars['String'];
  workTeam?: Maybe<Array<Maybe<WorkTeamHighTicketMentorship>>>;
};

export type Holiday = {
  __typename?: 'Holiday';
  end: Scalars['DateTime'];
  name: Scalars['String'];
  start: Scalars['DateTime'];
};

export type HolidayInput = {
  end: Scalars['DateTime'];
  name: Scalars['String'];
  start: Scalars['DateTime'];
};

export type HotProduct = {
  __typename?: 'HotProduct';
  competitors: Array<HotProductCompetitor>;
  createdAt: Scalars['DateTime'];
  description: Scalars['String'];
  id: Scalars['ID'];
  imagesURLs: Array<Scalars['String']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  minQuantity: Scalars['Int'];
  month?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  nextId?: Maybe<Scalars['String']>;
  prevId?: Maybe<Scalars['String']>;
  price: Array<HotProductPrice>;
  recommendations: Array<Scalars['String']>;
  releaseDate: Scalars['DateTime'];
  reportsAvailable: Array<Scalars['String']>;
  reportsDownloadLink: Scalars['String'];
  score: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type HotProductCompetitor = {
  __typename?: 'HotProductCompetitor';
  bestSellerLink: Scalars['String'];
  publicPrice: Scalars['String'];
  totalSold: Scalars['String'];
  totalUnits: Scalars['Int'];
};

export type HotProductCompetitorInput = {
  bestSellerLink: Scalars['String'];
  publicPrice: Scalars['String'];
  totalSold: Scalars['String'];
  totalUnits: Scalars['Int'];
};

export type HotProductPrice = {
  __typename?: 'HotProductPrice';
  BECPA: Scalars['String'];
  BEROAS: Scalars['String'];
  CPA: Scalars['String'];
  approxShippingCost: Scalars['String'];
  cost: Scalars['String'];
  landedCost: Scalars['String'];
  priceCostRatio: Scalars['String'];
  profit: Scalars['String'];
  profitMargin: Scalars['String'];
  publicPrice: Scalars['String'];
};

export type HotProductPriceInput = {
  BECPA: Scalars['String'];
  BEROAS: Scalars['String'];
  CPA: Scalars['String'];
  approxShippingCost: Scalars['String'];
  cost: Scalars['String'];
  landedCost: Scalars['String'];
  priceCostRatio: Scalars['String'];
  profit: Scalars['String'];
  profitMargin: Scalars['String'];
  publicPrice: Scalars['String'];
};

export type InteractiveContent = {
  __typename?: 'InteractiveContent';
  /** @deprecated Will be removed in future versions */
  coinsToAward?: Maybe<Scalars['Int']>;
  contentType: InteractiveContentType;
  courseId: Scalars['ID'];
  description?: Maybe<Array<TranslatedField>>;
  formId?: Maybe<Scalars['ID']>;
  grade?: Maybe<Grade>;
  id: Scalars['ID'];
  id_mysql?: Maybe<Scalars['String']>;
  module?: Maybe<Module>;
  moduleId: Scalars['ID'];
  rank: Scalars['String'];
  session?: Maybe<Session>;
  sessionId: Scalars['ID'];
  subTitle?: Maybe<Array<TranslatedField>>;
  title: Array<TranslatedField>;
  userDeliverable?: Maybe<Deliverable>;
  userProgress?: Maybe<Progress>;
  video?: Maybe<Array<TranslatedField>>;
  /** @deprecated Use 'video' instead */
  videoLink?: Maybe<Scalars['String']>;
};


export type InteractiveContentGradeArgs = {
  cohortId: Scalars['ID'];
};


export type InteractiveContentUserDeliverableArgs = {
  cohortId: Scalars['ID'];
};


export type InteractiveContentUserProgressArgs = {
  cohortId: Scalars['ID'];
};

export type InteractiveContentBannerContinueProgress = {
  __typename?: 'InteractiveContentBannerContinueProgress';
  sessionId?: Maybe<Scalars['ID']>;
  title?: Maybe<Scalars['String']>;
};

export enum InteractiveContentType {
  Deliverable = 'DELIVERABLE',
  HumanSkill = 'HUMAN_SKILL',
  Lab = 'LAB',
  Preparation = 'PREPARATION',
  Quiz = 'QUIZ'
}

export type Invite = {
  __typename?: 'Invite';
  acceptedAt?: Maybe<Scalars['DateTime']>;
  cohortId?: Maybe<Scalars['ID']>;
  createdAt: Scalars['DateTime'];
  email: Scalars['String'];
  enrolledAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  paymentMethodType: PaymentMethod;
  updates: Array<InviteStatusUpdate>;
  user?: Maybe<PublicUser>;
};

export type InviteInput = {
  email: Scalars['String'];
  paymentMethodType: PaymentMethod;
  template?: InputMaybe<Scalars['String']>;
};

export enum InviteStatus {
  Accepted = 'ACCEPTED',
  DocumentsAlreadySigned = 'DOCUMENTS_ALREADY_SIGNED',
  DocumentsIgnored = 'DOCUMENTS_IGNORED',
  DocumentsRejected = 'DOCUMENTS_REJECTED',
  DocumentsReminderSent = 'DOCUMENTS_REMINDER_SENT',
  DocumentsSent = 'DOCUMENTS_SENT',
  DocumentsSigned = 'DOCUMENTS_SIGNED',
  Enrolled = 'ENROLLED',
  ErrorSendingDocuments = 'ERROR_SENDING_DOCUMENTS',
  Sent = 'SENT'
}

export type InviteStatusUpdate = {
  __typename?: 'InviteStatusUpdate';
  createdAt?: Maybe<Scalars['DateTime']>;
  metadata?: Maybe<Scalars['JSON']>;
  status: InviteStatus;
};

export type Language = {
  __typename?: 'Language';
  language: Scalars['String'];
  level: Scalars['String'];
};

export type LanguageInput = {
  language: Scalars['String'];
  level: Scalars['String'];
};

export type LearningGoal = {
  __typename?: 'LearningGoal';
  /** @deprecated use goal instead */
  description?: Maybe<Scalars['String']>;
  goal: Array<TranslatedField>;
};

export type LearningGoalInput = {
  description?: InputMaybe<Scalars['String']>;
  goal?: InputMaybe<Array<TranslatedFieldInput>>;
};

export type LiveSession = {
  __typename?: 'LiveSession';
  attendees?: Maybe<Array<LiveSessionsAttendees>>;
  brand?: Maybe<Array<Maybe<Category>>>;
  brandId?: Maybe<Array<Maybe<Scalars['ID']>>>;
  channelName: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  emailSent?: Maybe<Scalars['Boolean']>;
  endDate: Scalars['DateTime'];
  externalLink?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  imageUrl?: Maybe<Scalars['String']>;
  imageUrlMentor?: Maybe<Scalars['String']>;
  isCancelled?: Maybe<Scalars['Boolean']>;
  isExternalLinkActive: Scalars['Boolean'];
  isWorkshop?: Maybe<Scalars['Boolean']>;
  level?: Maybe<Array<Maybe<Category>>>;
  levelId?: Maybe<Array<Maybe<Scalars['ID']>>>;
  liveSessionType: LiveSessionType;
  mainMentors?: Maybe<Array<Maybe<Mentor>>>;
  mentorId?: Maybe<Scalars['ID']>;
  mentorName?: Maybe<Scalars['String']>;
  mentors?: Maybe<Array<Maybe<Mentor>>>;
  mentorsIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
  moduleId?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  notified?: Maybe<Scalars['Boolean']>;
  programId?: Maybe<Scalars['ID']>;
  recurrence?: Maybe<LiveSessionRecurrenceType>;
  secondaryMentors?: Maybe<Array<Maybe<Mentor>>>;
  sessionTypeName?: Maybe<Scalars['String']>;
  startDate: Scalars['DateTime'];
  status?: Maybe<LiveSessionStatus>;
  subCategory?: Maybe<Array<Maybe<Category>>>;
  subCategoryId?: Maybe<Array<Maybe<Scalars['ID']>>>;
  type?: Maybe<Category>;
  typeId: Scalars['ID'];
  userId: Scalars['ID'];
};

export type LiveSessionAuth = {
  __typename?: 'LiveSessionAuth';
  resourceId: Scalars['String'];
  responseGamification?: Maybe<Gamification>;
  rtcToken: Scalars['String'];
  rtmToken: Scalars['String'];
  session: LiveSession;
  uid: Scalars['String'];
};

export type LiveSessionAuthJwtZoom = {
  __typename?: 'LiveSessionAuthJwtZoom';
  jwt: Scalars['String'];
  responseGamification?: Maybe<Gamification>;
  session: LiveSession;
  uid: Scalars['String'];
};

export type LiveSessionInput = {
  brandId: Array<Scalars['ID']>;
  description?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  externalLink?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  isExternalLinkActive?: InputMaybe<Scalars['Boolean']>;
  levelId: Array<Scalars['ID']>;
  mainMentors?: InputMaybe<Array<Scalars['ID']>>;
  moduleId?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  programId?: InputMaybe<Scalars['ID']>;
  secondaryMentors?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  subCategoryId: Array<Scalars['ID']>;
  typeId: Scalars['ID'];
};

export type LiveSessionP1 = {
  __typename?: 'LiveSessionP1';
  attendees?: Maybe<Array<LiveSessionsAttendees>>;
  channelName: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  endDate: Scalars['DateTime'];
  id: Scalars['ID'];
  imageUrl?: Maybe<Scalars['String']>;
  isCancelled?: Maybe<Scalars['Boolean']>;
  isWorkshop?: Maybe<Scalars['Boolean']>;
  mainMentors?: Maybe<Array<Maybe<Mentor>>>;
  name: Scalars['String'];
  programId?: Maybe<Scalars['ID']>;
  secondaryMentors?: Maybe<Array<Maybe<Mentor>>>;
  startDate: Scalars['DateTime'];
  status?: Maybe<LiveSessionStatus>;
};

export type LiveSessionRecording = {
  __typename?: 'LiveSessionRecording';
  channelName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isRecordingSent: Scalars['Boolean'];
  link: Scalars['String'];
  notifiedAt?: Maybe<Scalars['DateTime']>;
  recordedAt?: Maybe<Scalars['DateTime']>;
};

export type LiveSessionRecurrence = {
  daysOfWeek: Array<InputMaybe<Scalars['Int']>>;
  interval: Scalars['Int'];
  type?: InputMaybe<LiveSessionRecurrenceTypes>;
};

export type LiveSessionRecurrenceType = {
  __typename?: 'LiveSessionRecurrenceType';
  daysOfWeek: Array<Maybe<Scalars['Int']>>;
  interval?: Maybe<Scalars['Int']>;
  type?: Maybe<LiveSessionRecurrenceTypes>;
};

export enum LiveSessionRecurrenceTypes {
  Monthly = 'MONTHLY',
  Unique = 'UNIQUE',
  Weekly = 'WEEKLY',
  Yearly = 'YEARLY'
}

export type LiveSessionResponse = {
  __typename?: 'LiveSessionResponse';
  msg?: Maybe<Scalars['String']>;
  status?: Maybe<LiveSessionStatus>;
};

export enum LiveSessionStatus {
  Ended = 'ENDED',
  Live = 'LIVE',
  Scheduled = 'SCHEDULED'
}

export type LiveSessionStatusApi = {
  __typename?: 'LiveSessionStatusApi';
  status?: Maybe<Scalars['String']>;
};

export type LiveSessionTags = {
  __typename?: 'LiveSessionTags';
  moduleTags?: Maybe<Array<Maybe<Scalars['String']>>>;
  programTags?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type LiveSessionWeekTypes = {
  __typename?: 'LiveSessionWeekTypes';
  name?: Maybe<Scalars['String']>;
  sessions?: Maybe<Array<Maybe<LiveSession>>>;
};

export type LiveSessionWeekTypesP1 = {
  __typename?: 'LiveSessionWeekTypesP1';
  name?: Maybe<Scalars['String']>;
  sessions?: Maybe<Array<Maybe<LiveSessionP1>>>;
};

export type LiveSessionsAttendees = {
  __typename?: 'LiveSessionsAttendees';
  attendance?: Maybe<Scalars['Boolean']>;
  joinedAt?: Maybe<Scalars['DateTime']>;
  leftAt?: Maybe<Scalars['DateTime']>;
  registeredAt?: Maybe<Scalars['DateTime']>;
  timezone: Scalars['String'];
  uid: Scalars['String'];
  userId: Scalars['String'];
  version_portal?: Maybe<Scalars['String']>;
};

export type LiveSessionsForCarrousel = {
  __typename?: 'LiveSessionsForCarrousel';
  liveSessions?: Maybe<Array<Maybe<LiveSession>>>;
  scheduledSessions?: Maybe<Array<Maybe<LiveSession>>>;
};

export type LiveSessionsPaginated = {
  __typename?: 'LiveSessionsPaginated';
  liveSessions?: Maybe<Array<Maybe<LiveSession>>>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type LiveSessionsSearchInput = {
  dateInit?: InputMaybe<Scalars['DateTime']>;
  filterBy?: InputMaybe<FilterByLiveSession>;
  modulesId?: InputMaybe<Array<Scalars['ID']>>;
  programsId?: InputMaybe<Array<Scalars['ID']>>;
  timezone: Scalars['String'];
};

export type LivesessionsOutPut = {
  __typename?: 'LivesessionsOutPut';
  liveSessions?: Maybe<Array<Maybe<LiveSession>>>;
  totalPages?: Maybe<Scalars['Int']>;
};

export type MailgunConfig = {
  __typename?: 'MailgunConfig';
  apiKey: Scalars['String'];
  domain: Scalars['String'];
};

export type MailgunConfigInput = {
  apiKey: Scalars['String'];
  domain: Scalars['String'];
};

export type MailingConfig = {
  __typename?: 'MailingConfig';
  emailHeaderUrl: Scalars['String'];
};

export type MailingConfigInput = {
  emailHeaderUrl: Scalars['String'];
};

export type MarketplaceService = {
  __typename?: 'MarketplaceService';
  agency?: Maybe<Scalars['String']>;
  agencyImage?: Maybe<Scalars['String']>;
  category?: Maybe<Category>;
  categoryId: Scalars['ID'];
  contactLink?: Maybe<Scalars['String']>;
  createdBy: Scalars['ID'];
  description?: Maybe<Scalars['String']>;
  draft?: Maybe<Scalars['Boolean']>;
  entity?: Maybe<EntityName>;
  howItWorks?: Maybe<Array<Maybe<Scalars['String']>>>;
  id: Scalars['ID'];
  imageGallery?: Maybe<Array<Maybe<Scalars['String']>>>;
  lastUpdatedBy: Scalars['ID'];
  published?: Maybe<Scalars['Boolean']>;
  recipientsEmails?: Maybe<Array<Maybe<Scalars['String']>>>;
  responseTime?: Maybe<Scalars['String']>;
  services?: Maybe<Array<Maybe<Scalars['String']>>>;
  subcategory?: Maybe<Array<Maybe<Category>>>;
  subcategoryId?: Maybe<Array<Maybe<Scalars['ID']>>>;
  subtitle?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  whyUs?: Maybe<Scalars['String']>;
};

export type MassEmailForMigrationSummary = {
  __typename?: 'MassEmailForMigrationSummary';
  date: Scalars['DateTime'];
  email: Scalars['String'];
  emailStatus: Scalars['String'];
  tokenStatus: Scalars['String'];
  userId: Scalars['ID'];
};

export type MasterClass = {
  __typename?: 'MasterClass';
  ItIsScheduled?: Maybe<Scalars['Boolean']>;
  brand?: Maybe<Category>;
  channelName: Scalars['String'];
  description: Scalars['String'];
  endDate: Scalars['DateTime'];
  id: Scalars['ID'];
  imageUrlMentor?: Maybe<Scalars['String']>;
  isCancelled?: Maybe<Scalars['Boolean']>;
  isWorkshop?: Maybe<Scalars['Boolean']>;
  level?: Maybe<Category>;
  liveSessionType?: Maybe<LiveSessionType>;
  mentorId: Scalars['ID'];
  mentorInfo?: Maybe<MentorInfo>;
  name: Scalars['String'];
  startDate: Scalars['DateTime'];
  subCategory?: Maybe<Category>;
  userId: Scalars['ID'];
};

export type MediaUploadInput = {
  contentType: Scalars['String'];
  entity: EntityName;
  entityId: Scalars['ID'];
  filename: Scalars['String'];
  mediaContext?: InputMaybe<Scalars['String']>;
};

export type MediaUploadResponse = {
  __typename?: 'MediaUploadResponse';
  mediaURL: Scalars['String'];
  mediaUploadURL: Scalars['String'];
};

export type Mentor = {
  __typename?: 'Mentor';
  avatarUrl?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  id: Scalars['ID'];
  lastName: Scalars['String'];
  name: Scalars['String'];
  position?: Maybe<Scalars['String']>;
};

export type MentorForFilter = {
  __typename?: 'MentorForFilter';
  avatarUrl?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lastName?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

export type MentorInfo = {
  __typename?: 'MentorInfo';
  avatarUrl: Scalars['String'];
  email: Scalars['String'];
  id: Scalars['ID'];
  lastName: Scalars['String'];
  name: Scalars['String'];
};

export type Metric = {
  __typename?: 'Metric';
  data: Array<MetricSeries>;
  name: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type MetricSeries = {
  __typename?: 'MetricSeries';
  group?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  series: Array<MetricValue>;
};

export type MetricValue = {
  __typename?: 'MetricValue';
  key: Scalars['String'];
  value: Scalars['String'];
  valueType?: Maybe<Scalars['String']>;
};

export type MetricsSearchInput = {
  cohortIds?: InputMaybe<Array<Scalars['ID']>>;
  courseIds?: InputMaybe<Array<Scalars['ID']>>;
  end?: InputMaybe<Scalars['DateTime']>;
  formInstanceId?: InputMaybe<Scalars['ID']>;
  metricName: Scalars['String'];
  referrerCode?: InputMaybe<Scalars['String']>;
  start?: InputMaybe<Scalars['DateTime']>;
};

export type Module = {
  __typename?: 'Module';
  badges?: Maybe<Array<Badge>>;
  /** @deprecated No longer supported, will be removed in future versions */
  code?: Maybe<Scalars['String']>;
  course?: Maybe<Course>;
  courseId: Scalars['ID'];
  description?: Maybe<Array<TranslatedField>>;
  id: Scalars['ID'];
  id_mysql?: Maybe<Scalars['String']>;
  name?: Maybe<Array<TranslatedField>>;
  prerequisites?: Maybe<Array<Module>>;
  rank: Scalars['String'];
  sessionCount: Scalars['Int'];
  sessions: Array<Session>;
  userDeliverables: Array<Deliverable>;
  userPrerequisitesProgress: Scalars['Int'];
  userProgress?: Maybe<Progress>;
};


export type ModuleUserDeliverablesArgs = {
  cohortId: Scalars['ID'];
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type ModuleUserPrerequisitesProgressArgs = {
  cohortId: Scalars['ID'];
  excludedActivityTypes?: InputMaybe<Array<ActivityType>>;
};


export type ModuleUserProgressArgs = {
  cohortId: Scalars['ID'];
};

export type Month = {
  __typename?: 'Month';
  startDate: Scalars['String'];
  weeks: Array<Week>;
};

export type MultipleDisenrollmentEntry = {
  __typename?: 'MultipleDisenrollmentEntry';
  cohortId: Scalars['String'];
  error?: Maybe<Scalars['String']>;
  status: Scalars['String'];
  userId: Scalars['String'];
};

export type MultipleEnrollmentEntry = {
  __typename?: 'MultipleEnrollmentEntry';
  attempts?: Maybe<Scalars['Int']>;
  cohortId?: Maybe<Scalars['String']>;
  enrollmentId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Maybe<Scalars['String']>>>;
  salesforceNotificationStatus?: Maybe<Scalars['String']>;
  statusList?: Maybe<Array<Maybe<EnrollmentStatus>>>;
};

export type MultipleEnrollmentSummary = {
  __typename?: 'MultipleEnrollmentSummary';
  enrollmentsSummary: Array<Maybe<MultipleEnrollmentEntry>>;
  orderSummary?: Maybe<SalesforceOrderSummary>;
};

export type Mutation = {
  __typename?: 'Mutation';
  activePost?: Maybe<Post>;
  addCalendarEventResponsible: CalendarEvent;
  addOrganizationOwner: Organization;
  addOrganizationUser: Organization;
  addUserBadge: Badge;
  assignContentDeliverables: Array<Deliverable>;
  awardCoins: Array<Coins>;
  banUser?: Maybe<Scalars['Boolean']>;
  blockUser: Scalars['ID'];
  blockUserFromMultipleCohorts: MultipleEnrollmentSummary;
  cancelLiveSession: Scalars['String'];
  cancelSchedullingLiveSession: Scalars['String'];
  copyForm: Form;
  copyModule?: Maybe<Module>;
  copySession?: Maybe<Session>;
  createAttendance: AttendanceResponse;
  createBadge: Badge;
  createBadge2: Badge2;
  createBootcamp?: Maybe<Bootcamp>;
  createBootcampCohort?: Maybe<Cohort>;
  createBootcampRelease?: Maybe<BootcampRelease>;
  createCalendarEvent: CalendarEvent;
  createCategory: Category;
  createCertificate: Certificate;
  createChangeHistory: ChangeHistory;
  createChinaAgentForm: ChinaAgentForm;
  createCohort: Cohort;
  createCommunityEvent: CommunityEvent;
  createConsultants: Consultant;
  createCourse: Course;
  createDeliverable: Deliverable;
  createDiscordChannel?: Maybe<Scalars['String']>;
  createFeedback: Scalars['ID'];
  createForm: Form;
  createFormInstance: FormInstance;
  createFormResponse: FormResponse;
  createGrade: Grade;
  createHighTicketMentorship?: Maybe<HighTicketMentorship>;
  createHotProduct: HotProduct;
  createInteractiveContent?: Maybe<InteractiveContent>;
  createLiveSession: LiveSession;
  createLiveSessionScheduling: LiveSession;
  createLiveSessionSchedulingPortalV1: LiveSession;
  createLiveSessions: Scalars['String'];
  createMarketplaceService: MarketplaceService;
  createMasterClass: MasterClass;
  createMasterClassSchedulling: Scalars['String'];
  createMediaUploadURL: MediaUploadResponse;
  createModule: Module;
  createNewNotification?: Maybe<NewNotification>;
  createNotification: Notification;
  createOrUpdateComment?: Maybe<Comment>;
  createOrUpdateGamification?: Maybe<Gamification>;
  createOrUpdatePost?: Maybe<Post>;
  createOrder: OrderResponse;
  createOrganization: Organization;
  createPlatformFeedback: Scalars['ID'];
  createProfilingUser: Profiling;
  createProgram: Program;
  createRocketfyLog: RocketfyLog;
  createSecondCheckNotification: Notification;
  createSession: Session;
  createSolution: Solution;
  createTeam: Team;
  createTokenAgora: AgoraToken;
  createUser: User;
  createUserBadge2: UserBadge2;
  createUserBadge2WithTrigger: Badge2;
  createWorkReport: WorkReport;
  deleteBadge: Scalars['ID'];
  deleteBadge2: Scalars['ID'];
  deleteCalendarEvent: Scalars['ID'];
  deleteCategory: Category;
  deleteComment?: Maybe<Comment>;
  deleteCommunityEvent: Scalars['ID'];
  deleteConsultants: Consultant;
  deleteForm: Scalars['ID'];
  deleteFormInstanceById: Scalars['ID'];
  deleteHotProduct: HotProduct;
  deleteInteractiveContent: Scalars['ID'];
  deleteLiveSession: Scalars['ID'];
  deleteMasterClass: Scalars['ID'];
  deleteModule: Scalars['ID'];
  deleteNotification: Scalars['ID'];
  deletePost?: Maybe<Scalars['Boolean']>;
  deleteSession: Scalars['ID'];
  deleteSolution: Solution;
  deleteUser: Scalars['ID'];
  deleteUserBadge2: Badge2;
  disenrollUser: Scalars['ID'];
  disenrollUserAsProMember: ProEnrollmentSummary;
  endLiveSession: Scalars['String'];
  endLiveSessionZoom?: Maybe<LiveSessionStatusApi>;
  enrollMe: Scalars['ID'];
  enrollUser: Scalars['ID'];
  enrollUserAsProMember: ProEnrollmentSummary;
  enrollUserInMultipleCohorts?: Maybe<MultipleEnrollmentSummary>;
  executeProgressRecalculationForAllUsers: ProgressRecalculationSummary;
  generateCalendarEvents: Array<CalendarEvent>;
  leaveTeam: Scalars['ID'];
  markMpServiceAsDraft: MarketplaceService;
  markNotificationsAsRead: Scalars['Int'];
  muteUser?: Maybe<Scalars['Boolean']>;
  notifyUsers: Array<Maybe<Notification>>;
  processLiveSessionRecordings: Scalars['String'];
  processNotifications: Scalars['String'];
  processNotificationsLiveSession: Scalars['String'];
  reconcileOrdersFromSalesforce: Array<Maybe<OrdersReconcileItem>>;
  reconcileUserList: Array<Maybe<UsersReconcileItem>>;
  registerAttendance: Scalars['String'];
  removeCalendarEventResponsible: CalendarEvent;
  removeOrganizationOwner: Organization;
  removeOrganizationUser: Organization;
  removeUserBadge: Badge;
  saveMpServiceAndPublish: MarketplaceService;
  saveMpServiceAsDraft: MarketplaceService;
  sendCohortInvites: Array<Maybe<Scalars['ID']>>;
  sendInvites: Array<Maybe<Scalars['ID']>>;
  sendMassEmailForMigratedUsers: Array<Maybe<MassEmailForMigrationSummary>>;
  sendNotificationToAdmins: Scalars['Boolean'];
  sendPendingNotifications: Array<Scalars['ID']>;
  sendPremiumRequestToSalesfoce?: Maybe<RequestPremiumResponse>;
  sendProfilingAndPersonalDataToSalesforce: SendProfilingAndPersonalData;
  sendRocketfyToSalesforce: Scalars['Boolean'];
  setProgress: Progress;
  startLiveSession: Scalars['String'];
  startRecordingAgora: RecordingAgora;
  stopRecordingAgora: RecordingAgora;
  storeUserLeftEvent: StoreUserLeftEventResponse;
  subscribeUserToSpecificTier: ProEnrollmentSummary;
  swapInteractiveContentRank: Array<InteractiveContent>;
  swapModuleRank: Array<Module>;
  swapSessionRank: Array<Session>;
  syncABdata?: Maybe<Scalars['Boolean']>;
  syncOrderFromSalesforce: SalesforceOrderSummary;
  syncProgramEnrollments: Cohort;
  syncUserDataFromSalesforce: UserSyncSummary;
  toggleCancelLiveSession: LiveSession;
  unblockUser: Scalars['ID'];
  unblockUserFromMultipleCohorts: MultipleEnrollmentSummary;
  unmuteUser?: Maybe<Scalars['Boolean']>;
  unsubscribeToSalesforce: Scalars['String'];
  unsubscribeUserFromSpecificTier: ProEnrollmentSummary;
  updateAttendance: Attendance;
  updateBadge: Badge;
  updateBadge2: Badge2;
  updateBootcamp: Bootcamp;
  updateBootcampRelease: BootcampRelease;
  updateCalendarEvent: CalendarEvent;
  updateCategory: Category;
  updateCohort: Cohort;
  updateCommunityEvent: CommunityEvent;
  updateCommunityUser: Scalars['ID'];
  updateConsultants: Consultant;
  updateCourse: Course;
  updateExternalLink: LiveSession;
  updateForm: Form;
  updateFormInstance: FormInstance;
  updateHighTicketMentorship?: Maybe<HighTicketMentorship>;
  updateHotProduct: HotProduct;
  updateInteractiveContent?: Maybe<InteractiveContent>;
  updateLiveSession: LiveSession;
  updateMasterClass: Scalars['String'];
  updateModule: Module;
  updateMpService: MarketplaceService;
  updateMyProfile: UserProfile;
  updateNotificationStatus?: Maybe<NewNotification>;
  updateOrganization: Organization;
  updateProgram: Program;
  updateSchool: School;
  updateSession: Session;
  updateSolution: Solution;
  updateTeam: Team;
  updateUnboxingUser: Scalars['ID'];
  updateUser: User;
  updateUserSettings: UserSettings;
  upsertProductRating: ProductRating;
};


export type MutationActivePostArgs = {
  postId: Scalars['String'];
};


export type MutationAddCalendarEventResponsibleArgs = {
  calendarEventId: Scalars['ID'];
  role: ResponsibleRole;
  userId: Scalars['ID'];
};


export type MutationAddOrganizationOwnerArgs = {
  organizationId: Scalars['ID'];
  userId: Scalars['ID'];
};


export type MutationAddOrganizationUserArgs = {
  organizationId: Scalars['ID'];
  userId: Scalars['ID'];
};


export type MutationAddUserBadgeArgs = {
  addUserBadgeInput: AddUserBadgeInput;
};


export type MutationAssignContentDeliverablesArgs = {
  assignDeliverablesInput: AssignDeliverablesInput;
};


export type MutationAwardCoinsArgs = {
  awardCoinsInput: AwardCoinsInput;
};


export type MutationBanUserArgs = {
  userId: Scalars['String'];
};


export type MutationBlockUserArgs = {
  blockUserInput: DisenrollUserInput;
};


export type MutationBlockUserFromMultipleCohortsArgs = {
  enrollUserMultipleInput: EnrollUserMultipleInput;
};


export type MutationCancelLiveSessionArgs = {
  liveSessionsId: Scalars['String'];
};


export type MutationCancelSchedullingLiveSessionArgs = {
  liveSessionsId: Scalars['String'];
};


export type MutationCopyFormArgs = {
  courseId: Scalars['ID'];
  formId: Scalars['ID'];
};


export type MutationCopyModuleArgs = {
  courseId: Scalars['ID'];
  moduleId: Scalars['ID'];
};


export type MutationCopySessionArgs = {
  moduleId: Scalars['ID'];
  sessionId: Scalars['ID'];
};


export type MutationCreateAttendanceArgs = {
  calendarEventId: Scalars['ID'];
};


export type MutationCreateBadgeArgs = {
  createBadgeInput: CreateBadgeInput;
};


export type MutationCreateBadge2Args = {
  createBadgeInput: CreateBadge2Input;
};


export type MutationCreateBootcampArgs = {
  createBootcampInput: CreateBootcampInput;
};


export type MutationCreateBootcampCohortArgs = {
  createBootcampCohortInput: CreateCohortInput;
};


export type MutationCreateBootcampReleaseArgs = {
  createReleaseInput: CreateBootcampReleaseInput;
};


export type MutationCreateCalendarEventArgs = {
  createCalendarEventInput: CreateCalendarEventInput;
};


export type MutationCreateCategoryArgs = {
  categoryInput: CategoryInput;
};


export type MutationCreateCertificateArgs = {
  createCertificateInput: CreateCertificateInput;
};


export type MutationCreateChangeHistoryArgs = {
  createChangeHistoryInput: CreateChangeHistoryInput;
};


export type MutationCreateChinaAgentFormArgs = {
  createChinaAgentFormInput: CreateChinaAgentFormInput;
};


export type MutationCreateCohortArgs = {
  createCohortInput: CreateCohortInput;
};


export type MutationCreateCommunityEventArgs = {
  CommunityEventInput: CommunityEventInput;
};


export type MutationCreateConsultantsArgs = {
  createConsultantInput: CreateConsultantInput;
};


export type MutationCreateCourseArgs = {
  createCourseInput: CreateCourseInput;
};


export type MutationCreateDeliverableArgs = {
  createDeliverableInput: CreateDeliverableInput;
};


export type MutationCreateDiscordChannelArgs = {
  createDiscordChannelInput: CreateDiscordChannelInput;
};


export type MutationCreateFeedbackArgs = {
  feedbackInput: FeedbackInput;
};


export type MutationCreateFormArgs = {
  createFormInput: CreateFormInput;
};


export type MutationCreateFormInstanceArgs = {
  createFormInstanceInput: CreateFormInstanceInput;
};


export type MutationCreateFormResponseArgs = {
  createFormResponseInput: CreateFormResponseInput;
};


export type MutationCreateGradeArgs = {
  createGradeInput: CreateGradeInput;
};


export type MutationCreateHighTicketMentorshipArgs = {
  createHighTicketMentorshipInput: CreateHighTicketMentorshipInput;
};


export type MutationCreateHotProductArgs = {
  createHotProductInput: CreateHotProductInput;
};


export type MutationCreateInteractiveContentArgs = {
  createInteractiveContentInput: CreateInteractiveContentInput;
};


export type MutationCreateLiveSessionArgs = {
  input: LiveSessionInput;
};


export type MutationCreateLiveSessionSchedulingArgs = {
  createLiveSessionsSchedulingInput: CreateLiveSessionsSchedulingInput;
};


export type MutationCreateLiveSessionSchedulingPortalV1Args = {
  liveSessionsId: Scalars['String'];
  timezone: Scalars['String'];
  userId: Scalars['String'];
};


export type MutationCreateLiveSessionsArgs = {
  createLiveSessionsInput: CreateLiveSessionsInput;
};


export type MutationCreateMarketplaceServiceArgs = {
  createMarketplaceServiceInput: CreateMarketplaceServiceInput;
};


export type MutationCreateMasterClassArgs = {
  createMasterClassInput: CreateMasterClassInput;
};


export type MutationCreateMasterClassSchedullingArgs = {
  liveSessionsId: Scalars['String'];
  timezone: Scalars['String'];
};


export type MutationCreateMediaUploadUrlArgs = {
  mediaUploadInput: MediaUploadInput;
};


export type MutationCreateModuleArgs = {
  createModuleInput: CreateModuleInput;
};


export type MutationCreateNewNotificationArgs = {
  createNewNotificationInput: CreateNewNotificationInput;
};


export type MutationCreateNotificationArgs = {
  createNotificationInput: CreateNotificationInput;
};


export type MutationCreateOrUpdateCommentArgs = {
  commentInput: CommentInput;
};


export type MutationCreateOrUpdateGamificationArgs = {
  createOrUpdateGamificationInput: CreateOrUpdateGamificationInput;
};


export type MutationCreateOrUpdatePostArgs = {
  createOrUpdatePostInput: CreateOrUpdatePostInput;
};


export type MutationCreateOrderArgs = {
  createOrderInput: CreateOrderInput;
};


export type MutationCreateOrganizationArgs = {
  createOrganizationInput: CreateOrganizationInput;
};


export type MutationCreatePlatformFeedbackArgs = {
  feedbackInput: PlatformFeedbackInput;
};


export type MutationCreateProfilingUserArgs = {
  createProfilingUserInput: CreateProfilingUserInput;
};


export type MutationCreateProgramArgs = {
  createProgramInput: CreateProgramInput;
};


export type MutationCreateRocketfyLogArgs = {
  rocketfyLogInput: RocketfyLogInput;
};


export type MutationCreateSecondCheckNotificationArgs = {
  createSecondCheckNotificationInput: CreateSecondCheckNotificationInput;
};


export type MutationCreateSessionArgs = {
  createSessionInput: CreateSessionInput;
};


export type MutationCreateSolutionArgs = {
  createSolutionInput: CreateSolutionInput;
};


export type MutationCreateTeamArgs = {
  createTeamInput: CreateTeamInput;
};


export type MutationCreateTokenAgoraArgs = {
  createTokenAgoraInput: CreateTokenAgoraInput;
};


export type MutationCreateUserArgs = {
  createUserInput: CreateUserInput;
};


export type MutationCreateUserBadge2Args = {
  createUserBadgeInput: CreateUserBadge2Input;
};


export type MutationCreateUserBadge2WithTriggerArgs = {
  createUserBadgeWithTriggerInput: CreateUserBadge2WithTriggerInput;
};


export type MutationCreateWorkReportArgs = {
  createWorkReportInput: CreateWorkReportInput;
};


export type MutationDeleteBadgeArgs = {
  badgeId: Scalars['ID'];
};


export type MutationDeleteBadge2Args = {
  badgeId: Scalars['ID'];
};


export type MutationDeleteCalendarEventArgs = {
  calendarEventId: Scalars['ID'];
};


export type MutationDeleteCategoryArgs = {
  categoryId: Scalars['ID'];
};


export type MutationDeleteCommentArgs = {
  commentId: Scalars['String'];
};


export type MutationDeleteCommunityEventArgs = {
  communityEventId: Scalars['ID'];
};


export type MutationDeleteConsultantsArgs = {
  consultantID: Scalars['ID'];
};


export type MutationDeleteFormArgs = {
  formId: Scalars['ID'];
};


export type MutationDeleteFormInstanceByIdArgs = {
  formInstanceId: Scalars['ID'];
};


export type MutationDeleteHotProductArgs = {
  hotProductId: Scalars['ID'];
};


export type MutationDeleteInteractiveContentArgs = {
  interactiveContentId: Scalars['ID'];
};


export type MutationDeleteLiveSessionArgs = {
  liveSessionId: Scalars['ID'];
};


export type MutationDeleteMasterClassArgs = {
  masterClassId: Scalars['ID'];
};


export type MutationDeleteModuleArgs = {
  moduleId: Scalars['ID'];
};


export type MutationDeleteNotificationArgs = {
  recipientId: Scalars['ID'];
  recipientType: EntityName;
};


export type MutationDeletePostArgs = {
  postId: Scalars['String'];
};


export type MutationDeleteSessionArgs = {
  sessionId: Scalars['ID'];
};


export type MutationDeleteSolutionArgs = {
  solutionId: Scalars['ID'];
};


export type MutationDeleteUserArgs = {
  userId: Scalars['ID'];
};


export type MutationDeleteUserBadge2Args = {
  deleteUserBadgeInput: DeleteUserBadge2Input;
};


export type MutationDisenrollUserArgs = {
  disenrollUserInput: DisenrollUserInput;
};


export type MutationDisenrollUserAsProMemberArgs = {
  proEnrollmentInput: ProEnrollmentInput;
};


export type MutationEndLiveSessionArgs = {
  liveSessionsId: Scalars['String'];
};


export type MutationEndLiveSessionZoomArgs = {
  sessionId: Scalars['ID'];
};


export type MutationEnrollMeArgs = {
  cohortId: Scalars['ID'];
  referrerCode?: InputMaybe<Scalars['String']>;
  token?: InputMaybe<Scalars['String']>;
};


export type MutationEnrollUserArgs = {
  enrollUserInput: EnrollUserInput;
};


export type MutationEnrollUserAsProMemberArgs = {
  proEnrollmentInput: ProEnrollmentInput;
};


export type MutationEnrollUserInMultipleCohortsArgs = {
  enrollUserMultipleInput: EnrollUserMultipleInput;
};


export type MutationExecuteProgressRecalculationForAllUsersArgs = {
  progressRecalculationInput: ProgressRecalculationInput;
};


export type MutationGenerateCalendarEventsArgs = {
  generateCalendarEventsInput: GenerateCalendarEventsInput;
};


export type MutationLeaveTeamArgs = {
  teamId: Scalars['ID'];
};


export type MutationMarkMpServiceAsDraftArgs = {
  marketplaceServiceId: Scalars['String'];
};


export type MutationMarkNotificationsAsReadArgs = {
  notificationIds: Array<Scalars['ID']>;
};


export type MutationMuteUserArgs = {
  postId: Scalars['String'];
  userId: Scalars['String'];
};


export type MutationNotifyUsersArgs = {
  createNotification: CreateNotificationInput;
  userIds: Array<Scalars['ID']>;
};


export type MutationReconcileOrdersFromSalesforceArgs = {
  reconcileOrdersInput: Array<SalesforceOrderInput>;
};


export type MutationReconcileUserListArgs = {
  reconcileUserInput: Array<ReconcileUserInput>;
};


export type MutationRegisterAttendanceArgs = {
  dataPortal1Input: DataPortal1Input;
  isPortal1: Scalars['Boolean'];
  liveSessionsId: Scalars['String'];
};


export type MutationRemoveCalendarEventResponsibleArgs = {
  calendarEventId: Scalars['ID'];
  userId: Scalars['ID'];
};


export type MutationRemoveOrganizationOwnerArgs = {
  organizationId: Scalars['ID'];
  userId: Scalars['ID'];
};


export type MutationRemoveOrganizationUserArgs = {
  organizationId: Scalars['ID'];
  userId: Scalars['ID'];
};


export type MutationRemoveUserBadgeArgs = {
  removeUserBadgeInput: RemoveUserBadgeInput;
};


export type MutationSaveMpServiceAndPublishArgs = {
  createMarketplaceServiceInput: CreateMarketplaceServiceInput;
};


export type MutationSaveMpServiceAsDraftArgs = {
  createMarketplaceServiceInput: CreateMarketplaceServiceInput;
};


export type MutationSendCohortInvitesArgs = {
  sendCohortInvitesInput: SendCohortInvitesInput;
};


export type MutationSendInvitesArgs = {
  sendInvitesInput: SendInvitesInput;
};


export type MutationSendNotificationToAdminsArgs = {
  sendNotificationMarketplaceServiceInput: SendNotificationMarketplaceServiceInput;
};


export type MutationSendProfilingAndPersonalDataToSalesforceArgs = {
  sendProfilingAndPersonalDataInput: SendProfilingAndPersonalDataInput;
};


export type MutationSendRocketfyToSalesforceArgs = {
  salesforceId: Scalars['String'];
};


export type MutationSetProgressArgs = {
  createProgressInput: CreateProgressInput;
};


export type MutationStartLiveSessionArgs = {
  liveSessionsId: Scalars['String'];
};


export type MutationStartRecordingAgoraArgs = {
  recordingAgoraInput: RecordingAgoraInput;
};


export type MutationStopRecordingAgoraArgs = {
  recordingAgoraInput: RecordingAgoraInput;
};


export type MutationStoreUserLeftEventArgs = {
  storeUserLeftEventInput: StoreUserLeftEventInput;
};


export type MutationSubscribeUserToSpecificTierArgs = {
  specificTierSubscriptionInput: ProEnrollmentInput;
};


export type MutationSwapInteractiveContentRankArgs = {
  firstInteractiveContentId: Scalars['ID'];
  secondInteractiveContentId: Scalars['ID'];
};


export type MutationSwapModuleRankArgs = {
  firstModuleId: Scalars['ID'];
  secondModuleId: Scalars['ID'];
};


export type MutationSwapSessionRankArgs = {
  firstSessionId: Scalars['ID'];
  secondSessionId: Scalars['ID'];
};


export type MutationSyncABdataArgs = {
  syncABdataInput: SyncABdataInput;
};


export type MutationSyncOrderFromSalesforceArgs = {
  salesforceOrderInput: SalesforceOrderInput;
};


export type MutationSyncProgramEnrollmentsArgs = {
  cohortId: Scalars['ID'];
};


export type MutationSyncUserDataFromSalesforceArgs = {
  syncUserDataFromSalesforceInput: SyncUserDataFromSalesforceInput;
};


export type MutationToggleCancelLiveSessionArgs = {
  isCancelled: Scalars['Boolean'];
  liveSessionId: Scalars['ID'];
};


export type MutationUnblockUserArgs = {
  unblockUserInput: DisenrollUserInput;
};


export type MutationUnblockUserFromMultipleCohortsArgs = {
  enrollUserMultipleInput: EnrollUserMultipleInput;
};


export type MutationUnmuteUserArgs = {
  postId: Scalars['String'];
  userId: Scalars['String'];
};


export type MutationUnsubscribeToSalesforceArgs = {
  unsubscribeInputs: UnsubscribeInputs;
};


export type MutationUnsubscribeUserFromSpecificTierArgs = {
  specificTierSubscriptionInput: ProEnrollmentInput;
};


export type MutationUpdateAttendanceArgs = {
  calendarEventId: Scalars['ID'];
};


export type MutationUpdateBadgeArgs = {
  updateBadgeInput: UpdateBadgeInput;
};


export type MutationUpdateBadge2Args = {
  updateBadgeInput: UpdateBadge2Input;
};


export type MutationUpdateBootcampArgs = {
  updateBootcampInput: UpdateBootcampInput;
};


export type MutationUpdateBootcampReleaseArgs = {
  updateBootcampReleaseInput: UpdateBootcampReleaseInput;
};


export type MutationUpdateCalendarEventArgs = {
  updateCalendarEventInput: UpdateCalendarEventInput;
};


export type MutationUpdateCategoryArgs = {
  categoryId: Scalars['ID'];
  newCategory: CategoryInput;
};


export type MutationUpdateCohortArgs = {
  updateCohortInput: UpdateCohortInput;
};


export type MutationUpdateCommunityEventArgs = {
  communityEventId: Scalars['ID'];
  updateCommunityEventInput: CommunityEventInput;
};


export type MutationUpdateCommunityUserArgs = {
  updateCommunityInput: UpdateCommunityInput;
};


export type MutationUpdateConsultantsArgs = {
  consultantID: Scalars['ID'];
  newConsultant: UpdateConsultantInput;
};


export type MutationUpdateCourseArgs = {
  updateCourseInput: UpdateCourseInput;
};


export type MutationUpdateExternalLinkArgs = {
  externalLink?: InputMaybe<Scalars['String']>;
  isExternalLinkActive: Scalars['Boolean'];
  liveSessionId: Scalars['ID'];
};


export type MutationUpdateFormArgs = {
  updateFormInput: UpdateFormInput;
};


export type MutationUpdateFormInstanceArgs = {
  updateFormInstanceInput: UpdateFormInstanceInput;
};


export type MutationUpdateHighTicketMentorshipArgs = {
  updateHighTicketMentorshipInput: UpdateHighTicketMentorshipInput;
};


export type MutationUpdateHotProductArgs = {
  hotProductId: Scalars['ID'];
  newHotProduct: UpdateHotProductInput;
};


export type MutationUpdateInteractiveContentArgs = {
  updateInteractiveContentInput: UpdateInteractiveContentInput;
};


export type MutationUpdateLiveSessionArgs = {
  input: LiveSessionInput;
};


export type MutationUpdateMasterClassArgs = {
  updateMasterClassInput: UpdateMasterClassInput;
};


export type MutationUpdateModuleArgs = {
  updateModuleInput: UpdateModuleInput;
};


export type MutationUpdateMpServiceArgs = {
  updateMarketplaceServiceInput: UpdateMarketplaceServiceInput;
};


export type MutationUpdateMyProfileArgs = {
  updateUserProfileInput: UpdateUserProfileInput;
};


export type MutationUpdateNotificationStatusArgs = {
  updatedNotificationStatusInput: UpdatedNotificationStatusInput;
};


export type MutationUpdateOrganizationArgs = {
  updateOrganizationInput: UpdateOrganizationInput;
};


export type MutationUpdateProgramArgs = {
  updateProgramInput: UpdateProgramInput;
};


export type MutationUpdateSchoolArgs = {
  updateSchoolInput: UpdateSchoolInput;
};


export type MutationUpdateSessionArgs = {
  updateSessionInput: UpdateSessionInput;
};


export type MutationUpdateSolutionArgs = {
  newSolution: CreateSolutionInput;
  solutionId: Scalars['ID'];
};


export type MutationUpdateTeamArgs = {
  updateTeamInput: UpdateTeamInput;
};


export type MutationUpdateUnboxingUserArgs = {
  userId: Scalars['ID'];
};


export type MutationUpdateUserArgs = {
  updateUserInput: UpdateUserInput;
};


export type MutationUpdateUserSettingsArgs = {
  input: UpdateUserSettingsInput;
};


export type MutationUpsertProductRatingArgs = {
  upsertProductRatingInput: UpsertProductRatingInput;
};

export type NewNotification = {
  __typename?: 'NewNotification';
  notificationRecords?: Maybe<Array<Maybe<NotificationData>>>;
  userId?: Maybe<Scalars['String']>;
};

export type Notification = {
  __typename?: 'Notification';
  channel: NotificationChannel;
  channelConfig: ChannelConfig;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  message: Array<TranslatedField>;
  notificationTime: Scalars['DateTime'];
  readAt?: Maybe<Scalars['DateTime']>;
  recipientId: Scalars['ID'];
  recipientType: Scalars['String'];
  status: NotificationStatus;
};

export enum NotificationChannel {
  Dashboard = 'DASHBOARD',
  Discord = 'DISCORD',
  Email = 'EMAIL',
  Slack = 'SLACK'
}

export type NotificationData = {
  __typename?: 'NotificationData';
  _id: Scalars['String'];
  author?: Maybe<Author>;
  description?: Maybe<Description>;
  link?: Maybe<Scalars['String']>;
  registerDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<Scalars['Boolean']>;
};

export type NotificationDataInput = {
  _id?: InputMaybe<Scalars['String']>;
  author?: InputMaybe<AuthorInput>;
  description?: InputMaybe<DescriptionInput>;
  link?: InputMaybe<Scalars['String']>;
  registerDate?: InputMaybe<Scalars['DateTime']>;
  status?: InputMaybe<Scalars['Boolean']>;
};

export enum NotificationStatus {
  Created = 'CREATED',
  Failed = 'FAILED',
  Read = 'READ',
  Sent = 'SENT'
}

export type OneToOneSession = {
  __typename?: 'OneToOneSession';
  _id?: Maybe<Scalars['ID']>;
  avatar?: Maybe<Scalars['String']>;
  date: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  isLinkEnabled?: Maybe<Scalars['Boolean']>;
  link: Scalars['String'];
  mentorId?: Maybe<Scalars['ID']>;
  mentorshipId?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  userId: Scalars['String'];
};

export type OneToOneSessionInput = {
  _id?: InputMaybe<Scalars['ID']>;
  date: Scalars['DateTime'];
  description?: InputMaybe<Scalars['String']>;
  link: Scalars['String'];
  mentorId?: InputMaybe<Scalars['ID']>;
  mentorshipId?: InputMaybe<Scalars['ID']>;
  title: Scalars['String'];
  userId: Scalars['String'];
};

export type Order = {
  __typename?: 'Order';
  currency: Scalars['String'];
  description: Scalars['String'];
  id: Scalars['ID'];
  items: Array<OrderItem>;
  paymentMethodType: PaymentMethod;
  referrerCode?: Maybe<Scalars['String']>;
  shortId: Scalars['String'];
  statusUpdates: Array<OrderStatusUpdate>;
  total: Price;
  user: User;
};

export type OrderItem = {
  __typename?: 'OrderItem';
  cohort?: Maybe<Cohort>;
  course?: Maybe<Course>;
  id: Scalars['ID'];
  publicCohort?: Maybe<CourseCohortPrice>;
  unitPrice: Price;
};

export type OrderItemInput = {
  cohortId: Scalars['ID'];
};

export type OrderResponse = {
  __typename?: 'OrderResponse';
  gatewayResponse?: Maybe<GatewayResponse>;
  order?: Maybe<Order>;
};

export enum OrderStatus {
  Cancelled = 'CANCELLED',
  Completed = 'COMPLETED',
  Created = 'CREATED',
  Declined = 'DECLINED',
  Expired = 'EXPIRED',
  Failed = 'FAILED',
  OnHold = 'ON_HOLD',
  Paid = 'PAID',
  Pending = 'PENDING',
  Processing = 'PROCESSING',
  Reimbursed = 'REIMBURSED',
  Resumed = 'RESUMED',
  Unkown = 'UNKOWN'
}

export type OrderStatusUpdate = {
  __typename?: 'OrderStatusUpdate';
  createdAt?: Maybe<Scalars['DateTime']>;
  status: OrderStatus;
};

export type OrdersReconcileItem = {
  __typename?: 'OrdersReconcileItem';
  contractId?: Maybe<Scalars['String']>;
  contractPositionId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  invoiceId?: Maybe<Scalars['String']>;
  orderId?: Maybe<Scalars['String']>;
  orderStatus?: Maybe<Scalars['String']>;
  salesforceUserId?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<Scalars['String']>;
  statusMessage?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};

export type Organization = PublicOrganization & {
  __typename?: 'Organization';
  id: Scalars['ID'];
  imageUrl: Scalars['String'];
  name: Scalars['String'];
  owners: Array<User>;
  users: Array<User>;
};


export type OrganizationUsersArgs = {
  userSearchInput: EntitySearchInput;
};

export enum OrganizationRole {
  Owner = 'OWNER',
  Sponsor = 'SPONSOR'
}

export type PayUConfig = {
  __typename?: 'PayUConfig';
  accountId: Scalars['String'];
  apiKey: Scalars['String'];
  merchantId: Scalars['String'];
  mode: Scalars['String'];
  useSandbox: Scalars['Boolean'];
};

export type PayUConfigInput = {
  accountId: Scalars['String'];
  apiKey: Scalars['String'];
  merchantId: Scalars['String'];
  mode: Scalars['String'];
  useSandbox: Scalars['Boolean'];
};

export type PayUResponse = {
  __typename?: 'PayUResponse';
  accountId: Scalars['String'];
  confirmationUrl: Scalars['String'];
  merchantId: Scalars['String'];
  mode: Scalars['String'];
  responseUrl: Scalars['String'];
  signature: Scalars['String'];
  webCheckoutUrl: Scalars['String'];
};

export enum PaymentMethod {
  AdaCoins = 'ADA_COINS',
  Free = 'FREE',
  Installments = 'INSTALLMENTS',
  PayU = 'PAY_U',
  PayWhenEmployed = 'PAY_WHEN_EMPLOYED',
  Stripe = 'STRIPE'
}

export type PersonalInformation = {
  __typename?: 'PersonalInformation';
  birthDate: Scalars['String'];
  cityOfResidence: Scalars['String'];
  countryOfResidence: Scalars['String'];
  gender: Scalars['String'];
  lastName: Scalars['String'];
  mainAddress: Scalars['String'];
  name: Scalars['String'];
  occupation?: Maybe<Scalars['String']>;
  regionOfResidence?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
};

export type PersonalInformationInput = {
  birthDate: Scalars['String'];
  cityOfResidence: Scalars['String'];
  countryOfResidence: Scalars['String'];
  gender: Scalars['String'];
  lastName: Scalars['String'];
  mainAddress: Scalars['String'];
  name: Scalars['String'];
  occupation: Scalars['String'];
  regionOfResidence: Scalars['String'];
  zipCode: Scalars['String'];
};

export type PlatformFeedback = {
  __typename?: 'PlatformFeedback';
  category: Scalars['String'];
  feedback: Scalars['String'];
  id: Scalars['ID'];
  pageUrl?: Maybe<Scalars['String']>;
};

export type PlatformFeedbackInput = {
  category: Scalars['String'];
  feedback: Scalars['String'];
  pageUrl?: InputMaybe<Scalars['String']>;
};

export type PlaylistObject = {
  mentorsPlaylists?: InputMaybe<Array<InputMaybe<MentorPlaylistInput>>>;
  prevSessionsPlaylist?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  suggestedTutorialsPlaylist?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type Post = {
  __typename?: 'Post';
  _id?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  author: Author;
  category?: Maybe<Scalars['String']>;
  comments?: Maybe<Array<Maybe<Comment>>>;
  content: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  mutedUsers?: Maybe<Array<Maybe<Scalars['String']>>>;
  tags: Array<Maybe<Scalars['String']>>;
  title?: Maybe<Scalars['String']>;
  totalComments?: Maybe<Scalars['Int']>;
};

export type PostResponse = {
  __typename?: 'PostResponse';
  posts?: Maybe<Array<Maybe<Post>>>;
  totalPosts?: Maybe<Scalars['Int']>;
};

export type Price = {
  __typename?: 'Price';
  currency: Scalars['String'];
  value: Scalars['Float'];
  valueInCents: Scalars['Int'];
};

export type PriceInput = {
  currency: Scalars['String'];
  valueInCents: Scalars['Int'];
};

export type ProEnrollmentInput = {
  agreementDuration?: InputMaybe<Scalars['Int']>;
  contractId?: InputMaybe<Scalars['String']>;
  contractPositionId?: InputMaybe<Scalars['String']>;
  invoiceId?: InputMaybe<Scalars['String']>;
  isPaymentRejected?: InputMaybe<Scalars['Boolean']>;
  orderDescription?: InputMaybe<Scalars['String']>;
  paymentMethodLink?: InputMaybe<Scalars['String']>;
  salesforceUserId?: InputMaybe<Scalars['String']>;
  shouldAcceptAgreement?: InputMaybe<Scalars['Boolean']>;
  startDate?: InputMaybe<Scalars['Int']>;
  tierToEnroll?: InputMaybe<Scalars['String']>;
  userId: Scalars['ID'];
};

export type ProEnrollmentSummary = {
  __typename?: 'ProEnrollmentSummary';
  contractId?: Maybe<Scalars['String']>;
  contractPositionId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Maybe<Scalars['String']>>>;
  invoiceId?: Maybe<Scalars['String']>;
  orderErrors?: Maybe<Array<Maybe<Scalars['String']>>>;
  orderId?: Maybe<Scalars['ID']>;
  orderStartDate?: Maybe<Scalars['DateTime']>;
  orderStatus?: Maybe<Scalars['String']>;
  orderUpdatedAt?: Maybe<Scalars['DateTime']>;
  proUpdatedAt?: Maybe<Scalars['DateTime']>;
  roles: Array<UserRole>;
  salesforceID?: Maybe<Scalars['String']>;
  salesforceUserId?: Maybe<Scalars['String']>;
  status: Scalars['String'];
  tierToEnroll?: Maybe<Scalars['String']>;
  userId: Scalars['ID'];
};

export type ProductRating = {
  __typename?: 'ProductRating';
  id: Scalars['ID'];
  productId: Scalars['ID'];
  productType: Scalars['String'];
  rating: Scalars['Int'];
  review?: Maybe<Scalars['String']>;
  userId: Scalars['ID'];
};

export type Profiling = {
  __typename?: 'Profiling';
  answers?: Maybe<Array<AnswersProfiling>>;
  id: Scalars['ID'];
  userId: Scalars['ID'];
};

export type ProfilingInformation = {
  __typename?: 'ProfilingInformation';
  answer: Array<Scalars['String']>;
  question: Scalars['String'];
};

export type ProfilingInformationInput = {
  answer: Array<Scalars['String']>;
  question: Scalars['String'];
};

export type Program = {
  __typename?: 'Program';
  availableUntil?: Maybe<Scalars['DateTime']>;
  brand?: Maybe<Array<Maybe<Category>>>;
  code: Scalars['String'];
  cohorts: Array<Cohort>;
  courses: Array<Course>;
  description?: Maybe<Array<TranslatedField>>;
  id: Scalars['ID'];
  id_mysql?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  items: Array<ProgramItem>;
  level?: Maybe<Array<Maybe<Category>>>;
  mentors: Array<User>;
  mentorsIds: Array<Scalars['ID']>;
  name: Scalars['String'];
  notifyInBanner?: Maybe<Scalars['Boolean']>;
  order?: Maybe<Scalars['Int']>;
  programType?: Maybe<EntityName>;
  schoolId: Scalars['ID'];
  status: CourseStatus;
  subCategory?: Maybe<Array<Maybe<Category>>>;
  tags?: Maybe<Array<Scalars['String']>>;
  thumbnailUrl?: Maybe<Scalars['String']>;
};

export type ProgramBannerProgress = {
  __typename?: 'ProgramBannerProgress';
  id: Scalars['ID'];
  thumbnailUrl?: Maybe<Scalars['String']>;
};

export type ProgramDetails = {
  __typename?: 'ProgramDetails';
  description?: Maybe<Array<TranslatedField>>;
  imageUrl?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  programId: Scalars['ID'];
};

export type ProgramItem = {
  __typename?: 'ProgramItem';
  content?: Maybe<ProgramItemContent>;
  id: Scalars['ID'];
  parentIds: Array<Scalars['ID']>;
};

export type ProgramItemContent = Course;

export type ProgramItemInput = {
  entity: EntityName;
  entityId: Scalars['ID'];
  parentIds: Array<Scalars['ID']>;
};

export type Progress = {
  __typename?: 'Progress';
  badgeNotification?: Maybe<BadgeNotification>;
  certificateNotification?: Maybe<CertificateNotification>;
  cohortId?: Maybe<Scalars['ID']>;
  entity: EntityName;
  entityId: Scalars['ID'];
  gamificationNotification?: Maybe<GamificationNotification>;
  id: Scalars['ID'];
  progress: Scalars['Int'];
  userId: Scalars['ID'];
};

export type ProgressRecalculationInput = {
  cohortId: Scalars['ID'];
  programOrCourseId: Scalars['ID'];
  sessionId: Scalars['ID'];
};

export type ProgressRecalculationSummary = {
  __typename?: 'ProgressRecalculationSummary';
  cohortId: Scalars['ID'];
  programOrCourseId: Scalars['ID'];
  sessionId: Scalars['ID'];
  totalUsersModified: Scalars['Int'];
};

export type PublicOrganization = {
  id: Scalars['ID'];
  imageUrl: Scalars['String'];
  name: Scalars['String'];
};

export type PublicOrganizationWithRole = {
  __typename?: 'PublicOrganizationWithRole';
  organization: PublicOrganization;
  role: OrganizationRole;
};

export type PublicOrganizationWithRoleInput = {
  organizationId: Scalars['ID'];
  role: OrganizationRole;
};

export type PublicSchool = BaseSchool & {
  __typename?: 'PublicSchool';
  googleAnalytics?: Maybe<GoogleAnalyticsConfig>;
  id: Scalars['ID'];
  linkedInId?: Maybe<Scalars['String']>;
  logoUrl?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  theme?: Maybe<Theme>;
};

export type PublicUser = {
  __typename?: 'PublicUser';
  avatarUrl?: Maybe<Scalars['String']>;
  gitHubUsername?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  profile?: Maybe<PublicUserProfile>;
  referrerCode?: Maybe<Scalars['String']>;
};

export type PublicUserProfile = {
  __typename?: 'PublicUserProfile';
  id: Scalars['ID'];
};

export type Query = {
  __typename?: 'Query';
  badge: Badge;
  badge2: Badge2;
  badges2: Array<Badge2>;
  calendarEvent?: Maybe<CalendarEvent>;
  calendarEventsByDateRange: Array<CalendarEvent>;
  calendarEventsByResponsible: Array<CalendarEvent>;
  calendarEventsBySession: Array<CalendarEvent>;
  categories: Array<Category>;
  category?: Maybe<Category>;
  categoryTypes: Array<Scalars['String']>;
  certificate: Certificate;
  certificates?: Maybe<Array<Certificate>>;
  cohort?: Maybe<Cohort>;
  cohorts: Array<Cohort>;
  course?: Maybe<Course>;
  courseCohortPrice?: Maybe<CourseCohortPrice>;
  courses: Array<Course>;
  currentSchool: PublicSchool;
  feedback: Array<Feedback>;
  form: Form;
  formInstance: FormInstance;
  formInstancesByEntity: Array<FormInstance>;
  formResponse?: Maybe<FormResultsDisplayData>;
  forms: Array<Form>;
  getActiveMentorshipForUser?: Maybe<Array<Maybe<HighTicketMentorship>>>;
  getAllConsultants: Array<Consultant>;
  getAllCountries: Array<Country>;
  getAllHotProducts: Array<HotProduct>;
  getAllowedPaymentMethods: Array<PaymentMethod>;
  getBannerToContinueProgress: BannerContinueProgress;
  getBootcampById: Bootcamp;
  getBootcampCohortsByParentId: Array<Maybe<Cohort>>;
  getBootcampParents: Array<Maybe<Bootcamp>>;
  getBootcampReleaseDetails: BootcampBannerEntry;
  getBootcampReleasesByParentId: Array<Maybe<BootcampRelease>>;
  getBootcampsForBanners: BootcampsBannerSummary;
  getCitiesByCountryAndRegionId: Array<City>;
  getCommentById?: Maybe<Comment>;
  getCommentsByPostId?: Maybe<Array<Maybe<Comment>>>;
  getCommunityEvents: Array<Maybe<CommunityEvent>>;
  getEntityChangeHistory: Array<ChangeHistory>;
  getGamificationByUserId?: Maybe<Gamification>;
  getGradedDeliverablesHours: Array<WorkReport>;
  getHighTicketsForAdminPanel?: Maybe<Array<Maybe<HighTicketMentorship>>>;
  getHotProduct: HotProduct;
  getHotProducts: Array<HotProduct>;
  getHotProductsCalendar: Array<Month>;
  getJwtAuthLiveSession: LiveSessionAuthJwtZoom;
  getLastCancellationAttemptTimestamp: Scalars['Float'];
  getLearnFilterMentors: Array<MentorForFilter>;
  getLiveSessionAuthentication: LiveSessionAuth;
  getLiveSessionAuthenticationPortalV1: LiveSessionAuth;
  getLiveSessionRecordings: Array<Maybe<LiveSessionRecording>>;
  getLiveSessions?: Maybe<LivesessionsOutPut>;
  getLiveSessionsForCarrousel: LiveSessionsForCarrousel;
  getLiveSessionsForCurrentWeek: Array<Maybe<LiveSessionWeekTypes>>;
  getLiveSessionsForModule: Array<Maybe<LiveSession>>;
  getLiveSessionsHours: TotalRoleHours;
  getLiveSessionsOnboarding?: Maybe<Array<Maybe<LiveSession>>>;
  getLiveSessionsPortalv1: Array<Maybe<LiveSessionWeekTypesP1>>;
  getMasterClasses?: Maybe<Array<Maybe<MasterClass>>>;
  getMentorshipsForCarousel: Array<Maybe<HighTicketMentorship>>;
  getMyOrders: Array<Maybe<SalesforceOrder>>;
  getMyPremiumRequestStatus: Scalars['Boolean'];
  getNotificationByUserId?: Maybe<NewNotification>;
  getPaginatedLiveSessions: LiveSessionsPaginated;
  getPostById?: Maybe<Post>;
  getPosts?: Maybe<PostResponse>;
  getProductRatingByUserAndProduct?: Maybe<ProductRating>;
  getRegionsByCountryId: Array<Region>;
  getRemainingAgreement?: Maybe<RemainingAgreement>;
  getServiceForId: MarketplaceService;
  getServicesForAdminPanel: Array<Maybe<MarketplaceService>>;
  getServicesForDisplay: Array<Maybe<MarketplaceService>>;
  getUserBadges: Array<Badge2>;
  getUserOneToOneSessions: Array<Maybe<OneToOneSession>>;
  getUserProfiling?: Maybe<Profiling>;
  getUsers: UserConnection;
  getUsersByRole: Array<User>;
  getUsersForHighTicketMentorship: Array<Maybe<User>>;
  getVisibleCommunityEvents: Array<Maybe<CommunityEvent>>;
  getWhatsappCommunities?: Maybe<Array<Maybe<WhatsAppComunity>>>;
  getWhatsappCommunityByField?: Maybe<WhatsAppComunity>;
  getWorkReportHours: Array<WorkReport>;
  grade?: Maybe<Grade>;
  interactiveContent?: Maybe<InteractiveContent>;
  interactiveContentBySession: Array<InteractiveContent>;
  isProfilingDone: Scalars['Boolean'];
  isSessionScheduledAtSameTime?: Maybe<LiveSession>;
  isSessionScheduledAtSameTimeMasterClass?: Maybe<LiveSession>;
  isSessionScheduledAtSameTimePortalV1: Scalars['Boolean'];
  listLiveSessionsByToday: Array<Maybe<LiveSession>>;
  listLiveSessionsSchedulling: Array<Maybe<LiveSessionWeekTypes>>;
  me?: Maybe<User>;
  mentor?: Maybe<User>;
  /** @deprecated Use searchUsers instead */
  mentors: Array<User>;
  metrics: Array<Metric>;
  module?: Maybe<Module>;
  moduleList: Array<Maybe<Module>>;
  organization: Organization;
  organizations: Array<Organization>;
  platformFeedback: Array<PlatformFeedback>;
  program?: Maybe<Program>;
  publicUser?: Maybe<PublicUser>;
  school: School;
  searchAllPrograms: Array<Program>;
  searchBadges: Array<Badge>;
  searchById: Array<User>;
  searchCohorts: Array<Cohort>;
  searchCourses: Array<Course>;
  searchForms: Array<Form>;
  searchModules: Array<Module>;
  searchPrograms: Array<Program>;
  searchPublicCourses: Array<Course>;
  searchSessions: Array<Session>;
  searchTeams: Array<Team>;
  searchUsers: Array<User>;
  session?: Maybe<Session>;
  sessions: Array<Session>;
  sessionsByMentor: Array<Session>;
  solution: Solution;
  solutions: Array<Solution>;
  sprint?: Maybe<Sprint>;
  sprints: Array<Sprint>;
  /** @deprecated Use user instead */
  student?: Maybe<User>;
  /** @deprecated Use searchUsers instead */
  students: Array<User>;
  team?: Maybe<Team>;
  user?: Maybe<User>;
  userSettings?: Maybe<UserSettings>;
  validateUserEnrollment?: Maybe<Scalars['Boolean']>;
  validateUserForEnrollment?: Maybe<ValidateUserEnrollmentOutput>;
  visibleSolutions: Array<Solution>;
};


export type QueryBadgeArgs = {
  badgeId: Scalars['ID'];
};


export type QueryBadge2Args = {
  badgeId: Scalars['ID'];
};


export type QueryCalendarEventArgs = {
  calendarEventId: Scalars['ID'];
};


export type QueryCalendarEventsByDateRangeArgs = {
  cohortId?: InputMaybe<Scalars['ID']>;
  courseId?: InputMaybe<Scalars['ID']>;
  end: Scalars['String'];
  programId?: InputMaybe<Scalars['ID']>;
  start: Scalars['String'];
};


export type QueryCalendarEventsByResponsibleArgs = {
  userId: Scalars['ID'];
};


export type QueryCalendarEventsBySessionArgs = {
  sessionId: Scalars['ID'];
};


export type QueryCategoryArgs = {
  categoryId: Scalars['ID'];
};


export type QueryCertificateArgs = {
  certificateId: Scalars['ID'];
};


export type QueryCertificatesArgs = {
  userId: Scalars['ID'];
};


export type QueryCohortArgs = {
  cohortId: Scalars['ID'];
};


export type QueryCohortsArgs = {
  entityId?: InputMaybe<Scalars['ID']>;
  limit?: InputMaybe<Scalars['Int']>;
};


export type QueryCourseArgs = {
  courseId: Scalars['ID'];
};


export type QueryCourseCohortPriceArgs = {
  cohortId: Scalars['ID'];
  token?: InputMaybe<Scalars['String']>;
};


export type QueryCoursesArgs = {
  courseType?: InputMaybe<Scalars['String']>;
  ids?: InputMaybe<Array<Scalars['ID']>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  query?: InputMaybe<Scalars['String']>;
};


export type QueryFeedbackArgs = {
  cohortId?: InputMaybe<Scalars['ID']>;
  entity?: InputMaybe<EntityName>;
  entityId?: InputMaybe<Scalars['ID']>;
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
};


export type QueryFormArgs = {
  formId: Scalars['ID'];
};


export type QueryFormInstanceArgs = {
  formInstanceId: Scalars['ID'];
};


export type QueryFormInstancesByEntityArgs = {
  cohortId?: InputMaybe<Scalars['ID']>;
  entity: EntityName;
  entityId: Scalars['ID'];
  formId?: InputMaybe<Scalars['ID']>;
};


export type QueryFormResponseArgs = {
  cohortId: Scalars['ID'];
  entity: EntityName;
  entityId: Scalars['ID'];
  formId: Scalars['ID'];
};


export type QueryFormsArgs = {
  courseId?: InputMaybe<Scalars['ID']>;
};


export type QueryGetActiveMentorshipForUserArgs = {
  userId: Scalars['String'];
};


export type QueryGetAllowedPaymentMethodsArgs = {
  cohortId: Scalars['ID'];
  token?: InputMaybe<Scalars['String']>;
};


export type QueryGetBootcampByIdArgs = {
  bootcampId: Scalars['ID'];
};


export type QueryGetBootcampCohortsByParentIdArgs = {
  parentId: Scalars['ID'];
};


export type QueryGetBootcampReleaseDetailsArgs = {
  bootcampReleaseId: Scalars['ID'];
};


export type QueryGetBootcampReleasesByParentIdArgs = {
  parentId: Scalars['ID'];
};


export type QueryGetBootcampsForBannersArgs = {
  bootcampType: Scalars['String'];
};


export type QueryGetCitiesByCountryAndRegionIdArgs = {
  getCitiesByCountryAndRegionIdInput: GetCitiesByCountryAndRegionIdInput;
};


export type QueryGetCommentByIdArgs = {
  commentId: Scalars['String'];
};


export type QueryGetCommentsByPostIdArgs = {
  postId: Scalars['String'];
};


export type QueryGetEntityChangeHistoryArgs = {
  entity: EntityName;
  entityId: Scalars['ID'];
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type QueryGetGamificationByUserIdArgs = {
  userId: Scalars['ID'];
};


export type QueryGetGradedDeliverablesHoursArgs = {
  end: Scalars['String'];
  start: Scalars['String'];
  userId: Scalars['ID'];
};


export type QueryGetHotProductArgs = {
  hotProductId: Scalars['ID'];
};


export type QueryGetHotProductsArgs = {
  getHotProductsInput: GetHotProductsInput;
};


export type QueryGetJwtAuthLiveSessionArgs = {
  dataPortal1Input: DataPortal1Input;
  isPortal1: Scalars['Boolean'];
  liveSessionsId: Scalars['String'];
  liveStream: Scalars['Boolean'];
};


export type QueryGetLastCancellationAttemptTimestampArgs = {
  userId: Scalars['ID'];
};


export type QueryGetLearnFilterMentorsArgs = {
  mentorsIds: Array<Scalars['String']>;
};


export type QueryGetLiveSessionAuthenticationArgs = {
  liveSessionsId: Scalars['String'];
  liveStream: Scalars['Boolean'];
};


export type QueryGetLiveSessionAuthenticationPortalV1Args = {
  channelName: Scalars['String'];
  liveSessionsId: Scalars['String'];
  userId: Scalars['String'];
};


export type QueryGetLiveSessionsArgs = {
  liveSessionType?: InputMaybe<Array<InputMaybe<LiveSessionType>>>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
};


export type QueryGetLiveSessionsForCurrentWeekArgs = {
  liveSessionsSearchInput: LiveSessionsSearchInput;
};


export type QueryGetLiveSessionsForModuleArgs = {
  moduleId: Scalars['String'];
};


export type QueryGetLiveSessionsHoursArgs = {
  end: Scalars['String'];
  start: Scalars['String'];
  userId: Scalars['ID'];
};


export type QueryGetLiveSessionsOnboardingArgs = {
  createdAt?: InputMaybe<Scalars['String']>;
  timezone?: InputMaybe<Scalars['String']>;
};


export type QueryGetLiveSessionsPortalv1Args = {
  timezone: Scalars['String'];
  userId: Scalars['String'];
};


export type QueryGetNotificationByUserIdArgs = {
  userId: Scalars['ID'];
};


export type QueryGetPaginatedLiveSessionsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
};


export type QueryGetPostByIdArgs = {
  postId: Scalars['String'];
};


export type QueryGetPostsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type QueryGetProductRatingByUserAndProductArgs = {
  getProductRatingByUserAndProduct: GetProductRatingByUserAndProduct;
};


export type QueryGetRegionsByCountryIdArgs = {
  getRegionsByCountryIdInput: GetRegionsByCountryIdInput;
};


export type QueryGetServiceForIdArgs = {
  serviceId: Scalars['String'];
};


export type QueryGetUserBadgesArgs = {
  userId: Scalars['ID'];
};


export type QueryGetUserOneToOneSessionsArgs = {
  userId: Scalars['String'];
};


export type QueryGetUsersArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  roles?: InputMaybe<Array<UserRole>>;
  search?: InputMaybe<Scalars['String']>;
};


export type QueryGetUsersByRoleArgs = {
  role: UserRole;
};


export type QueryGetUsersForHighTicketMentorshipArgs = {
  mentorshipId: Scalars['ID'];
};


export type QueryGetWhatsappCommunityByFieldArgs = {
  whatsAppComunityFieldInput?: InputMaybe<WhatsAppComunityFieldInput>;
};


export type QueryGetWorkReportHoursArgs = {
  end: Scalars['String'];
  start: Scalars['String'];
  userId: Scalars['ID'];
};


export type QueryGradeArgs = {
  cohortId: Scalars['ID'];
  entity: EntityName;
  entityId: Scalars['ID'];
  userId: Scalars['ID'];
};


export type QueryInteractiveContentArgs = {
  interactiveContentId: Scalars['ID'];
};


export type QueryInteractiveContentBySessionArgs = {
  sessionId: Scalars['ID'];
};


export type QueryIsSessionScheduledAtSameTimeArgs = {
  liveSessionsId: Scalars['String'];
};


export type QueryIsSessionScheduledAtSameTimeMasterClassArgs = {
  liveSessionsId: Scalars['String'];
};


export type QueryIsSessionScheduledAtSameTimePortalV1Args = {
  liveSessionsId: Scalars['String'];
  userId: Scalars['String'];
};


export type QueryListLiveSessionsByTodayArgs = {
  liveSessionsSearchInput: LiveSessionsSearchInput;
};


export type QueryListLiveSessionsSchedullingArgs = {
  liveSessionsSearchInput: LiveSessionsSearchInput;
};


export type QueryMentorArgs = {
  mentorId: Scalars['ID'];
};


export type QueryMetricsArgs = {
  metricsSearchInput: MetricsSearchInput;
};


export type QueryModuleArgs = {
  moduleId: Scalars['ID'];
};


export type QueryModuleListArgs = {
  moduleIds?: InputMaybe<Array<Scalars['ID']>>;
};


export type QueryOrganizationArgs = {
  organizationId: Scalars['ID'];
};


export type QueryOrganizationsArgs = {
  organizationSearchInput: EntitySearchInput;
};


export type QueryPlatformFeedbackArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
};


export type QueryProgramArgs = {
  programId: Scalars['ID'];
};


export type QueryPublicUserArgs = {
  userId: Scalars['ID'];
};


export type QuerySchoolArgs = {
  schoolId?: InputMaybe<Scalars['ID']>;
};


export type QuerySearchBadgesArgs = {
  badgeSearchInput: EntitySearchInput;
};


export type QuerySearchByIdArgs = {
  usersIds: Array<Scalars['ID']>;
};


export type QuerySearchCohortsArgs = {
  cohortSearchInput: EntitySearchInput;
};


export type QuerySearchCoursesArgs = {
  courseSearchInput: EntitySearchInput;
};


export type QuerySearchFormsArgs = {
  formSearchInput: EntitySearchInput;
};


export type QuerySearchModulesArgs = {
  moduleSearchInput: EntitySearchInput;
};


export type QuerySearchProgramsArgs = {
  programSearchInput: EntitySearchInput;
};


export type QuerySearchPublicCoursesArgs = {
  courseSearchInput: EntitySearchInput;
};


export type QuerySearchSessionsArgs = {
  sessionSearchInput: EntitySearchInput;
};


export type QuerySearchTeamsArgs = {
  teamSearchInput: EntitySearchInput;
};


export type QuerySearchUsersArgs = {
  userSearchInput: EntitySearchInput;
};


export type QuerySessionArgs = {
  sessionId: Scalars['ID'];
};


export type QuerySessionsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  moduleId: Scalars['ID'];
  start?: InputMaybe<Scalars['ID']>;
};


export type QuerySessionsByMentorArgs = {
  mentorId: Scalars['ID'];
};


export type QuerySolutionArgs = {
  solutionId: Scalars['ID'];
};


export type QuerySprintArgs = {
  sprintId: Scalars['ID'];
};


export type QuerySprintsArgs = {
  courseId: Scalars['ID'];
};


export type QueryStudentArgs = {
  studentId: Scalars['ID'];
};


export type QueryTeamArgs = {
  cohortId?: InputMaybe<Scalars['ID']>;
  teamId: Scalars['ID'];
};


export type QueryUserArgs = {
  userId: Scalars['ID'];
};


export type QueryUserSettingsArgs = {
  userId: Scalars['ID'];
};


export type QueryValidateUserEnrollmentArgs = {
  validateUserEnrollmentInput: ValidateUserEnrollmentInput;
};


export type QueryValidateUserForEnrollmentArgs = {
  validateUserEnrollmentInput: ValidateUserEnrollmentInput;
};

export type Question = {
  __typename?: 'Question';
  answers: Array<AnswerOption>;
  description: Array<TranslatedField>;
  explication: Array<TranslatedField>;
  hasMultipleAnswers: Scalars['Boolean'];
  id: Scalars['ID'];
  score: Scalars['Int'];
};

export type QuestionInput = {
  answers: Array<AnswerOptionInput>;
  description: Array<TranslatedFieldInput>;
  explication: Array<TranslatedFieldInput>;
  score?: InputMaybe<Scalars['Int']>;
};

export type QuestionResponse = {
  __typename?: 'QuestionResponse';
  selectedAnswerId: Scalars['ID'];
};

export type QuestionResponseInput = {
  selectedAnswerId: Scalars['ID'];
};

export type QuestionResultData = {
  __typename?: 'QuestionResultData';
  earnedPoints: Scalars['Float'];
  isQuestionCorrect: Scalars['Boolean'];
  questionId: Scalars['ID'];
  questionScore: Scalars['Float'];
  totalPoints: Scalars['Float'];
};

export type QuestionsResultsDisplayData = {
  __typename?: 'QuestionsResultsDisplayData';
  correctAnswers: Array<Scalars['ID']>;
  correctResponses: Array<Scalars['ID']>;
  incorrectResponses: Array<Scalars['ID']>;
  questionResults: Array<QuestionResultData>;
};

export enum Ranges {
  Gold = 'GOLD',
  Initial = 'INITIAL',
  Platinum = 'PLATINUM',
  Silver = 'SILVER'
}

export type Rating = {
  __typename?: 'Rating';
  average: Scalars['Float'];
  count: Scalars['Int'];
};

export type ReconcileUserInput = {
  email: Scalars['String'];
  salesforceID: Scalars['String'];
  syncViaSalesforceID?: InputMaybe<Scalars['Boolean']>;
};

export type RecordingAgora = {
  __typename?: 'RecordingAgora';
  resourceId: Scalars['String'];
  sid: Scalars['String'];
  uid?: Maybe<Scalars['String']>;
};

export type RecordingAgoraInput = {
  liveSessionId: Scalars['String'];
  resourceId: Scalars['String'];
  sid: Scalars['String'];
  token: Scalars['String'];
  uid: Scalars['String'];
};

export type Region = {
  __typename?: 'Region';
  countryId: Scalars['ID'];
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type RemainingAgreement = {
  __typename?: 'RemainingAgreement';
  days: Scalars['Int'];
  months: Scalars['Int'];
};

export type RemoveUserBadgeInput = {
  badgeId: Scalars['ID'];
  userId: Scalars['ID'];
};

export type RequestPremiumResponse = {
  __typename?: 'RequestPremiumResponse';
  error?: Maybe<Scalars['String']>;
  successful?: Maybe<Scalars['Boolean']>;
};

export type Responsible = {
  __typename?: 'Responsible';
  role: ResponsibleRole;
  user: User;
};

export type ResponsibleInput = {
  role: ResponsibleRole;
  userId: Scalars['ID'];
};

export enum ResponsibleRole {
  Mentor = 'MENTOR',
  Shadow = 'SHADOW',
  Support = 'SUPPORT'
}

export type RocketfyLog = {
  __typename?: 'RocketfyLog';
  country: Scalars['String'];
  email: Scalars['String'];
  id: Scalars['ID'];
  indicative: Scalars['String'];
  name: Scalars['String'];
  partnership: Scalars['String'];
  phone: Scalars['String'];
  redirectUrl?: Maybe<Scalars['String']>;
  responseMessage: Scalars['String'];
  responseStatus: Scalars['String'];
  userId: Scalars['ID'];
};

export type RocketfyLogInput = {
  country: Scalars['String'];
  email: Scalars['String'];
  indicative: Scalars['String'];
  name: Scalars['String'];
  partnership: Scalars['String'];
  phone: Scalars['String'];
  redirectUrl?: InputMaybe<Scalars['String']>;
  responseMessage: Scalars['String'];
  responseStatus: Scalars['String'];
  userId: Scalars['ID'];
};

export enum Rules {
  ActivityMonthAward = 'ACTIVITY_MONTH_AWARD',
  ActivityQuarterAward = 'ACTIVITY_QUARTER_AWARD',
  ActivitySemesterAward = 'ACTIVITY_SEMESTER_AWARD',
  ActivityYearAward = 'ACTIVITY_YEAR_AWARD',
  ClassStartedLive = 'CLASS_STARTED_LIVE',
  CompletedAdvancedCourse = 'COMPLETED_ADVANCED_COURSE',
  CompletedDiplomat = 'COMPLETED_DIPLOMAT',
  CompletedMeasurementAnalysis = 'COMPLETED_MEASUREMENT_ANALYSIS',
  CompletedOperatingPlatform = 'COMPLETED_OPERATING_PLATFORM',
  CompletedProfile = 'COMPLETED_PROFILE',
  CompletedRegularCourse = 'COMPLETED_REGULAR_COURSE',
  CompletedSpecialization = 'COMPLETED_SPECIALIZATION',
  DiplomaStarted = 'DIPLOMA_STARTED',
  MasterclassStarted = 'MASTERCLASS_STARTED',
  QuizCompleted = 'QUIZ_COMPLETED'
}

export type SalesforceOrder = {
  __typename?: 'SalesforceOrder';
  contractId: Scalars['String'];
  contractPositionId: Scalars['String'];
  description: Scalars['String'];
  id: Scalars['ID'];
  invoiceId: Scalars['String'];
  isPaymentRejected?: Maybe<Scalars['Boolean']>;
  orderStatus: Scalars['String'];
  paymentMethodLink?: Maybe<Scalars['String']>;
  salesforceUserId: Scalars['String'];
  startDate: Scalars['DateTime'];
  userId: Scalars['ID'];
};

export type SalesforceOrderInput = {
  contractId?: InputMaybe<Scalars['String']>;
  contractPositionId?: InputMaybe<Scalars['String']>;
  description: Scalars['String'];
  invoiceId?: InputMaybe<Scalars['String']>;
  isPaymentRejected?: InputMaybe<Scalars['Boolean']>;
  orderStatus: Scalars['String'];
  paymentMethodLink?: InputMaybe<Scalars['String']>;
  salesforceUserId?: InputMaybe<Scalars['String']>;
  startDate: Scalars['Int'];
  userId: Scalars['ID'];
};

export type SalesforceOrderSummary = {
  __typename?: 'SalesforceOrderSummary';
  contractId?: Maybe<Scalars['String']>;
  contractPositionId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Maybe<Scalars['String']>>>;
  invoiceId?: Maybe<Scalars['String']>;
  orderId?: Maybe<Scalars['ID']>;
  orderStatus?: Maybe<Scalars['String']>;
  orderUpdatedAt: Scalars['DateTime'];
  salesforceUserId?: Maybe<Scalars['String']>;
  startDate: Scalars['DateTime'];
  status: Scalars['String'];
  userId: Scalars['ID'];
};

export type School = BaseSchool & {
  __typename?: 'School';
  allowedIntegrations: Array<Scalars['String']>;
  allowedPaymentMethods: Array<PaymentMethod>;
  discord?: Maybe<DiscordConfig>;
  domain: Array<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  googleAnalytics?: Maybe<GoogleAnalyticsConfig>;
  id: Scalars['ID'];
  linkedInId?: Maybe<Scalars['String']>;
  logoUrl?: Maybe<Scalars['String']>;
  mailgun?: Maybe<MailgunConfig>;
  mailing?: Maybe<MailingConfig>;
  name: Scalars['String'];
  payU?: Maybe<PayUConfig>;
  stripe?: Maybe<StripeConfig>;
  theme?: Maybe<Theme>;
};

export type SendCohortInvitesInput = {
  cohortId: Scalars['ID'];
  domain: Scalars['String'];
  invites: Array<InviteInput>;
};

export type SendInvitesInput = {
  domain: Scalars['String'];
  invites: Array<InviteInput>;
};

export type SendNotificationMarketplaceServiceInput = {
  agencyName: Scalars['String'];
  recipients: Array<Scalars['String']>;
  userMessage: Scalars['String'];
};

export type SendProfilingAndPersonalData = {
  __typename?: 'SendProfilingAndPersonalData';
  id: Scalars['ID'];
  personalInformation?: Maybe<PersonalInformation>;
  profilingInformation?: Maybe<Array<Maybe<ProfilingInformation>>>;
};

export type SendProfilingAndPersonalDataInput = {
  id: Scalars['ID'];
  personalInformation: PersonalInformationInput;
  profilingInformation: Array<ProfilingInformationInput>;
  tcAccepted: Scalars['Boolean'];
};

export type Session = {
  __typename?: 'Session';
  activityType: ActivityType;
  affiliateLinks?: Maybe<Array<AffiliateLinks>>;
  calendarEvents?: Maybe<Array<CalendarEvent>>;
  course?: Maybe<Course>;
  courseId: Scalars['ID'];
  downloableContent?: Maybe<Array<DownloableContent>>;
  duration: Scalars['Float'];
  feedbackRating?: Maybe<Rating>;
  gamificationNotification?: Maybe<GamificationNotification>;
  goals: Array<LearningGoal>;
  group?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  id_mysql?: Maybe<Scalars['String']>;
  interactiveContent?: Maybe<Array<InteractiveContent>>;
  moduleId: Scalars['ID'];
  moduleInfo: SessionModuleInfo;
  name: Array<TranslatedField>;
  prerecordingLink?: Maybe<Scalars['String']>;
  presentationLink?: Maybe<Scalars['String']>;
  rank: Scalars['String'];
  solutions?: Maybe<Array<Maybe<Scalars['String']>>>;
  userProgress?: Maybe<Progress>;
};


export type SessionCalendarEventsArgs = {
  cohortId: Scalars['ID'];
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type SessionUserProgressArgs = {
  cohortId: Scalars['ID'];
};

export type SessionModuleInfo = {
  __typename?: 'SessionModuleInfo';
  /** @deprecated No longer supported, will be removed in future versions */
  code?: Maybe<Scalars['String']>;
  name?: Maybe<Array<TranslatedField>>;
  rank: Scalars['String'];
};

export type SlackNotificationConfig = {
  __typename?: 'SlackNotificationConfig';
  slackChannel: Scalars['String'];
};

export type Solution = {
  __typename?: 'Solution';
  benefits: Array<Scalars['String']>;
  category?: Maybe<Category>;
  description: Scalars['String'];
  details: Scalars['String'];
  features: Array<SolutionFeature>;
  id: Scalars['ID'];
  imgUrl: Scalars['String'];
  isVisible?: Maybe<Scalars['Boolean']>;
  links: Array<SolutionLink>;
  name: Scalars['String'];
  popularity: Scalars['String'];
  price?: Maybe<SolutionPrice>;
};

export type SolutionFeature = {
  __typename?: 'SolutionFeature';
  description: Scalars['String'];
  title?: Maybe<Scalars['String']>;
};

export type SolutionFeatureInput = {
  description: Scalars['String'];
  title: Scalars['String'];
};

export type SolutionLink = {
  __typename?: 'SolutionLink';
  label?: Maybe<Scalars['String']>;
  link: Scalars['String'];
};

export type SolutionLinkInput = {
  label?: InputMaybe<Scalars['String']>;
  link: Scalars['String'];
};

export type SolutionPrice = {
  __typename?: 'SolutionPrice';
  regular?: Maybe<Scalars['String']>;
  special?: Maybe<Scalars['String']>;
};

export type SolutionPriceInput = {
  regular: Scalars['String'];
  special: Scalars['String'];
};

export type Sprint = {
  __typename?: 'Sprint';
  code: Scalars['String'];
  goals: Array<LearningGoal>;
  id: Scalars['ID'];
  name: Scalars['String'];
  sessions: Array<Session>;
};

export type StoreUserLeftEventInput = {
  liveSessionId: Scalars['ID'];
};

export type StoreUserLeftEventResponse = {
  __typename?: 'StoreUserLeftEventResponse';
  liveSessionId: Scalars['ID'];
  userId: Scalars['ID'];
};

export type StripeConfig = {
  __typename?: 'StripeConfig';
  endpointSecret: Scalars['String'];
  publishableKey: Scalars['String'];
  secretKey: Scalars['String'];
};

export type StripeConfigInput = {
  endpointSecret: Scalars['String'];
  publishableKey: Scalars['String'];
  secretKey: Scalars['String'];
};

export type StripeResponse = {
  __typename?: 'StripeResponse';
  checkoutUrl: Scalars['String'];
};

export type Subscription = {
  __typename?: 'Subscription';
  commentNotification?: Maybe<Comment>;
  enrollUserInMultipleCohortsSubscrition?: Maybe<Scalars['Boolean']>;
  liveSessionNotification?: Maybe<LiveSession>;
  me?: Maybe<User>;
  newNotification?: Maybe<NewNotification>;
  postNotification?: Maybe<Post>;
};


export type SubscriptionMeArgs = {
  userId: Scalars['ID'];
};

export type SyncABdataInput = {
  key: Scalars['String'];
  value: Scalars['Boolean'];
};

export type SyncUserDataFromSalesforceInput = {
  birthDate?: InputMaybe<Scalars['String']>;
  cityOfResidence?: InputMaybe<Scalars['String']>;
  countryOfResidence?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  forceProUpdate?: InputMaybe<Scalars['Boolean']>;
  gender?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  lastName?: InputMaybe<Scalars['String']>;
  mainAddress?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  regionOfResidence?: InputMaybe<Scalars['String']>;
  salesforceID: Scalars['String'];
  secondaryAddress?: InputMaybe<Scalars['String']>;
  website?: InputMaybe<Scalars['String']>;
};

export type Team = {
  __typename?: 'Team';
  cohort: Cohort;
  cohortId: Scalars['ID'];
  id: Scalars['ID'];
  members: Array<PublicUser>;
  name: Scalars['String'];
};

export type Theme = {
  __typename?: 'Theme';
  colors?: Maybe<ThemeColors>;
};

export type ThemeColors = {
  __typename?: 'ThemeColors';
  dark?: Maybe<Scalars['JSON']>;
  light?: Maybe<Scalars['JSON']>;
  primary?: Maybe<Scalars['JSON']>;
  secondary?: Maybe<Scalars['JSON']>;
  tertiary?: Maybe<Scalars['JSON']>;
};

export type ThemeColorsInput = {
  dark?: InputMaybe<Scalars['JSON']>;
  light?: InputMaybe<Scalars['JSON']>;
  primary?: InputMaybe<Scalars['JSON']>;
  secondary?: InputMaybe<Scalars['JSON']>;
  tertiary?: InputMaybe<Scalars['JSON']>;
};

export type ThemeInput = {
  colors?: InputMaybe<ThemeColorsInput>;
};

export type TotalPeriodHours = {
  __typename?: 'TotalPeriodHours';
  comments?: Maybe<Scalars['String']>;
  period: Scalars['DateTime'];
  totalHours: Scalars['Float'];
};

export type TotalRoleHours = {
  __typename?: 'TotalRoleHours';
  mentoring: Array<TotalPeriodHours>;
  preparation: Array<TotalPeriodHours>;
  shadowing: Array<TotalPeriodHours>;
};

export type TranslatedField = {
  __typename?: 'TranslatedField';
  locale: Scalars['String'];
  value: Scalars['String'];
};

export type TranslatedFieldInput = {
  locale: Scalars['String'];
  value: Scalars['String'];
};

export type UnsubscribeInputs = {
  salesforceID: Scalars['String'];
  salesforceUserId: Scalars['String'];
  suppliedEmail: Scalars['String'];
  suppliedName: Scalars['String'];
  suppliedPhone: Scalars['String'];
  userId: Scalars['ID'];
};

export type UnsubscribeToSalesforce = {
  __typename?: 'UnsubscribeToSalesforce';
  businessHoursId: Scalars['ID'];
  origin: Scalars['String'];
  salesforceID: Scalars['String'];
  suppliedEmail: Scalars['String'];
  suppliedName: Scalars['String'];
  suppliedPhone: Scalars['String'];
  userId: Scalars['ID'];
};

export type UpdateAnswerOptionInput = {
  description?: InputMaybe<Array<TranslatedFieldInput>>;
  id?: InputMaybe<Scalars['ID']>;
  isCorrectAnswer?: InputMaybe<Scalars['Boolean']>;
};

export type UpdateBadge2Input = {
  color?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  imageUrl?: InputMaybe<Badge2ImageUrlInput>;
  name?: InputMaybe<Scalars['String']>;
  trigger?: InputMaybe<Badge2TriggerInput>;
  type?: InputMaybe<Scalars['String']>;
};

export type UpdateBadgeInput = {
  badgeColor?: InputMaybe<Scalars['String']>;
  badgeType?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Array<TranslatedFieldInput>>;
  id: Scalars['ID'];
  imageUrl?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Array<TranslatedFieldInput>>;
};

export type UpdateBootcampInput = {
  bootcampType?: InputMaybe<Scalars['String']>;
  brandId: Array<Scalars['ID']>;
  category?: InputMaybe<CategoryInput>;
  description?: InputMaybe<Scalars['String']>;
  faqs?: InputMaybe<Array<FaqInput>>;
  id: Scalars['ID'];
  levelId: Array<Scalars['ID']>;
  prerequisites?: InputMaybe<Array<Scalars['ID']>>;
  status?: InputMaybe<Scalars['String']>;
  subCategoryId: Array<Scalars['ID']>;
  tags?: InputMaybe<Array<Scalars['String']>>;
  title?: InputMaybe<Scalars['String']>;
};

export type UpdateBootcampReleaseInput = {
  bootcampType?: InputMaybe<Scalars['String']>;
  classes?: InputMaybe<Array<BootcampClassInput>>;
  description?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  faqs?: InputMaybe<Array<FaqInput>>;
  id?: InputMaybe<Scalars['ID']>;
  linkForResources?: InputMaybe<Scalars['String']>;
  mentors?: InputMaybe<Array<BootcampMentorInput>>;
  new?: InputMaybe<Scalars['Boolean']>;
  parentId?: InputMaybe<Scalars['ID']>;
  playlists?: InputMaybe<PlaylistObject>;
  prerequisites?: InputMaybe<Array<Scalars['ID']>>;
  signUpLink?: InputMaybe<Scalars['String']>;
  speakers?: InputMaybe<Array<BootcampSpeakerInput>>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  tags?: InputMaybe<Array<Scalars['String']>>;
  targetAudience?: InputMaybe<Array<Scalars['String']>>;
  title?: InputMaybe<Scalars['String']>;
  whatYouWillLearn?: InputMaybe<Array<Scalars['String']>>;
};

export type UpdateCalendarEventInput = {
  allDay?: InputMaybe<Scalars['Boolean']>;
  cohortId?: InputMaybe<Scalars['ID']>;
  duration?: InputMaybe<Scalars['Float']>;
  id: Scalars['ID'];
  liveSessionLink?: InputMaybe<Scalars['String']>;
  presentationLink?: InputMaybe<Scalars['String']>;
  recordingLink?: InputMaybe<Scalars['String']>;
  responsibles?: InputMaybe<Array<ResponsibleInput>>;
  sessionId?: InputMaybe<Scalars['ID']>;
  start?: InputMaybe<Scalars['DateTime']>;
};

export type UpdateCohortInput = {
  code?: InputMaybe<Scalars['String']>;
  defaultLiveSessionLink?: InputMaybe<Scalars['String']>;
  discordChannel?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  isPublic?: InputMaybe<Scalars['Boolean']>;
  prices?: InputMaybe<Array<PriceInput>>;
  sessionDefaultDuration?: InputMaybe<Scalars['Int']>;
  slackChannel?: InputMaybe<Scalars['String']>;
  sponsorIds?: InputMaybe<Array<Scalars['ID']>>;
  start?: InputMaybe<Scalars['DateTime']>;
  timeZone?: InputMaybe<Scalars['String']>;
  weekdays?: InputMaybe<Array<Scalars['Int']>>;
};

export type UpdateCommunityInput = {
  communityWhatsApp?: InputMaybe<Scalars['JSON']>;
  id: Scalars['ID'];
};

export type UpdateConsultantInput = {
  bio?: InputMaybe<Scalars['String']>;
  buttonUrl?: InputMaybe<Scalars['String']>;
  buttonUrl30Min?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  isVisible?: InputMaybe<Scalars['Boolean']>;
  job?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  photo?: InputMaybe<Scalars['String']>;
  popularity: Scalars['String'];
  previousExperience?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sections?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  shortSpecialty?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  skills?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  socialMedia?: InputMaybe<ConsultantSocialMediaInput>;
  value?: InputMaybe<Scalars['String']>;
  value30Min?: InputMaybe<Scalars['String']>;
};

export type UpdateCourseInput = {
  badgesIds?: InputMaybe<Array<Scalars['ID']>>;
  brandId: Array<Scalars['ID']>;
  code?: InputMaybe<Scalars['String']>;
  coins?: InputMaybe<Scalars['String']>;
  courseType?: InputMaybe<EntityName>;
  description?: InputMaybe<Array<TranslatedFieldInput>>;
  details?: InputMaybe<Array<TranslatedFieldInput>>;
  holidays?: InputMaybe<Array<HolidayInput>>;
  id: Scalars['ID'];
  imageUrl?: InputMaybe<Scalars['String']>;
  levelId: Array<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['Int']>;
  organizations?: InputMaybe<Array<PublicOrganizationWithRoleInput>>;
  status?: InputMaybe<CourseStatus>;
  subCategoryId: Array<Scalars['ID']>;
  tags?: InputMaybe<Array<Scalars['String']>>;
  translatedName?: InputMaybe<Array<TranslatedFieldInput>>;
  visibility?: InputMaybe<CourseVisibility>;
};

export type UpdateFormInput = {
  allowResponseUpdate?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  isGradeable?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Array<TranslatedFieldInput>>;
  questions?: InputMaybe<Array<UpdateQuestionInput>>;
};

export type UpdateFormInstanceInput = {
  cohortId?: InputMaybe<Scalars['ID']>;
  end?: InputMaybe<Scalars['DateTime']>;
  entity?: InputMaybe<EntityName>;
  entityId?: InputMaybe<Scalars['ID']>;
  id: Scalars['ID'];
  start?: InputMaybe<Scalars['DateTime']>;
};

export type UpdateHotProductInput = {
  competitors?: InputMaybe<Array<InputMaybe<HotProductCompetitorInput>>>;
  description?: InputMaybe<Scalars['String']>;
  imagesURLs?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  isVisible?: InputMaybe<Scalars['Boolean']>;
  minQuantity?: InputMaybe<Scalars['Int']>;
  month?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Array<InputMaybe<HotProductPriceInput>>>;
  recommendations?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  releaseDate?: InputMaybe<Scalars['DateTime']>;
  reportsAvailable?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  reportsDownloadLink?: InputMaybe<Scalars['String']>;
  score?: InputMaybe<Scalars['String']>;
};

export type UpdateInteractiveContentInput = {
  contentType?: InputMaybe<InteractiveContentType>;
  description?: InputMaybe<Array<TranslatedFieldInput>>;
  formId?: InputMaybe<Scalars['ID']>;
  id: Scalars['ID'];
  rank?: InputMaybe<Scalars['String']>;
  subTitle?: InputMaybe<Array<TranslatedFieldInput>>;
  title?: InputMaybe<Array<TranslatedFieldInput>>;
  video?: InputMaybe<Array<TranslatedFieldInput>>;
};

export type UpdateLiveSessionOrMasterclassInput = {
  brandId: Array<Scalars['ID']>;
  endDate: Scalars['DateTime'];
  id: Scalars['ID'];
  imageUrlMentor?: InputMaybe<Scalars['String']>;
  isCancelled?: InputMaybe<Scalars['Boolean']>;
  levelId: Array<Scalars['ID']>;
  liveSessionType: LiveSessionType;
  mentorId?: InputMaybe<Scalars['ID']>;
  mentorName?: InputMaybe<Scalars['String']>;
  moduleId: Scalars['ID'];
  name: Scalars['String'];
  programId: Scalars['ID'];
  recurrence?: InputMaybe<LiveSessionRecurrence>;
  startDate: Scalars['DateTime'];
  subCategoryId: Array<Scalars['ID']>;
  userId: Scalars['ID'];
};

export type UpdateLiveSessionRecordingInput = {
  id: Scalars['ID'];
  isRecordingSent: Scalars['Boolean'];
  link: Scalars['String'];
  notifiedAt: Scalars['DateTime'];
};

export type UpdateMarketplaceServiceInput = {
  agency?: InputMaybe<Scalars['String']>;
  agencyImage?: InputMaybe<Scalars['String']>;
  categoryId?: InputMaybe<Scalars['ID']>;
  contactLink?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  draft?: InputMaybe<Scalars['Boolean']>;
  howItWorks?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  id: Scalars['ID'];
  imageGallery?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  published?: InputMaybe<Scalars['Boolean']>;
  recipientsEmails?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  responseTime?: InputMaybe<Scalars['String']>;
  services?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  subcategoryId?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  subtitle?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  whyUs?: InputMaybe<Scalars['String']>;
};

export type UpdateMasterClassInput = {
  brandId: Array<Scalars['ID']>;
  description: Scalars['String'];
  endDate: Scalars['DateTime'];
  id: Scalars['ID'];
  imageUrlMentor: Scalars['String'];
  isCancelled?: InputMaybe<Scalars['Boolean']>;
  isWorkshop?: InputMaybe<Scalars['Boolean']>;
  levelId: Array<Scalars['ID']>;
  liveSessionType?: InputMaybe<LiveSessionType>;
  mentorId: Scalars['ID'];
  mentorName: Scalars['String'];
  name: Scalars['String'];
  startDate: Scalars['DateTime'];
  subCategoryId: Array<Scalars['ID']>;
  userId: Scalars['ID'];
};

export type UpdateModuleInput = {
  badgesIds?: InputMaybe<Array<Scalars['ID']>>;
  description?: InputMaybe<Array<TranslatedFieldInput>>;
  id: Scalars['ID'];
  name?: InputMaybe<Array<TranslatedFieldInput>>;
  prerequisitesIds?: InputMaybe<Array<Scalars['ID']>>;
  rank?: InputMaybe<Scalars['String']>;
};

export type UpdateOrganizationInput = {
  id: Scalars['ID'];
  imageUrl?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

export type UpdateProgramInput = {
  brandId: Array<Scalars['ID']>;
  code?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Array<TranslatedFieldInput>>;
  id: Scalars['ID'];
  imageUrl?: InputMaybe<Scalars['String']>;
  items?: InputMaybe<Array<ProgramItemInput>>;
  levelId: Array<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['Int']>;
  programType?: InputMaybe<EntityName>;
  status?: InputMaybe<CourseStatus>;
  subCategoryId: Array<Scalars['ID']>;
  tags?: InputMaybe<Array<Scalars['String']>>;
  thumbnailUrl?: InputMaybe<Scalars['String']>;
};

export type UpdateQuestionInput = {
  answers?: InputMaybe<Array<UpdateAnswerOptionInput>>;
  description?: InputMaybe<Array<TranslatedFieldInput>>;
  explication?: InputMaybe<Array<TranslatedFieldInput>>;
  id?: InputMaybe<Scalars['ID']>;
  score?: InputMaybe<Scalars['Int']>;
};

export type UpdateSchoolInput = {
  allowedIntegrations: Array<Scalars['String']>;
  allowedPaymentMethods?: InputMaybe<Array<PaymentMethod>>;
  discord?: InputMaybe<DiscordConfigInput>;
  domain?: InputMaybe<Array<Scalars['String']>>;
  email?: InputMaybe<Scalars['String']>;
  googleAnalytics?: InputMaybe<GoogleAnalyticsConfigInput>;
  id: Scalars['ID'];
  linkedInId?: InputMaybe<Scalars['String']>;
  logoUrl?: InputMaybe<Scalars['String']>;
  mailgun?: InputMaybe<MailgunConfigInput>;
  mailing?: InputMaybe<MailingConfigInput>;
  name?: InputMaybe<Scalars['String']>;
  payU?: InputMaybe<PayUConfigInput>;
  stripe?: InputMaybe<StripeConfigInput>;
  theme?: InputMaybe<ThemeInput>;
};

export type UpdateSessionInput = {
  activityType?: InputMaybe<ActivityType>;
  affiliateLinks?: InputMaybe<Array<InputMaybe<AffiliateLinksInput>>>;
  courseId?: InputMaybe<Scalars['ID']>;
  downloableContent?: InputMaybe<Array<InputMaybe<DownloableContentInput>>>;
  duration?: InputMaybe<Scalars['Float']>;
  goals?: InputMaybe<Array<LearningGoalInput>>;
  id: Scalars['ID'];
  moduleId?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Array<TranslatedFieldInput>>;
  prerecordingLink?: InputMaybe<Scalars['String']>;
  presentationLink?: InputMaybe<Scalars['String']>;
  rank?: InputMaybe<Scalars['String']>;
  solutions?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type UpdateTeamInput = {
  id: Scalars['ID'];
  memberIds?: InputMaybe<Array<Scalars['ID']>>;
  name: Scalars['String'];
};

export type UpdateUserInput = {
  agreementDuration?: InputMaybe<Scalars['Int']>;
  avatarUrl?: InputMaybe<Scalars['String']>;
  birthDate?: InputMaybe<Scalars['String']>;
  code?: InputMaybe<Scalars['String']>;
  communityWhatsApp?: InputMaybe<Scalars['JSON']>;
  email?: InputMaybe<Scalars['String']>;
  firebaseUid?: InputMaybe<Scalars['String']>;
  forumStandards?: InputMaybe<Scalars['Boolean']>;
  gitHubUsername?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  lastName?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  proUpdatedAt?: InputMaybe<Scalars['DateTime']>;
  roles?: InputMaybe<Array<UserRole>>;
  shouldAcceptAgreement?: InputMaybe<Scalars['Boolean']>;
  unboxing?: InputMaybe<Scalars['Boolean']>;
  website?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type UpdateUserProfileInput = {
  birthDate?: InputMaybe<Scalars['String']>;
  cityOfResidence?: InputMaybe<Scalars['String']>;
  communityWhatsApp?: InputMaybe<Scalars['JSON']>;
  countryOfResidence?: InputMaybe<Scalars['String']>;
  employmentStatus?: InputMaybe<Scalars['String']>;
  gender?: InputMaybe<Scalars['String']>;
  gitHubProfileUrl?: InputMaybe<Scalars['String']>;
  languages?: InputMaybe<Array<LanguageInput>>;
  linkedInProfileUrl?: InputMaybe<Scalars['String']>;
  mainAddress?: InputMaybe<Scalars['String']>;
  mentoringLevel?: InputMaybe<Scalars['String']>;
  occupation?: InputMaybe<Scalars['String']>;
  regionOfResidence?: InputMaybe<Scalars['String']>;
  secondaryAddress?: InputMaybe<Scalars['String']>;
  userId: Scalars['ID'];
  vulnerablePopulation?: InputMaybe<Scalars['String']>;
  zipCode?: InputMaybe<Scalars['String']>;
};

export type UpdateUserSettingsInput = {
  timeZone?: InputMaybe<Scalars['String']>;
  userId: Scalars['ID'];
};

export type UpdatedNotificationStatusInput = {
  recordId?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
};

export type UpsertProductRatingInput = {
  productId: Scalars['ID'];
  productType: Scalars['String'];
  rating: Scalars['Int'];
  review?: InputMaybe<Scalars['String']>;
  userId: Scalars['ID'];
};

export type User = {
  __typename?: 'User';
  agreementDuration?: Maybe<Scalars['Int']>;
  assignedDeliverables: Array<Deliverable>;
  avatarUrl?: Maybe<Scalars['String']>;
  badges?: Maybe<Array<Badge>>;
  birthDate?: Maybe<Scalars['String']>;
  code: Scalars['String'];
  cohorts?: Maybe<Array<Cohort>>;
  cohortsWithRole?: Maybe<Array<CohortWithRole>>;
  coins?: Maybe<Coins>;
  communityWhatsApp?: Maybe<Scalars['JSON']>;
  countryData?: Maybe<UserCountryData>;
  courses?: Maybe<Array<Course>>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  emailVerified?: Maybe<Scalars['Boolean']>;
  enrollmentInCohort?: Maybe<Enrollment>;
  experience?: Maybe<Experience>;
  firebaseUid?: Maybe<Scalars['String']>;
  forumStandards?: Maybe<Scalars['Boolean']>;
  gitHubUsername?: Maybe<Scalars['String']>;
  grade?: Maybe<Grade>;
  id: Scalars['ID'];
  id_mysql?: Maybe<Scalars['String']>;
  invites: Array<Invite>;
  isBanned?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  notifications: Array<Notification>;
  notificationsCount?: Maybe<Scalars['Int']>;
  orders: Array<Maybe<Order>>;
  phone?: Maybe<Scalars['String']>;
  proUpdatedAt?: Maybe<Scalars['DateTime']>;
  profile?: Maybe<UserProfile>;
  progress?: Maybe<Progress>;
  referrerCode?: Maybe<Scalars['String']>;
  roles: Array<UserRole>;
  salesforceID?: Maybe<Scalars['String']>;
  school: School;
  secretForSalesforce?: Maybe<Scalars['String']>;
  shouldAcceptAgreement?: Maybe<Scalars['Boolean']>;
  teams: Array<Team>;
  unboxing?: Maybe<Scalars['Boolean']>;
  website?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type UserAssignedDeliverablesArgs = {
  cohortId: Scalars['ID'];
  interactiveContentId?: InputMaybe<Scalars['ID']>;
  ownerId?: InputMaybe<Scalars['ID']>;
  userIds?: InputMaybe<Array<Scalars['ID']>>;
};


export type UserBadgesArgs = {
  badgesIds?: InputMaybe<Array<Scalars['ID']>>;
};


export type UserCohortsArgs = {
  courseId?: InputMaybe<Scalars['ID']>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  programId?: InputMaybe<Scalars['ID']>;
};


export type UserCohortsWithRoleArgs = {
  courseId?: InputMaybe<Scalars['ID']>;
  includeBlocked?: InputMaybe<Scalars['Boolean']>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type UserEnrollmentInCohortArgs = {
  cohortId: Scalars['ID'];
};


export type UserGradeArgs = {
  cohortId: Scalars['ID'];
  entity: EntityName;
  entityId: Scalars['ID'];
};


export type UserNotificationsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  userNotificationFilter?: InputMaybe<UserNotificationFilter>;
};


export type UserNotificationsCountArgs = {
  userNotificationFilter?: InputMaybe<UserNotificationFilter>;
};


export type UserOrdersArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type UserProgressArgs = {
  cohortId: Scalars['ID'];
  entity: EntityName;
  entityId: Scalars['ID'];
};


export type UserTeamsArgs = {
  teamSearchInput?: InputMaybe<EntitySearchInput>;
};

export type UserBadge2 = {
  __typename?: 'UserBadge2';
  badgeId: Scalars['ID'];
  userId: Scalars['ID'];
};

export type UserConnection = {
  __typename?: 'UserConnection';
  totalCount: Scalars['Int'];
  users: Array<User>;
};

export type UserCountryData = {
  __typename?: 'UserCountryData';
  isoAlpha3?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  phoneCode?: Maybe<Scalars['String']>;
};

export type UserIdGamificationInput = {
  userId?: InputMaybe<Scalars['ID']>;
};

export type UserNotificationFilter = {
  channel?: InputMaybe<NotificationChannel>;
  statuses?: InputMaybe<Array<InputMaybe<NotificationStatus>>>;
};

export type UserProfile = {
  __typename?: 'UserProfile';
  cityOfResidence?: Maybe<Scalars['String']>;
  communityWhatsApp?: Maybe<Scalars['JSON']>;
  countryOfResidence?: Maybe<Scalars['String']>;
  employmentStatus?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['String']>;
  gitHubProfileUrl?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  languages?: Maybe<Array<Language>>;
  linkedInProfileUrl?: Maybe<Scalars['String']>;
  mainAddress?: Maybe<Scalars['String']>;
  mentoringLevel?: Maybe<Scalars['String']>;
  occupation?: Maybe<Scalars['String']>;
  regionOfResidence?: Maybe<Scalars['String']>;
  secondaryAddress?: Maybe<Scalars['String']>;
  vulnerablePopulation?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
};

export enum UserRole {
  Academic = 'ACADEMIC',
  Admin = 'ADMIN',
  AdvancedBootcampUser = 'ADVANCED_BOOTCAMP_USER',
  BasicSubscriber = 'BASIC_SUBSCRIBER',
  BootcampUser = 'BOOTCAMP_USER',
  Community = 'COMMUNITY',
  Consultants = 'CONSULTANTS',
  Editor = 'EDITOR',
  Free = 'FREE',
  Guest = 'GUEST',
  Integration = 'INTEGRATION',
  Marketplace = 'MARKETPLACE',
  Mentor = 'MENTOR',
  MentorTeamMember = 'MENTOR_TEAM_MEMBER',
  MigratedUser = 'MIGRATED_USER',
  Moderator = 'MODERATOR',
  OldUser = 'OLD_USER',
  PremiumSubscriber = 'PREMIUM_SUBSCRIBER',
  Pro = 'PRO',
  Products = 'PRODUCTS',
  SchoolAdmin = 'SCHOOL_ADMIN',
  Solutions = 'SOLUTIONS',
  Student = 'STUDENT',
  Tutor = 'TUTOR'
}

export type UserSettings = {
  __typename?: 'UserSettings';
  timeZone?: Maybe<Scalars['String']>;
  userId: Scalars['ID'];
};

export type UserSyncSummary = {
  __typename?: 'UserSyncSummary';
  errors?: Maybe<Array<Maybe<Scalars['String']>>>;
  proUpdated?: Maybe<Scalars['Boolean']>;
  salesforceID?: Maybe<Scalars['String']>;
  status: Scalars['String'];
  updates?: Maybe<Array<Maybe<Scalars['String']>>>;
  userId: Scalars['ID'];
};

export type UsersReconcileItem = {
  __typename?: 'UsersReconcileItem';
  email: Scalars['String'];
  salesforceID: Scalars['String'];
  status?: Maybe<Scalars['String']>;
  statusMessage?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};

export enum ValidateBootcampEnrrollments {
  Blockenroll = 'BLOCKENROLL',
  Confirmblockenroll = 'CONFIRMBLOCKENROLL',
  Crosseddates = 'CROSSEDDATES',
  OutdaysAfter = 'OUTDAYS_AFTER',
  OutdaysBefore = 'OUTDAYS_BEFORE',
  Success = 'SUCCESS'
}

export type ValidateUserEnrollmentInput = {
  bootcampReleaseId?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
};

export type ValidateUserEnrollmentOutput = {
  __typename?: 'ValidateUserEnrollmentOutput';
  type?: Maybe<ValidateBootcampEnrrollments>;
  validate?: Maybe<Scalars['Boolean']>;
};

export type Week = {
  __typename?: 'Week';
  hasProducts: Scalars['Boolean'];
  startDate: Scalars['String'];
};

export type WhatsAppComunity = {
  __typename?: 'WhatsAppComunity';
  _id: Scalars['ID'];
  cohortId?: Maybe<Scalars['String']>;
  communityText?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  redirectUrl?: Maybe<Scalars['String']>;
  userSettingKey?: Maybe<Scalars['String']>;
};

export type WhatsAppComunityFieldInput = {
  field?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

export type WorkReport = {
  __typename?: 'WorkReport';
  category?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  start: Scalars['DateTime'];
  totalHours: Scalars['Float'];
  userId: Scalars['ID'];
};

export type CommunityWhatsAppGroup = {
  __typename?: 'communityWhatsAppGroup';
  amazon?: Maybe<Scalars['Boolean']>;
  amazon_arbitrage?: Maybe<Scalars['Boolean']>;
  amazon_kdp_diploma?: Maybe<Scalars['Boolean']>;
  amazon_wholesale_diploma?: Maybe<Scalars['Boolean']>;
  dropshipping?: Maybe<Scalars['Boolean']>;
  dropshipping_social_media?: Maybe<Scalars['Boolean']>;
  imports_chinese_ecommerce?: Maybe<Scalars['Boolean']>;
  meli?: Maybe<Scalars['Boolean']>;
  sell_anything_with_chat?: Maybe<Scalars['Boolean']>;
};

export type CommunityWhatsAppGroupInput = {
  data?: InputMaybe<Scalars['JSON']>;
};

export type ContactInfoHighTicketMentorship = {
  __typename?: 'contactInfoHighTicketMentorship';
  email: Scalars['String'];
  phoneNumber: Scalars['String'];
};

export type ContactInfoHighTicketMentorshipInput = {
  email: Scalars['String'];
  phoneNumber: Scalars['String'];
};

export type CreateHighTicketMentorshipInput = {
  avatar?: InputMaybe<Scalars['String']>;
  brandIds: Array<InputMaybe<Scalars['ID']>>;
  categoryId?: InputMaybe<Scalars['ID']>;
  description?: InputMaybe<Scalars['String']>;
  duration?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  extraBenefits?: InputMaybe<ExtraBenefitsHighTicketMentorshipInput>;
  faq: Array<InputMaybe<FaqInput>>;
  groupSessions: Array<InputMaybe<GroupSessionInput>>;
  isPublished?: InputMaybe<Scalars['Boolean']>;
  landingUrl?: InputMaybe<Scalars['String']>;
  levelIds: Array<InputMaybe<Scalars['ID']>>;
  logo?: InputMaybe<Scalars['String']>;
  mentor?: InputMaybe<MentorHighTicketMentorshipInput>;
  oneOnOneBenefits: Array<InputMaybe<Scalars['String']>>;
  oneToOneSessions?: InputMaybe<Array<InputMaybe<OneToOneSessionInput>>>;
  order?: InputMaybe<Scalars['Int']>;
  parentId?: InputMaybe<Scalars['ID']>;
  qrCodeLink?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  subCategoryIds: Array<InputMaybe<Scalars['ID']>>;
  thumbnail?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  type: EntityName;
  whatsAppLink?: InputMaybe<Scalars['String']>;
  workTeam?: InputMaybe<Array<InputMaybe<WorkTeamHighTicketMentorshipInput>>>;
};

export type ExtraBenefitsHighTicketMentorship = {
  __typename?: 'extraBenefitsHighTicketMentorship';
  exclusiveSupport?: Maybe<Scalars['String']>;
  generalClasses?: Maybe<Scalars['String']>;
  privateGroup?: Maybe<Scalars['String']>;
  vipGroup?: Maybe<Scalars['String']>;
  weHopeToTeachYou?: Maybe<Scalars['String']>;
};

export type ExtraBenefitsHighTicketMentorshipInput = {
  exclusiveSupport?: InputMaybe<Scalars['String']>;
  generalClasses?: InputMaybe<Scalars['String']>;
  privateGroup?: InputMaybe<Scalars['String']>;
  vipGroup?: InputMaybe<Scalars['String']>;
  weHopeToTeachYou?: InputMaybe<Scalars['String']>;
};

export enum LiveSessionType {
  Livesession = 'LIVESESSION',
  Masterclass = 'MASTERCLASS',
  OnboardingSession = 'ONBOARDING_SESSION',
  QuestionsAndAnswers = 'QUESTIONS_AND_ANSWERS',
  SalesSession = 'SALES_SESSION',
  Workshop = 'WORKSHOP'
}

export type MentorHighTicketMentorship = {
  __typename?: 'mentorHighTicketMentorship';
  avatar?: Maybe<Scalars['String']>;
  contactInfo?: Maybe<ContactInfoHighTicketMentorship>;
  description: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  previousExperience: Array<Maybe<Scalars['String']>>;
  socialNetworks?: Maybe<SocialNetworksHighTicketMentorship>;
  title?: Maybe<Scalars['String']>;
  userId: Scalars['ID'];
};

export type MentorHighTicketMentorshipInput = {
  contactInfo?: InputMaybe<ContactInfoHighTicketMentorshipInput>;
  description: Scalars['String'];
  previousExperience: Array<InputMaybe<Scalars['String']>>;
  socialNetworks?: InputMaybe<SocialNetworksHighTicketMentorshipInput>;
  title: Scalars['String'];
  userId: Scalars['ID'];
};

export type MentorPlaylist = {
  __typename?: 'mentorPlaylist';
  link?: Maybe<Scalars['String']>;
  mentorName?: Maybe<Scalars['String']>;
};

export type MentorPlaylistInput = {
  link?: InputMaybe<Scalars['String']>;
  mentorName?: InputMaybe<Scalars['String']>;
};

export type SocialNetworksHighTicketMentorship = {
  __typename?: 'socialNetworksHighTicketMentorship';
  facebookLink?: Maybe<Scalars['String']>;
  instagramLink?: Maybe<Scalars['String']>;
  linkedInLink?: Maybe<Scalars['String']>;
};

export type SocialNetworksHighTicketMentorshipInput = {
  facebookLink?: InputMaybe<Scalars['String']>;
  instagramLink?: InputMaybe<Scalars['String']>;
  linkedInLink?: InputMaybe<Scalars['String']>;
};

export type TalentHighTicketMentorship = {
  __typename?: 'talentHighTicketMentorship';
  content: Scalars['String'];
  title: Scalars['String'];
};

export type TalentHighTicketMentorshipInput = {
  content: Scalars['String'];
  title: Scalars['String'];
};

export type UpdateHighTicketMentorshipInput = {
  avatar?: InputMaybe<Scalars['String']>;
  brandIds: Array<InputMaybe<Scalars['ID']>>;
  categoryId?: InputMaybe<Scalars['ID']>;
  description?: InputMaybe<Scalars['String']>;
  duration?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  extraBenefits?: InputMaybe<ExtraBenefitsHighTicketMentorshipInput>;
  faq: Array<InputMaybe<FaqInput>>;
  groupSessions: Array<InputMaybe<GroupSessionInput>>;
  id: Scalars['ID'];
  isPublished?: InputMaybe<Scalars['Boolean']>;
  landingUrl?: InputMaybe<Scalars['String']>;
  levelIds: Array<InputMaybe<Scalars['ID']>>;
  logo?: InputMaybe<Scalars['String']>;
  mentor?: InputMaybe<MentorHighTicketMentorshipInput>;
  oneOnOneBenefits: Array<InputMaybe<Scalars['String']>>;
  oneToOneSessions?: InputMaybe<Array<InputMaybe<OneToOneSessionInput>>>;
  order?: InputMaybe<Scalars['Int']>;
  parentId?: InputMaybe<Scalars['ID']>;
  qrCodeLink?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  subCategoryIds: Array<InputMaybe<Scalars['ID']>>;
  thumbnail?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  type: EntityName;
  whatsAppLink?: InputMaybe<Scalars['String']>;
  workTeam?: InputMaybe<Array<InputMaybe<WorkTeamHighTicketMentorshipInput>>>;
};

export type WorkTeamHighTicketMentorship = {
  __typename?: 'workTeamHighTicketMentorship';
  avatar?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  talents?: Maybe<Array<Maybe<TalentHighTicketMentorship>>>;
  userId: Scalars['ID'];
};

export type WorkTeamHighTicketMentorshipInput = {
  description: Scalars['String'];
  talents?: InputMaybe<Array<InputMaybe<TalentHighTicketMentorshipInput>>>;
  userId: Scalars['ID'];
};
