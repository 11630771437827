import { useContext } from "react";
import { BootcampBannerEntry, Program } from "@/schemaTypes";
import { learnContext } from "../../providers/learnProvider";

export const useUniqueCategories = () => {
  const { brandData, selectedBrand } = useContext(learnContext);
  const normaliceBrand = selectedBrand?.name?.toLowerCase() ?? "";
  if (!brandData) return [];

  const { bootcamps, liveSessions, programs } = brandData;

  const bootcampsSubCategories = [
    ...(bootcamps?.cta ?? []),
    ...(bootcamps?.myBootcamps ?? []),
    ...(bootcamps?.completed ?? []),
  ].flatMap(
    (bootcamp: BootcampBannerEntry) =>
      bootcamp?.parent?.subCategory?.map((category) => category?.name) ?? []
  );

  const programsSubCategories =
    programs?.flatMap((program: Program) =>
      program.subCategory?.map((category) => category?.name)
    ) ?? [];

  const liveSessionsSubCategories = [
    ...(liveSessions?.next ?? []),
    ...(liveSessions?.scheduled ?? []),
  ].flatMap(
    (session) => session?.subCategory?.map((category) => category?.name) ?? []
  );

  const uniqueCategories = Array.from(
    new Set([
      ...bootcampsSubCategories,
      ...programsSubCategories,
      ...liveSessionsSubCategories,
    ])
  ).filter((category) => category !== undefined);

  const brandCategoriesMap: Record<string, string[]> = {
    amazon: [
      "Marca Privada",
      "Venta de Libros",
      "Venta al Por Mayor",
      "Arbitraje",
      "Tienda Propia",
    ],
    dropshipping: ["Redes Sociales", "Tienda Propia"],
    "mercado libre": ["Marketplace"],
  };

  const allowedCategories = brandCategoriesMap[normaliceBrand] || [];

  return uniqueCategories.filter((category) =>
    allowedCategories.includes(category ?? "")
  );
};
