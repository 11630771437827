/**
 * @license
 * Copyright 2023 Ada School
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { useQuery } from "@apollo/client";
import { BoxProps } from "@chakra-ui/react";
// import ModalInteractiveContent from "../InteractiveContent/ModalInteractiveContent";
import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { AppRoute } from "@/AppRoute";
import { CourseCard as CardCourse } from "@/components/Card/Course";
import { GetCourseProgressDocument } from "@/components/ProgressDashboard/graphql/getCourseProgress.generated";
import { getCoursePathFromType } from "@/utils/courses";

import { GetCourseCohortsDocument } from "@/graphql/getCourseCohorts.generated";
import useGA4Event, {
  GA4EventAction,
  GA4EventCategory,
  GA4EventLabel,
} from "@/hooks/useGA4Event";
import { Category, EntityName } from "@/schemaTypes";
import { TestIds } from "@/utils/testIds";
import type { CourseCardFragment } from "./graphql/courseCardFragment.generated";

type CourseWithCohort = CourseCardFragment & {
  cohort?: string;
};

interface CourseCardProps {
  isNewUi?: boolean;
  children?: React.ReactNode;
  cohortId?: string;
  course: CourseCardFragment;
  dragType?: string;
  isDraggable?: boolean;
  isSelected?: boolean;
  onClick?: (course: CourseWithCohort, e: React.MouseEvent) => void;
  onDelete?: (course: CourseCardFragment) => void;
  showVisibility?: boolean;
}

const CourseCard = ({
  isNewUi,
  children,
  cohortId,
  course,
  onClick,
  onDelete,
  showVisibility = false,
  ...props
}: CourseCardProps & Omit<BoxProps, "onClick" | "as">): JSX.Element => {
  const { id, imageUrl, status, translatedName, visibility } = course;
  const location = useLocation();
  const pathSegments = location.pathname.split("/");
  const firstSegment = "/" + pathSegments[1];
  const firstPathTargetForCard = getCoursePathFromType(
    course.courseType ?? undefined
  );
  const navigate = useNavigate();
  const isNew = course.tags?.some((tag) => tag.toLowerCase() === "new");
  const [currentCohortId, setCurrentCohortId] = useState("0");
  const { data: cohortForCurrentCard } = useQuery(GetCourseCohortsDocument, {
    variables: { courseId: id },
    skip: !id,
  });
  const { sendEvent } = useGA4Event();

  useEffect(() => {
    setCurrentCohortId(cohortForCurrentCard?.course?.cohorts[0]?.id ?? "");
  }, [cohortForCurrentCard]);

  cohortId = currentCohortId;

  // const [
  //   fetchCourseProgress,
  //   { data: userCourseData, loading: loadingCourses },
  // ] = useLazyQuery(GetCourseProgressDocument, {
  //   fetchPolicy: "no-cache",
  // });
  // useEffect(() => {
  //   if (id && cohortId !== "0" && user) {
  //     fetchCourseProgress({
  //       variables: { courseId: id, cohortId },
  //     });
  //   }
  // }, [id, cohortId, fetchCourseProgress]);

  // let progress = user ? userCourseData?.cohort?.userProgress?.progress ?? 0 : 0;
  let progress = 0;
  progress = progress > 100 ? 100 : progress;

  const handleClick = (e: React.MouseEvent) => {
    if (course && cohortId) {
      const courseWithCohort = {
        ...course,
        cohort: cohortId,
      };
      onClick?.(courseWithCohort, e);

      if (progress === 0) {
        if (course.courseType === EntityName.AdvancedCourse) {
          sendEvent(
            {
              action: `${GA4EventAction.StartAdvancedCourseCard}`,
              label: GA4EventLabel.StartAdvancedCourseCard,
              category: GA4EventCategory.AdvancedCourses,
            },
            {
              courseName: translatedName?.[0].value,
            }
          );
        } else if (course.courseType === EntityName.Course) {
          sendEvent(
            {
              action: `${GA4EventAction.StartCourseCard}`,
              label: GA4EventLabel.StartCourseCard,
              category: GA4EventCategory.Courses,
            },
            {
              courseName: translatedName?.[0].value,
            }
          );
        }
      } else if (progress < 100) {
        if (course.courseType === EntityName.AdvancedCourse) {
          sendEvent(
            {
              action: `${GA4EventAction.ContinueAdvancedCourseCard}`,
              label: GA4EventLabel.ContinueAdvancedCourseCard,
              category: GA4EventCategory.AdvancedCourses,
            },
            {
              courseName: translatedName?.[0].value,
            }
          );
        } else if (course.courseType === EntityName.Course) {
          sendEvent(
            {
              action: `${GA4EventAction.ContinueCourseCard}`,
              label: GA4EventLabel.ContinueCourseCard,
              category: GA4EventCategory.Courses,
            },
            {
              courseName: translatedName?.[0].value,
            }
          );
        }
      } else {
        if (course.courseType === EntityName.AdvancedCourse) {
          sendEvent(
            {
              action: `${GA4EventAction.RepeatAdvancedCourseCard}`,
              label: GA4EventLabel.RepeatAdvancedCourseCard,
              category: GA4EventCategory.AdvancedCourses,
            },
            {
              courseName: translatedName?.[0].value,
            }
          );
        } else if (course.courseType === EntityName.Course) {
          sendEvent(
            {
              action: `${GA4EventAction.RepeatCourseCard}`,
              label: GA4EventLabel.RepeatCourseCard,
              category: GA4EventCategory.Courses,
            },
            {
              courseName: translatedName?.[0].value,
            }
          );
        }
      }
    }
  };

  const handleDeleteClick = useCallback(() => {
    onDelete?.(course);
  }, [course, onDelete]);

  const handleEditClick = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      e.preventDefault();
      if (firstSegment !== firstPathTargetForCard) {
        navigate(`${AppRoute.Courses}/${id}/edit`);
      } else {
        navigate(`${firstPathTargetForCard}/${id}/edit`);
      }
    },
    [course.courseType, id, firstSegment, navigate]
  );

  return (
    <CardCourse
      isNewUi={isNewUi}
      data_cy={TestIds.CourseCard}
      course={{
        isNew,
        name: translatedName?.[0].value ?? "",
        progress: progress,
        status,
        type: course.courseType ?? undefined,
        visibility,
        brand: (course.brand as Category[]) ?? [],
        subCategory: (course.subCategory as Category[]) ?? [],
        level: (course.level as Category[]) ?? [],
      }}
      img={imageUrl ? { src: imageUrl } : undefined}
      onClick={handleClick}
      onDelete={handleDeleteClick}
      onEdit={handleEditClick}
      showVisibility={showVisibility}
      {...props}
    >
      {children}
    </CardCourse>
  );
};

export { CourseCard };
export type { CourseCardProps };
