import { Ico } from "@/assets/icons";
import { Button } from "@/components/Button";
import {
  Flex,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import React, { useContext } from "react";
import FilterItem from "./FilterItem";
import { MentorFilterContext } from "../../providers/mentorFilterProvider";
import EmptyFilter from "./EmptyFilter";
interface FilterModalProps {
  onClose: () => void;
  isOpen: boolean;
}

const FilterModal: React.FC<FilterModalProps> = ({ onClose, isOpen }) => {
  const mentorFilterContext = useContext(MentorFilterContext);

  if (!mentorFilterContext) return null;

  const {
    mentorsForFilter,
    inputValue,
    matchingMentors,
    setInputValue,
    setMatchingMentors,
    setSelectedMentor,
    setPreselectedMentor,
    preselectedMentor,
    selectedMentor,
    debouncedSearch,
  } = mentorFilterContext;

  return (
    <Modal
      scrollBehavior="inside"
      onClose={onClose}
      size={"full"}
      isOpen={isOpen}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader
          px={"16px"}
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          Mentores
          <Ico.Close
            height={"24px"}
            width={"24px"}
            fontSize={"24px"}
            fill={"#1E1E23"}
            cursor={"pointer"}
            onClick={() => {
              onClose();
              setPreselectedMentor(undefined);
            }}
          />
        </ModalHeader>
        <ModalBody px={"16px"} pt={0}>
          <Flex
            flexDir={"column"}
            gap={"4px"}
            maxW={"100%"}
            h={"64px"}
            position={"relative"}
          >
            <Text variant={"feedback"}>Filtrar por mentor:</Text>

            <Flex
              align={"center"}
              flexDir={"row"}
              border={"1px solid"}
              borderColor={"neutral.100"}
              borderRadius={"6px"}
              gap={"8px"}
              py={"4px"}
              pl={"8px"}
              pr={"16px"}
              maxH={"48px"}
              _focusWithin={{
                borderColor: "primary.200",
                boxShadow: "-1px -1px 10px 0px rgba(100, 100, 100, 0.16)",
              }}
              _hover={{
                borderColor: "primary.200",
                boxShadow: "-1px -1px 10px 0px rgba(100, 100, 100, 0.16)",
              }}
            >
              <Ico.User fontSize={"24px"} fill="#8F8F91" />

              <Input
                px={0}
                border={"none"}
                placeholder="Buscar mentor"
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                outline={"none"}
                _active={{
                  border: "none",
                  outline: "none",
                  boxShadow: "none",
                }}
                _focus={{
                  border: "none",
                  outline: "none",
                  boxShadow: "none",
                }}
                _selected={{
                  border: "none",
                  outline: "none",
                  boxShadow: "none",
                }}
              />

              {inputValue ? (
                <Ico.Close
                  height={"24px"}
                  width={"24px"}
                  fontSize={"24px"}
                  fill={"#1F58F3"}
                  cursor={"pointer"}
                  onClick={() => {
                    setMatchingMentors(undefined);
                    setSelectedMentor(undefined);
                    setInputValue("");
                  }}
                />
              ) : (
                <Ico.MagnifyingGlass fontSize={"24px"} fill="#1F58F3" />
              )}
            </Flex>

            <Flex
              bg={"#fff"}
              top={"115%"}
              flexDir={"column"}
              position={"absolute"}
              w={"100%"}
            >
              {matchingMentors?.length &&
                matchingMentors.map((mentor) => (
                  <FilterItem
                    key={mentor.id}
                    mentor={mentor}
                    closeFilterModal={onClose}
                    isPreselected={preselectedMentor?.id === mentor.id}
                    onClick={() => {
                      setPreselectedMentor(mentor);
                    }}
                  />
                ))}

              {inputValue &&
                debouncedSearch &&
                !selectedMentor &&
                !matchingMentors?.length && <EmptyFilter />}

              {!inputValue &&
                [...(mentorsForFilter?.getLearnFilterMentors || [])]
                  .sort((a, b) =>
                    `${a.name} ${a.lastName}`.localeCompare(
                      `${b.name} ${b.lastName}`
                    )
                  )
                  .map((mentor) => (
                    <FilterItem
                      key={mentor.id}
                      mentor={mentor}
                      closeFilterModal={onClose}
                      onClick={() => {
                        setPreselectedMentor(mentor);
                      }}
                      isPreselected={preselectedMentor?.id === mentor.id}
                    />
                  ))}
            </Flex>
          </Flex>
        </ModalBody>
        <ModalFooter
          borderTop={"1px solid"}
          borderColor={"neutral.100"}
          gap={"32px"}
          display={"flex"}
          justifyContent={"space-between"}
        >
          <Button w={"140px"} variant={"secondary"} onClick={onClose}>
            Cancelar
          </Button>
          <Button
            w={"140px"}
            variant={"primary"}
            onClick={() => {
              setSelectedMentor(preselectedMentor);
              setPreselectedMentor(undefined);
              onClose();
            }}
          >
            Filtrar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default FilterModal;
