import * as Types from '../../../../../schemaTypes';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type GetMentorsForFilterQueryVariables = Types.Exact<{
  mentorsIds: Array<Types.Scalars['String']> | Types.Scalars['String'];
}>;


export type GetMentorsForFilterQuery = { __typename?: 'Query', getLearnFilterMentors: Array<{ __typename?: 'MentorForFilter', id: string, name: string, lastName?: string | null, avatarUrl?: string | null }> };


export const GetMentorsForFilterDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetMentorsForFilter"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"mentorsIds"}},"type":{"kind":"NonNullType","type":{"kind":"ListType","type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"getLearnFilterMentors"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"mentorsIds"},"value":{"kind":"Variable","name":{"kind":"Name","value":"mentorsIds"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"lastName"}},{"kind":"Field","name":{"kind":"Name","value":"avatarUrl"}}]}}]}}]} as unknown as DocumentNode<GetMentorsForFilterQuery, GetMentorsForFilterQueryVariables>;