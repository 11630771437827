import { Ico } from "@/assets/icons";
import { Flex, Text, useBreakpointValue } from "@chakra-ui/react";
import { useState, useEffect } from "react";

const EmptyFilter = () => {
  const isMobile = useBreakpointValue({ base: true, lg: false });
  const [shouldShow, setShouldShow] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShouldShow(true);
    }, 500);

    return () => clearTimeout(timer);
  }, []);

  if (!shouldShow) return null;

  return (
    <Flex
      gap={"16px"}
      align={"center"}
      justify={"center"}
      flexDir={"column"}
      my={isMobile ? "40px" : "32px"}
      w={isMobile ? "100%" : "max-content"}
    >
      <Flex
        gap={"16px"}
        align={"center"}
        justify={"center"}
        flexDir={"column"}
        my={isMobile ? "40px" : "32px"}
        w={isMobile ? "100%" : "max-content"}
      >
        <Flex
          w={"40px"}
          h={"40px"}
          bg={"neutral.50"}
          borderRadius={"50%"}
          justify={"center"}
          align={"center"}
        >
          <Ico.Search03 height={"24px"} width={"24px"} />
        </Flex>

        <Flex>
          <Text textAlign={"center"} variant={"placeholder"} maxW={"280px"}>
            Parece que no hay mentores con ese nombre. ¿Intentas con otro?
          </Text>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default EmptyFilter;
