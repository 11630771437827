import { useLazyQuery } from "@apollo/client";
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import { GetMentorsForFilterDocument } from "./graphql/getMentorsForFilter.generated";
import {
  filterBootcampReleaseByField,
  filterByCategory,
  filterByPlainMentor,
} from "./utils";
import { Program } from "@/schemaTypes";
import {
  Bootcamps,
  LiveSessions,
  MentorFilterContext as IMentorFilterContext,
  MentorshipCard,
} from "./types";
import { learnContext } from "./learnProvider";
import { MentorForFilter } from "../components/mentorFilter";
import { useDebouncedValue } from "@/hooks/useDebouncedValue";
import { useDisclosure } from "@chakra-ui/react";

export const MentorFilterContext = createContext<IMentorFilterContext>(
  {} as IMentorFilterContext
);

export const MentorFilterProvider = ({ children }: { children: ReactNode }) => {
  const {
    isOpen: isFilterModalOpen,
    onClose: closeFilterModal,
    onOpen: openFilterModal,
  } = useDisclosure();

  const {
    selectedBrand,
    brandData,
    allSessions,
    setLiveSessions,
    allPrograms,
    setPrograms,
    allBootcamps,
    setBootcamps,
    allMentorships,
    setMentorships,
  } = useContext(learnContext);

  const [allMentorsIds, setAllMentorsIds] = useState<string[]>([]);

  useEffect(() => {
    const getMentorsIds = () => {
      const sessionsMentorsIds = [
        ...allSessions.next.map((session) => session.mentorsIds),
        ...allSessions.scheduled.map((session) => session.mentorsIds),
      ].flat();

      const programsMentorsIds = allPrograms
        .map((program) => program.mentorsIds)
        .flat();

      const bootcampsMentorsIds = [
        ...allBootcamps.completed,
        ...allBootcamps.cta,
        ...allBootcamps.myBootcamps,
      ]
        .map((bootcamp) => bootcamp.release?.mentorsIds)
        .flat();

      const mentorshipsMentorsIds = allMentorships
        .map((mentorship) => mentorship.mentorsIds)
        .flat();

      return [
        ...new Set(
          [
            ...sessionsMentorsIds,
            ...programsMentorsIds,
            ...bootcampsMentorsIds,
            ...mentorshipsMentorsIds,
          ].filter((id): id is string => id !== undefined)
        ),
      ];
    };

    setAllMentorsIds(getMentorsIds());
  }, [allSessions, allPrograms, allBootcamps, allMentorships]);

  const [
    fetchMentors,
    { data: mentorsForFilter, loading: mentorsForFilterLoading },
  ] = useLazyQuery(GetMentorsForFilterDocument);

  useEffect(() => {
    fetchMentors({ variables: { mentorsIds: allMentorsIds } });
  }, [allMentorsIds]);

  const [inputValue, setInputValue] = useState("");
  const debouncedSearch = useDebouncedValue(inputValue, 300);
  const [preselectedMentor, setPreselectedMentor] = useState<MentorForFilter>();
  const [matchingMentors, setMatchingMentors] = useState<
    MentorForFilter[] | undefined
  >(undefined);
  const [selectedMentor, setSelectedMentor] = useState<
    MentorForFilter | undefined
  >();

  useEffect(() => {
    const dataToFilter = {
      liveSessions: selectedBrand ? brandData?.liveSessions : allSessions,
      programs: selectedBrand ? brandData?.programs : allPrograms,
      bootcamps: selectedBrand ? brandData?.bootcamps : allBootcamps,
      mentorships: selectedBrand ? brandData?.mentorships : allMentorships,
    };

    if (selectedMentor) {
      setInputValue(`${selectedMentor.name} ${selectedMentor.lastName}`);
      setMatchingMentors(undefined);
    }

    if (!selectedMentor) {
      setBootcamps(dataToFilter.bootcamps as Bootcamps);
      setPrograms(dataToFilter.programs as Program[]);
      setLiveSessions(dataToFilter.liveSessions as LiveSessions);
      setMentorships(dataToFilter.mentorships as MentorshipCard[]);
      return;
    }

    const filteredProgramsByMentorsIds = filterByCategory(
      dataToFilter.programs as Program[],
      "mentorsIds",
      selectedMentor.id.toString(),
      true
    );

    const filteredProgramsByPlainMentors = filterByPlainMentor(
      dataToFilter.programs as Program[],
      selectedMentor.name,
      selectedMentor.lastName ?? ""
    );

    const filteredPrograms = [
      ...new Set([
        ...filteredProgramsByMentorsIds,
        ...filteredProgramsByPlainMentors,
      ]),
    ] as Program[];

    const filteredSessions = {
      next: filterByCategory(
        dataToFilter.liveSessions?.next ?? [],
        "mentorsIds",
        selectedMentor.id.toString(),
        true
      ),
      scheduled: filterByCategory(
        dataToFilter.liveSessions?.scheduled ?? [],
        "mentorsIds",
        selectedMentor.id.toString(),
        true
      ),
    };

    const filteredBootcampsByMentorsId = {
      cta: filterBootcampReleaseByField(
        dataToFilter.bootcamps?.cta ?? [],
        "mentorsIds",
        selectedMentor.id.toString()
      ),
      myBootcamps: filterBootcampReleaseByField(
        dataToFilter.bootcamps?.myBootcamps ?? [],
        "mentorsIds",
        selectedMentor.id.toString()
      ),
      completed: filterBootcampReleaseByField(
        dataToFilter.bootcamps?.completed ?? [],
        "mentorsIds",
        selectedMentor.id.toString()
      ),
    };

    const filteredBootcampsByPlainMentors = {
      cta: filterByPlainMentor(
        dataToFilter.bootcamps?.cta ?? [],
        selectedMentor.name,
        selectedMentor.lastName ?? ""
      ),
      myBootcamps: filterByPlainMentor(
        dataToFilter.bootcamps?.myBootcamps ?? [],
        selectedMentor.name,
        selectedMentor.lastName ?? ""
      ),
      completed: filterByPlainMentor(
        dataToFilter.bootcamps?.completed ?? [],
        selectedMentor.name,
        selectedMentor.lastName ?? ""
      ),
    };

    const filteredBootcamps = {
      cta: [
        ...new Set([
          ...filteredBootcampsByMentorsId.cta,
          ...filteredBootcampsByPlainMentors.cta,
        ]),
      ],
      myBootcamps: [
        ...new Set([
          ...filteredBootcampsByMentorsId.myBootcamps,
          ...filteredBootcampsByPlainMentors.myBootcamps,
        ]),
      ],
      completed: [
        ...new Set([
          ...filteredBootcampsByMentorsId.completed,
          ...filteredBootcampsByPlainMentors.completed,
        ]),
      ],
    } as Bootcamps;

    const filteredMentorships = filterByCategory(
      dataToFilter.mentorships as MentorshipCard[],
      "mentorsIds",
      selectedMentor.id.toString(),
      true
    );

    setLiveSessions(filteredSessions);
    setPrograms(filteredPrograms);
    setBootcamps(filteredBootcamps);
    setMentorships(filteredMentorships);
  }, [selectedMentor]);

  useEffect(() => {
    if (!debouncedSearch) return setMatchingMentors(undefined);

    if (inputValue === `${selectedMentor?.name} ${selectedMentor?.lastName}`) {
      return setMatchingMentors(undefined);
    }

    const filteredMentors =
      mentorsForFilter?.getLearnFilterMentors
        .filter((mentor) =>
          `${mentor.name} ${mentor.lastName}`
            .toLowerCase()
            .includes(debouncedSearch.toLowerCase())
        )
        .sort((a, b) =>
          `${a.name} ${a.lastName}`.localeCompare(`${b.name} ${b.lastName}`)
        )
        .slice(0, 6) || [];

    setMatchingMentors(filteredMentors.length ? filteredMentors : undefined);
  }, [debouncedSearch, mentorsForFilter]);

  return (
    <MentorFilterContext.Provider
      value={{
        mentorsForFilter,
        mentorsForFilterLoading,
        selectedMentor,
        setSelectedMentor,
        preselectedMentor,
        setPreselectedMentor,
        inputValue,
        setInputValue,
        debouncedSearch,
        matchingMentors,
        setMatchingMentors,
        isFilterModalOpen,
        openFilterModal,
        closeFilterModal,
      }}
    >
      {children}
    </MentorFilterContext.Provider>
  );
};

export default MentorFilterProvider;
