import { Ico } from "@/assets/icons";
import {
  Avatar,
  Flex,
  Input,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import { useContext } from "react";
import { GetMentorsForFilterQuery } from "../../providers/graphql/getMentorsForFilter.generated";
import FilterItem from "./FilterItem";
import FilterModal from "./FilterModal";
import { MentorFilterContext } from "../../providers/mentorFilterProvider";
import EmptyFilter from "./EmptyFilter";

export type MentorForFilter =
  GetMentorsForFilterQuery["getLearnFilterMentors"][0];

const MentorFilter = () => {
  const isMobile = useBreakpointValue({ base: true, lg: false });

  const mentorFilterContext = useContext(MentorFilterContext);

  if (!mentorFilterContext) return null;

  const {
    selectedMentor,
    inputValue,
    matchingMentors,
    setInputValue,
    setMatchingMentors,
    setSelectedMentor,
    closeFilterModal,
    openFilterModal,
    isFilterModalOpen,
    debouncedSearch,
  } = mentorFilterContext;

  const handleFilterClick = () => {
    if (!isMobile) return;
    setTimeout(() => openFilterModal(), 100);
  };

  const handleResetFilter = () => {
    setInputValue("");
    setSelectedMentor(undefined);
  };

  return (
    <>
      <Flex
        flexDir={"column"}
        gap={"4px"}
        position={"relative"}
        mt={isMobile ? "24px" : "32px"}
        w={isMobile ? "100%" : "max-content"}
      >
        <Flex justify={"space-between"} w={"100%"}>
          <Text variant={isMobile ? "captions" : "feedback"} fontWeight={600}>
            Filtrar por mentor:
          </Text>

          {isMobile && selectedMentor && (
            <Text
              cursor={selectedMentor == undefined ? "not-allowed" : "pointer"}
              textDecoration={"underline"}
              fontSize={"12px"}
              w={"66px"}
              onClick={handleResetFilter}
            >
              Borrar filtro
            </Text>
          )}
        </Flex>

        <Flex
          w={"100%"}
          flexDir={"row"}
          position={"relative"}
          align={"center"}
          gap={"8px"}
        >
          <Flex
            h={isMobile ? "48px !important" : "40px"}
            w={isMobile ? "100%" : "auto"}
            align={"center"}
            flexDir={"row"}
            border={"1px solid"}
            borderColor={"neutral.100"}
            borderRadius={"6px"}
            gap={"8px"}
            py={"4px"}
            pl={"8px"}
            pr={"16px"}
            onTouchStart={handleFilterClick}
            onClick={handleFilterClick}
            _focusWithin={{
              borderColor: "primary.200",
              boxShadow: "-1px -1px 10px 0px rgba(100, 100, 100, 0.16)",
            }}
            _hover={{
              borderColor: "primary.200",
              boxShadow: "-1px -1px 10px 0px rgba(100, 100, 100, 0.16)",
            }}
          >
            {selectedMentor ? (
              <Avatar
                src={selectedMentor.avatarUrl ?? ""}
                w={"24px"}
                h={"24px"}
              />
            ) : (
              <Ico.User width={"24px"} height={"24px"} fill="#8F8F91" />
            )}

            <Input
              w={"100%"}
              px={0}
              border={"none"}
              outline={"none"}
              h={isMobile ? "48px !important" : "40px"}
              value={inputValue}
              readOnly={isMobile}
              placeholder="Buscar mentor"
              fontWeight={inputValue && selectedMentor ? 600 : 400}
              onChange={(e) => setInputValue(e.target.value)}
              onFocus={(e) => isMobile && e.target.blur()}
              _active={{
                border: "none",
                outline: "none",
                boxShadow: "none",
              }}
              _focus={{
                border: "none",
                outline: "none",
                boxShadow: "none",
              }}
              _selected={{
                border: "none",
                outline: "none",
                boxShadow: "none",
              }}
            />

            {inputValue && (
              <Ico.Close
                fontSize={"24px"}
                fill={"#1F58F3"}
                cursor={"pointer"}
                onClick={() => {
                  setInputValue("");
                  setMatchingMentors(undefined);
                }}
              />
            )}

            {!inputValue && !selectedMentor && !isMobile && (
              <Ico.MagnifyingGlass
                fontSize={"24px"}
                fill={"#1F58F3"}
                cursor={"pointer"}
                onClick={() => {
                  setMatchingMentors(undefined);
                  if (selectedMentor) setInputValue("");
                }}
              />
            )}

            {!matchingMentors?.length && !selectedMentor && isMobile && (
              <Ico.MagnifyingGlass fontSize={"24px"} fill="#1F58F3" />
            )}
          </Flex>

          {!isMobile && selectedMentor && (
            <Text
              cursor={selectedMentor == undefined ? "not-allowed" : "pointer"}
              textDecoration={"underline"}
              fontSize={"12px"}
              w={"66px"}
              onClick={handleResetFilter}
            >
              Borrar filtro
            </Text>
          )}
        </Flex>

        <Flex
          align={"center"}
          justify={"center"}
          bg={"#fff"}
          top={"calc(100% + 4px)"}
          flexDir={"column"}
          position={"absolute"}
          w={"100%"}
          boxShadow={"-1px -1px 10px 0px rgba(100, 100, 100, 0.16)"}
          borderRadius={"6px"}
          overflow={"hidden"}
          zIndex={10}
        >
          {inputValue &&
            debouncedSearch &&
            !selectedMentor &&
            !matchingMentors?.length && <EmptyFilter />}

          {matchingMentors?.length &&
            matchingMentors.map((mentor) => (
              <FilterItem
                key={mentor.id}
                mentor={mentor}
                closeFilterModal={closeFilterModal}
                isPreselected={selectedMentor?.id === mentor.id}
              />
            ))}
        </Flex>
      </Flex>

      {isFilterModalOpen && <FilterModal isOpen onClose={closeFilterModal} />}
    </>
  );
};

export default MentorFilter;
